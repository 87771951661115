/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
// Dependencies
import { useState, useEffect, useContext, Fragment } from 'react';
import cx from 'classnames';
// Features
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';
import {
  getFulfillmentIDforCartItem,
  getAllAvailableQuantitiesForSku,
} from '@/utils/getFulfillmentOption';
import { useProductListView } from '../../context/useProductListView';
// Stores
// Components
import { ConditionalFulfillmentStates } from './ConditionalFulfillmentStates';
import { FulfillmentStates } from './FulfillmentStates';
import { SearchStoreModal } from '@/components/Store/StoreModal/SearchStoreModal';
import { MyStoreModal } from '@/components/Store/StoreModal/MyStoreModal';
// STARC Components
import { View } from '@az/starc-ui';
// Utils
// Constants
import { cartConstants } from '@/constants/cart';
import {
  FULFILLMENT_METHODS,
  FULFILLMENT_OPTION_TO_RENDER,
} from '@/constants/fulfillmentConstants';
// Styles
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import dayjs from 'dayjs';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

export type NewFulfillmentSectionProps = {
  fulfillmentOptionsGroupList: FulfillmentGroup[];
  shippingType: typeof cartConstants.STOREORDER;
  skuId: string | undefined;
  sisterStoreAvailable?: boolean;
  isDealDrawer?: boolean;
  quantityInCart: number;
  dealId?: string;
  setFulfillmentId?: (fulfillmentId: number) => void;
};

export const NewFulfillmentSection = ({
  fulfillmentOptionsGroupList,
  shippingType,
  skuId,
  isDealDrawer,
  dealId,
  quantityInCart,
  setFulfillmentId,
}: NewFulfillmentSectionProps) => {
  const { data: storeDetailsData } = useStoreDetailsData();
  const { listViewState } = useProductListView();
  const { openSisterStoreFlyout } = useContext(SisterStoreContext);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const isStoreSelected = useIsStoreSelected();
  const labels = useLabels({
    label_shipping_express_delivery: 'label_shipping_express_delivery',
    label_shipping_ground_delivery: 'label_shipping_ground_delivery',
    label_free: 'label_cart_bopus_Free',
  });
  const isVdpDsUniqueDisplayEnabled = useMonetateDecisionFlag('enableVdpDsUniqueDisplay');
  const showVdpDsShippingCost = useMonetateDecisionFlag('showVdpDsShippingCost');
  const showVpdDsAsRange = useMonetateDecisionFlag('showVpdDsAsRange');

  const nddOptionAvailable = (groupFulfillmentOptions: FulfillmentOption[]) => {
    return groupFulfillmentOptions.some((option) => {
      if (option.fulfillmentTypeId === FULFILLMENT_METHODS.NEXTDAY && option.available) {
        return option.availableQty > 0;
      }
      return false;
    });
  };
  const isNddAvailable = nddOptionAvailable(
    fulfillmentOptionsGroupList.flatMap((group) => group.groupFulfillmentOptions)
  );

  const toggleStoreModal = () => setStoreModalOpen((state) => !state);

  const openSisterStore = () => {
    openSisterStoreFlyout({
      skuId: skuId ?? '',
      shippingType,
      quantityInCart,
      productDetails: undefined,
      dealId,
    });
  };

  useEffect(() => {
    if (isDealDrawer) {
      const availableQuantities = getAllAvailableQuantitiesForSku(fulfillmentOptionsGroupList);
      const onlineOrderFulfillmentID = availableQuantities
        ? getFulfillmentIDforCartItem(availableQuantities, quantityInCart)
        : FULFILLMENT_METHODS.ONLINEORDER;
      const fulfillmentID =
        shippingType === cartConstants.STOREORDER
          ? FULFILLMENT_METHODS.STOREORDER
          : onlineOrderFulfillmentID;
      setFulfillmentId?.(fulfillmentID);
    }
  }, [shippingType, fulfillmentOptionsGroupList, isDealDrawer, quantityInCart, setFulfillmentId]);

  const renderFulfillmentStickers = (option: FulfillmentOption) => {
    if (
      option.fulfillmentTypeIdOriginal === 103 &&
      isVdpDsUniqueDisplayEnabled &&
      !isNddAvailable
    ) {
      const estimatedDelivery = dayjs(option.estimatedDeliveryDate);
      const dateWithFormat = estimatedDelivery.format('MMM. D');
      const vdpDsType = showVpdDsAsRange ? 'ground' : 'express';

      if (vdpDsType === 'express') {
        return (
          <FulfillmentStates
            key={option.fulfillmentTypeId}
            fulfillmentOptionToRender={
              showVdpDsShippingCost
                ? FULFILLMENT_OPTION_TO_RENDER.VDP_DS_AVAILABLE_EXPRESS_B
                : FULFILLMENT_OPTION_TO_RENDER.VDP_DS_AVAILABLE_EXPRESS_A
            }
            fulfillmentTypeLabel={labels.label_shipping_express_delivery}
            estDeliveryDate={estimatedDelivery.isTomorrow() ? 'tomorrow' : dateWithFormat}
          />
        );
      } else if (vdpDsType === 'ground') {
        return (
          <FulfillmentStates
            key={option.fulfillmentTypeId}
            fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.VDP_DS_AVAILABLE_GROUND}
            fulfillmentTypeLabel={`${labels.label_free} ${labels.label_shipping_ground_delivery}`}
            estDeliveryDate={option.estimatedDeliveryDate}
          />
        );
      }
      return <></>;
    }

    switch (option.fulfillmentTypeId) {
      case FULFILLMENT_METHODS.NEXTDAY:
        return (
          <FulfillmentStates
            key={option.fulfillmentTypeId}
            fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.NDD_AVAILABLE}
            fulfillmentTypeLabel={option.fulfillmentTypeLabel}
          />
        );

      case FULFILLMENT_METHODS.SAMEDAY:
        return (
          <Fragment key={option.fulfillmentTypeId}>
            {option.available && (
              <FulfillmentStates
                fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.SDD_DELIVERY}
                fulfillmentTypeLabel={option.fulfillmentTypeLabel}
              />
            )}
          </Fragment>
        );

      default: {
        const estDeliveryDate = option.estimatedDeliveryDate.split(':')?.[1].replace(/\-/, ' - ');
        return (
          <FulfillmentStates
            key={option.fulfillmentTypeId}
            fulfillmentOptionToRender={FULFILLMENT_OPTION_TO_RENDER.STD_DELIVERY}
            fulfillmentTypeLabel={option.fulfillmentTypeLabel}
            estDeliveryDate={estDeliveryDate}
          />
        );
      }
    }
  };

  function getOptionsToDisplay(groupFulfillmentOptions: FulfillmentOption[]) {
    const arrayOfSubGroupOptions: FulfillmentOption[] = [];
    groupFulfillmentOptions.forEach((option) => {
      if (option.available) {
        arrayOfSubGroupOptions.push(option);
      }
    });
    arrayOfSubGroupOptions.splice(2);
    return arrayOfSubGroupOptions;
  }

  return (
    <div
      className={cx(styles.fulfillmentContainer, {
        [styles.minHeightGrid]: !listViewState.isListView,
      })}
      data-testid="new-fulfillment-view-default"
    >
      {fulfillmentOptionsGroupList
        ? fulfillmentOptionsGroupList.map((item) => {
            return (
              <View direction="column" key={item.groupId}>
                <ConditionalFulfillmentStates
                  item={item}
                  address={storeDetailsData?.address1.toLowerCase()}
                  openSisterStore={openSisterStore}
                  toggleStoreModal={toggleStoreModal}
                  numberOfSisterStoreAvailable={
                    item.groupFulfillmentOptions[0].numberOfSisterStoreAvailable!
                  }
                  getOptionsToDisplay={getOptionsToDisplay}
                  renderFulfillmentStickers={renderFulfillmentStickers}
                />
              </View>
            );
          })
        : null}
      {storeModalOpen &&
        (!isStoreSelected ? (
          <SearchStoreModal closeStoreModal={toggleStoreModal} />
        ) : (
          <MyStoreModal closeStoreModal={toggleStoreModal} />
        ))}
    </div>
  );
};
