/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { pageTypes } from '@/constants/page';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useLocale } from '@/hooks/useLocale';
import { ResultsList } from '@/features/shelf/components/PageTemplates/Shelf/ResultsList';
import type { ShelfPart } from '@/types/legacy';
import { usePageType } from '@/hooks/usePageType';
import { useCMSShelfPageConfig } from '@/features/contentstack/hooks/useCMSShelfPageConfig';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import { persistXFusionQueryId } from '@/utils/xFusionQueryIdHelpers';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';

type Props = {
  resultsListRef?: React.RefObject<HTMLDivElement>;
  handleDealClick: (
    part: ShelfPart,
    price: string,
    quantity: number,
    ref: React.RefObject<HTMLButtonElement>,
    fulfillmentTypeId: number | null
  ) => void;
};

export const CMSResultList = ({ resultsListRef, handleDealClick }: Props) => {
  const locale = useLocale();
  const { pageType } = usePageType();
  const isBrandShelf = pageType === pageTypes.BrandShelf;
  const isSearchResults = pageType === pageTypes.search;
  const { data: brandProductData } = useBrandProductData({ enabled: isBrandShelf });
  const { data: searchProductData } = useSearchProductData({ enabled: isSearchResults });
  const { totalRecs, firstRecNum, recordsPerPage } = useCMSShelfPageConfig();
  const { data: productShelfResultsData } = useProductShelfResults();

  let xFusionQueryId;
  if (isSearchResults) {
    xFusionQueryId = searchProductData?.xFusionQueryId;
  } else if (isBrandShelf) {
    xFusionQueryId = brandProductData?.xFusionQueryId;
  } else {
    xFusionQueryId = productShelfResultsData?.xFusionQueryId;
  }
  persistXFusionQueryId(xFusionQueryId);

  const searchRecordsPerPage = isSearchResults ? searchProductData?.recordsPerPage : undefined;
  const searchTotalNumRecs = isSearchResults ? searchProductData?.total : undefined;
  const searchFirstRecNum = isSearchResults ? searchProductData?.firstRecNum : undefined;

  const brandRecordsPerPage = isBrandShelf ? brandProductData?.recordsPerPage : undefined;
  const brandTotalNumRecs = isBrandShelf ? brandProductData?.total : undefined;
  const brandFirstRecNum = isBrandShelf ? brandProductData?.firstRecNum : undefined;

  return (
    <ResultsList
      locale={locale}
      recsPerPage={
        searchRecordsPerPage ??
        brandRecordsPerPage ??
        recordsPerPage ??
        productShelfResultsData?.productShelfResults?.recordsPerPage ??
        12
      }
      totalNumRecs={
        searchTotalNumRecs ??
        brandTotalNumRecs ??
        totalRecs ??
        productShelfResultsData?.productShelfResults?.totalNumberOfRecords
      }
      firstRecNum={
        searchFirstRecNum ??
        brandFirstRecNum ??
        firstRecNum ??
        productShelfResultsData?.productShelfResults?.firstRecordNumber
      }
      isPaginationEnabled={
        (searchTotalNumRecs ??
          brandTotalNumRecs ??
          totalRecs ??
          productShelfResultsData?.productShelfResults?.totalNumberOfRecords) >
        (searchRecordsPerPage ??
          brandRecordsPerPage ??
          recordsPerPage ??
          productShelfResultsData?.productShelfResults?.recordsPerPage ??
          12)
      }
      handleDealClick={handleDealClick}
      xFusionQueryId={xFusionQueryId}
      resultsListRef={resultsListRef}
    />
  );
};
