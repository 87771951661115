/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useLabels } from '@/hooks/useLabels';
import { Button } from '../Button';

const labelMap = {
  lblShowMore: 'label_Show_More',
  lblShowLess: 'label_Show_Less',
};

export const ExpansionButton = ({
  isExpanded,
  onClick,
  className,
}: {
  isExpanded: boolean;
  onClick: () => void;
  className?: string;
}) => {
  const { lblShowMore, lblShowLess } = useLabels(labelMap);

  return (
    <Button
      data-testid="show-more-button"
      onClick={onClick}
      className={className}
      tabIndex={0}
      ariaExpanded={isExpanded}
      ariaLabel={isExpanded ? lblShowLess : lblShowMore}
    >
      {isExpanded ? lblShowLess : lblShowMore}
    </Button>
  );
};
