/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useAppState } from '@/stores/AppState';
import { useDeviceType } from '@/utils/useDeviceType';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { useGetShelfPageRecords } from '@/features/shelf/hooks/useGetShelfPageRecords';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';
import { getCSPLPResultList } from '../utils/getCSPLPResultList';
import { getRecsPerPage } from '../utils/getRecsPerPage';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

export const useCMSShelfPageConfig = () => {
  const router = useRouter();
  const deviceType = useDeviceType();
  const is24ProductViewEnabled = useIs24ProductViewEnabled();
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  const { data: plpCMSData, isCSProductListingPageEnabled } = useContentStackPLPData({
    enabled: true,
  });
  const csResultList = useMemo(() => getCSPLPResultList(plpCMSData), [plpCMSData]);

  const appState = useAppState();

  const { data: shelfData } = useProductList();

  const pageRecData = useGetShelfPageRecords(shelfData?.shelfPageBody);
  const totalRecs = pageRecData?.totalNumRecs ?? 0;
  const firstRecNum = pageRecData?.firstRecNum ?? 0;
  const rppOptions = csResultList?.records_per_page_dropdown;

  const reqUrl = __IS_SERVER__ ? appState.state.reqUrl : router.asPath;

  const recordsPerPage = getRecsPerPage({
    reqUrl,
    is24ProductViewEnabled,
    deviceType,
    csResultList,
    isCSProductListingPageEnabled,
    isLoadMoreEnabled,
  });

  return {
    recordsPerPage,
    totalRecs,
    firstRecNum,
    rppOptions,
    disableRecordsPerPage: csResultList?.disable_items_per_page ?? false,
  };
};
