/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';
import { Button, View, Text, ProgressBar, Link } from '@az/starc-ui';
import { useLabels } from '@/hooks/useLabels';

type LoadMoreProps = {
  fetchMore: () => void;
  totalRecords: number | null | undefined;
  currentlyShowing: number | null | undefined;
  hasNextPage: boolean | undefined;
};

export const LoadMoreResults = ({
  fetchMore,
  totalRecords,
  currentlyShowing,
  hasNextPage,
}: LoadMoreProps) => {
  const { label_load_more, label_load_more_of, label_load_more_results, label_back_to_top } =
    useLabels();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <React.Fragment>
      <View align="center" className={styles.loadMoreContainer}>
        {hasNextPage && (
          <>
            <View className={styles.loadMoreText}>
              <Text
                className={styles.resultsText}
              >{`${currentlyShowing} ${label_load_more_of} ${totalRecords} ${label_load_more_results}`}</Text>
            </View>
            {currentlyShowing && totalRecords && (
              <View className={styles.progressBar}>
                <ProgressBar
                  label=""
                  value={(currentlyShowing / totalRecords) * 100}
                  labelAlign="right"
                  className={styles.progressBarContainer}
                />
              </View>
            )}
            <View className={styles.loadMoreButton}>
              <Button variant="secondary" onClick={fetchMore}>
                {label_load_more}
              </Button>
            </View>
          </>
        )}
        {currentlyShowing && currentlyShowing > 24 && (
          <View className={styles.backToTop} justify={'center'}>
            <Link size="small" onClick={scrollToTop}>
              <Text className={styles.backToTopText}>{label_back_to_top}</Text>
            </Link>
          </View>
        )}
      </View>
    </React.Fragment>
  );
};
