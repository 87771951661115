/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import dayjs from 'dayjs';

/**
 * Creates a formatted date range string for free ground shipping with a 3-day open range and a 5-day close range.
 * This is static with A/B testing purposes. Should be updated or deleted later.CB2C-10753
 * @param estDeliveryDate - The estimated delivery date as a string, undefined, or null.
 * @returns A formatted date range string or an empty string if the input is invalid.
 */
export const createFreeGroundDateRange = (estDeliveryDate: string | undefined | null): string => {
  if (!estDeliveryDate || !dayjs(estDeliveryDate).isValid()) {
    return '';
  }

  const deliveryDate = dayjs(estDeliveryDate);
  const openRangeDate = deliveryDate.add(3, 'day');
  const closeRangeDate = deliveryDate.add(5, 'day');

  let dateRangeString: string;

  if (openRangeDate.month() === closeRangeDate.month()) {
    dateRangeString = `${openRangeDate.format('MMM')} ${openRangeDate.format(
      'D'
    )} - ${closeRangeDate.format('D')}`;
  } else {
    dateRangeString = `${openRangeDate.format('MMM D')} - ${closeRangeDate.format('MMM D')}`;
  }

  return dateRangeString;
};
