/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { cartConstants } from '@/constants/cart';
import type {
  AvailabilityInfoVOFromAPI,
  AvailabilityInfoVOFromAPIV2,
  FulfillmentOptionFromAPI,
} from '@/types/availability';
import type { FulfillmentGroup, FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import type { Locale } from '@/types/i18n';
import { countryCodes } from '@/constants/locale';
import { getIsSTHFulfillmentTypeId } from '@/features/product/utils/getIsSTHFulfillmentTypeId';

export const getFulfillmentOption = (
  shipToHomeAvailable: boolean | undefined,
  storePickupAvailable: boolean | undefined,
  defaultFulfillmentOption: string,
  locale: Locale
): string => {
  const productNotAvailable = !shipToHomeAvailable && !storePickupAvailable;

  if (productNotAvailable) {
    return '';
  } else if (storePickupAvailable && shipToHomeAvailable) {
    if (defaultFulfillmentOption === cartConstants.BOPUS) {
      return cartConstants.STOREORDER;
    }

    if (defaultFulfillmentOption === cartConstants.STH) {
      return cartConstants.ONLINEORDER;
    }

    return '';
  } else if (storePickupAvailable || locale === countryCodes.mx) {
    return cartConstants.STOREORDER;
  } else {
    return cartConstants.ONLINEORDER;
  }
};

export const getSelectedFulfilmentID = (
  fulfilmentOptions: Array<{ selected: boolean; availableQty: number; fulfillmentTypeId: number }>
) => {
  return fulfilmentOptions
    .map((option) => {
      if (option.selected && option.availableQty) {
        return option.fulfillmentTypeId;
      }
    })
    .filter((a) => a !== undefined)[0];
};

export const getSelectedFulfilmentForSelectedQty = (
  count: number,
  fulfilmentOptions: FulfillmentOptionFromAPI[] | FulfillmentOption[] | undefined,
  shippingType: string
) => {
  return fulfilmentOptions
    ?.map((option) => {
      const updatedOption = {
        ...option,
        fulfillmentTypeId: getIsSTHFulfillmentTypeId(option.fulfillmentTypeId)
          ? FULFILLMENT_METHODS.ONLINEORDER
          : option.fulfillmentTypeId,
      };
      if (updatedOption.selected) {
        if (
          updatedOption.availableQty >= count ||
          (updatedOption.fulfillmentTypeId === FULFILLMENT_METHODS.STOREORDER &&
            !updatedOption.available &&
            updatedOption.sisterStoreAvailable)
        ) {
          return updatedOption.fulfillmentTypeId;
        } else if (shippingType === cartConstants.ONLINEORDER) {
          return FULFILLMENT_METHODS.ONLINEORDER;
        } else if (shippingType === cartConstants.STOREORDER) {
          return FULFILLMENT_METHODS.STOREORDER;
        }
      }
    })
    .filter((a) => a !== undefined)[0];
};

export const getSelectedFulfillmentInCart = (
  count: number,
  fulfilmentOptions: FulfillmentOptionFromAPI[] | undefined
) => {
  return fulfilmentOptions
    ?.map((option) => {
      if (option.selected) {
        if (
          option.availableQty >= count ||
          (option.fulfillmentTypeId === FULFILLMENT_METHODS.STOREORDER &&
            option.sisterStoreAvailable)
        ) {
          return option.fulfillmentTypeId;
        }
      }
    })
    .filter((a) => a !== undefined)[0];
};

export const getFulfillmentId = (
  shipToHomeAvailable: boolean | undefined,
  storePickupAvailable: boolean | undefined,
  defaultFulfillmentOption: string,
  nddAvailable: boolean,
  sddAvailable: boolean,
  sisterStoreAvailable?: boolean
) => {
  const productNotAvailable = !shipToHomeAvailable && !storePickupAvailable;
  if (productNotAvailable && !sisterStoreAvailable) {
    return 0;
  } else if (
    (storePickupAvailable && defaultFulfillmentOption === cartConstants.BOPUS) ||
    (storePickupAvailable && defaultFulfillmentOption === cartConstants.STH && !shipToHomeAvailable)
  ) {
    return FULFILLMENT_METHODS.STOREORDER;
  } else if (shipToHomeAvailable) {
    if (nddAvailable) {
      return FULFILLMENT_METHODS.NEXTDAY;
    } else if (sddAvailable) {
      return FULFILLMENT_METHODS.SAMEDAY;
    }
    return FULFILLMENT_METHODS.ONLINEORDER;
  } else if (
    (sisterStoreAvailable && defaultFulfillmentOption === cartConstants.BOPUS) ||
    (sisterStoreAvailable && defaultFulfillmentOption === cartConstants.STH && !shipToHomeAvailable)
  ) {
    return FULFILLMENT_METHODS.STOREORDER;
  }
  return 0;
};

export const getFulfillmentIDforCartItem = (
  availabilityInfoVO: AvailabilityInfoVOFromAPIV2,
  count: number
) => {
  if (availabilityInfoVO.nddQuantity > count) {
    return FULFILLMENT_METHODS.NEXTDAY;
  } else if (availabilityInfoVO.sthQuantity > count) {
    return FULFILLMENT_METHODS.ONLINEORDER;
  } else if (availabilityInfoVO.sddQuantity >= count) {
    return FULFILLMENT_METHODS.SAMEDAY;
  }
  return FULFILLMENT_METHODS.ONLINEORDER;
};

export const getSelectedFulfillmentAvailableQuantity = (
  fulfillmentId: number,
  availabilityInfoVO: AvailabilityInfoVOFromAPI | undefined | AvailabilityInfoVOFromAPIV2
) => {
  if (fulfillmentId === FULFILLMENT_METHODS.SAMEDAY) {
    return availabilityInfoVO?.sddQuantity;
  } else if (fulfillmentId === FULFILLMENT_METHODS.NEXTDAY) {
    return availabilityInfoVO?.nddQuantity;
  } else if (fulfillmentId === FULFILLMENT_METHODS.ONLINEORDER) {
    return availabilityInfoVO?.sthQuantity;
  } else if (fulfillmentId === FULFILLMENT_METHODS.STOREORDER) {
    return availabilityInfoVO?.bopusQuantity;
  }
};

export const getAvailableQuantityByFulfillmentId = (
  fulfillmentId: number,
  fulfillmentOptions?: FulfillmentOption[]
) => {
  if (!fulfillmentOptions) return 0;
  if (
    fulfillmentId === 101 &&
    !fulfillmentOptions.some((option) => option.fulfillmentTypeId === fulfillmentId)
  ) {
    return fulfillmentOptions.find((option) => option.fulfillmentTypeId === 105)?.availableQty ?? 0;
  }
  return (
    fulfillmentOptions.find((option) => option.fulfillmentTypeId === fulfillmentId)?.availableQty ??
    0
  );
};

export const getAllAvailableQuantitiesForSku = (fulfillmentGroups?: FulfillmentGroup[]) => {
  const result = {
    sddQuantity: 0,
    nddQuantity: 0,
    sthQuantity: 0,
    bopusQuantity: 0,
  };
  if (!fulfillmentGroups) return result;

  const allFulfillmentOptions = fulfillmentGroups.flatMap(
    (group) => group.groupFulfillmentOptions ?? []
  );
  allFulfillmentOptions.forEach((option) => {
    switch (option.fulfillmentTypeId) {
      case FULFILLMENT_METHODS.NEXTDAY:
        result.nddQuantity = option.availableQty;
        break;
      case FULFILLMENT_METHODS.SAMEDAY:
        result.sddQuantity = option.availableQty;
        break;
      case FULFILLMENT_METHODS.STOREORDER:
        result.bopusQuantity = option.availableQty;
        break;
      case FULFILLMENT_METHODS.ONLINEORDER:
      case FULFILLMENT_METHODS.CUSTOMIZEDSKU:
        result.sthQuantity = option.availableQty;
        break;
    }
  });
  return result;
};
