/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useRef, useEffect } from 'react';

export const useIsSticky = (
  className: string,
  marginClassName: string,
  underRecsPerPage: boolean,
  resultsListRef?: React.RefObject<HTMLDivElement>
) => {
  const mobileFiltersRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const stickyFiltersAndHeaderHeight =
    isMobile && !!mobileFiltersRef?.current
      ? 64 + mobileFiltersRef?.current?.offsetHeight
      : !!mobileFiltersRef?.current
      ? 66 + mobileFiltersRef?.current?.offsetHeight
      : 66;

  useEffect(() => {
    if (!mobileFiltersRef.current || !resultsListRef?.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([event]) => {
        event.target.classList.toggle(
          className,
          event.intersectionRatio < 1 && event.intersectionRatio > 0.95
        );
      },
      { threshold: [1, 0.95], rootMargin: `${isMobile ? '-64px' : '-66px'} 0px 0px 0px` }
    );
    observer.observe(mobileFiltersRef.current);

    const resultsListObserver = new IntersectionObserver(
      ([event]) => {
        mobileFiltersRef.current?.classList.toggle(
          marginClassName,
          (event.intersectionRatio < 0.02 ||
            (underRecsPerPage && event.intersectionRatio < 0.05)) &&
            event.target.getBoundingClientRect().top <=
              mobileFiltersRef.current.getBoundingClientRect().bottom
        );
      },
      {
        threshold: [1, 0.9],
        rootMargin: `-${stickyFiltersAndHeaderHeight}px 0px 0px 0px`,
      }
    );
    resultsListObserver.observe(resultsListRef.current);

    return () => {
      observer.disconnect();
      resultsListObserver.disconnect();
    };
  }, [
    className,
    underRecsPerPage,
    isMobile,
    marginClassName,
    resultsListRef,
    stickyFiltersAndHeaderHeight,
  ]);

  return { mobileFiltersRef };
};
