/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type AxiosInstance, type AxiosError } from 'axios';
import { type DealData, type DealResponse } from '../interface';
import { requestBaseURL } from '@/config/serviceAPI';
import { isAxiosError } from '@/utils/errorsHandling';
import { createQuery } from '@/utils/createReactQuery';

const URL = `${requestBaseURL}/ecomm/b2c/browse/v3/skus`;

type Options = {
  skuId: string;
  dealId: string;
  fulfillmentOption: string;
};

const getDeal = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { skuId, dealId, fulfillmentOption } = options;
  const response = await getAxios(axiosInstance).get<DealResponse>(
    `${URL}/${skuId}/deals/${dealId}/fulfillment-option/${fulfillmentOption}`
  );

  return selector(response.data);
};

const selector = (data: DealResponse): DealData => data;

const { useData } = createQuery<DealData, Options>('dealDetails', getDeal);

export const useDeal = ({
  enabled = true,
  onError,
  ...rest
}: Options & { enabled?: boolean; onError?: (err: AxiosError) => void }) => {
  return useData({
    enabled,
    onError: (err) => {
      if (onError && isAxiosError(err)) {
        onError(err);
      }
    },
    ...rest,
  });
};
