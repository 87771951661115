/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(customParseFormat);
dayjs.extend(isTomorrow);

export const checkIfDateIsTomorrow = (date: string) => {
  // Convert the input to title case to handle lowercase month abbreviations
  const formattedDate = date.replace(/\b\w/g, (c) => c.toUpperCase());

  let dateToCheck = dayjs(formattedDate);

  // If parsing fails, try some common formats
  if (!dateToCheck.isValid()) {
    const commonFormats = ['MMM D', 'MMM DD', 'MMM. D', 'MMM. DD', 'MMM D YYYY', 'MMM DD YYYY'];
    for (const format of commonFormats) {
      dateToCheck = dayjs(formattedDate, format, false);
      if (dateToCheck.isValid()) break;
    }
  }

  if (!dateToCheck.isValid()) {
    return false;
  }

  if (!formattedDate.includes(dateToCheck.year().toString())) {
    const currentDate = dayjs();
    const isEndOfDecember = currentDate.month() === 11 && currentDate.date() >= 31;
    dateToCheck = dateToCheck.year(isEndOfDecember ? currentDate.year() + 1 : currentDate.year());
  }

  return dateToCheck.isTomorrow();
};

export const getTomorrowOrDate = (date: string, tomorrowLabel: string) => {
  const isTomorrowDate = checkIfDateIsTomorrow(date);
  return isTomorrowDate ? tomorrowLabel : date;
};

type GenerateGetItTimePhraseInput = {
  labels: {
    lblTomorrow: string;
    lblGetItBy: string;
    lblGetIt: string;
  };
  estimatedDeliveryDate: string;
  config?: {
    dateFormat: string;
  };
};

type ReturnOfGenerateGetItPhrase = {
  prepositional: string;
  getItPhrase: string;
  formattedDate: string;
};

export const generateGetItTimePhrase = ({
  labels,
  estimatedDeliveryDate,
  config,
}: GenerateGetItTimePhraseInput): ReturnOfGenerateGetItPhrase => {
  if (!dayjs(estimatedDeliveryDate).isValid()) {
    return {
      prepositional: '',
      getItPhrase: '',
      formattedDate: '',
    };
  }

  if (checkIfDateIsTomorrow(estimatedDeliveryDate)) {
    return {
      prepositional: '',
      getItPhrase: labels.lblGetIt,
      formattedDate: labels.lblTomorrow.toLocaleLowerCase(),
    };
  }
  return {
    prepositional: 'by',
    getItPhrase: labels.lblGetItBy,
    formattedDate: dayjs(estimatedDeliveryDate)
      .format(config?.dateFormat ?? 'MMM D')
      .replace(/^\w/, (c) => c.toUpperCase()),
  };
};

type ReturnOfGenerateDeliveryTimePhrase = {
  prepositional: string;
  formattedDate: string;
};

type GenerateDeliveryTimePhraseInput = {
  labels: {
    lblBy?: string;
    lblTomorrow: string;
  };
  estimatedDeliveryDate: string;
  config?: {
    dateFormat: string;
  };
};

export const generateDeliveryTimePhrase = ({
  labels,
  estimatedDeliveryDate,
  config,
}: GenerateDeliveryTimePhraseInput): ReturnOfGenerateDeliveryTimePhrase => {
  if (!dayjs(estimatedDeliveryDate).isValid()) {
    return {
      prepositional: '',
      formattedDate: '',
    };
  }

  const isTomorrow = checkIfDateIsTomorrow(estimatedDeliveryDate);
  const formattedDate = isTomorrow
    ? labels.lblTomorrow.toLowerCase()
    : dayjs(estimatedDeliveryDate)
        .format(config?.dateFormat ?? 'MMM D')
        .replace(/^\w/, (c) => c.toUpperCase());

  return {
    prepositional: labels.lblBy ?? 'by',
    formattedDate,
  };
};
