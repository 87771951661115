/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { AxiosInstance } from 'axios';
import type { Dispatch, GetState, ThunkAction } from '../types';
import azURL, { postATGData } from '../config/serviceAPI';
import actionConstants from '../types/action';
import { fetchOrderDetails } from './details';
import { cartConstants } from '../constants/cart';
import { type QueryClient } from '@tanstack/react-query';
import { shipmentOptionsQuery } from '@/features/orders/api/getShipmentOptions';
import type { PageName } from '@/types/analytics';

export const API_URL3: string = azURL('promoCodeApply');

export const API_URL4: string = azURL('promoCodeRemove');

export const emptyCartPageData = (): ThunkAction => (dispatch: Dispatch) =>
  dispatch({
    type: actionConstants.CLEAR_CART_PAGE_DATA,
  });

export type DiscountDispatchData = {
  id: string;
  fromPage: PageName;
  discountTypeId: number;
};

export const applyDiscountCodeCall =
  (
    data: DiscountDispatchData,
    checkoutPage: boolean,
    queryClient: QueryClient,
    URL: string = API_URL3,
    hideInStorePurchase = true
  ): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: AxiosInstance) => {
    dispatch({ type: actionConstants.PROMOCODE_APPLY_REQUESTING });

    try {
      const { id: promotionId, fromPage } = data;
      const response = await postATGData(axios, URL, {
        promotionId,
        fromPage,
      }); // if the request was made from the checkout page, request shipment
      // options but only if shippingAddressAvailable

      const shippingAddressAvailable = getState().orders.addressDetails.shippingAddressAvailable;
      const orderItems = getState().orders.details.orderItems;
      const onlyBOPUSItems = orderItems.filter(
        (item: { orderType: string }) => item.orderType === cartConstants.STOREORDER
      );
      const orderIsOnlyBOPUS = orderItems.length === onlyBOPUSItems.length;
      if (checkoutPage && shippingAddressAvailable && !orderIsOnlyBOPUS) {
        try {
          await queryClient.fetchQuery({
            queryKey: shipmentOptionsQuery.getFullKey({}),
            staleTime: 0,
          });
        } catch (err) {
          dispatch({
            type: actionConstants.ORDER_DETAILS_FAILURE,
            err: err,
          });
        }
      }

      // this boolean controls whether url params are sent on the order details call. They should not be sent when on cart page.
      if (!checkoutPage) {
        hideInStorePurchase = false;
      }

      await dispatch(fetchOrderDetails(hideInStorePurchase, queryClient));
      dispatch({
        type: actionConstants.PROMOCODE_APPLY_SUCCESS,
        data: response.data,
        value: data.id,
      });
    } catch (err: any) {
      dispatch({
        type: actionConstants.PROMOCODE_APPLY_FAILURE,
        err: err.response,
        value: data.id,
        errorLog: err,
        data: data,
      });
    }
  };

export const removeDiscountCodeCall =
  (data: DiscountDispatchData, URL: string = API_URL4, queryClient: QueryClient): ThunkAction =>
  async (dispatch: Dispatch, getState: GetState, axios: AxiosInstance) => {
    dispatch({ type: actionConstants.PROMOCODE_REMOVE_REQUESTING });

    try {
      const removeCouponDiscountData = {
        couponId: data.id,
        fromPage: data.fromPage,
      };
      const removeDealDiscountData = {
        promotionId: data.id,
        fromPage: data.fromPage,
      };
      const requestData =
        data.discountTypeId === 1 ? removeCouponDiscountData : removeDealDiscountData;
      const response = await postATGData(axios, URL, requestData);
      dispatch({
        type: actionConstants.PROMOCODE_REMOVE_SUCCESS,
        data: response.data,
        values: data.id,
      });
      dispatch(fetchOrderDetails(undefined, queryClient));
    } catch (err: any) {
      dispatch({
        type: actionConstants.PROMOCODE_REMOVE_FAILURE,
        err: err.message,
        values: data.id,
        errorLog: err,
        data: data,
      });
    }
  };

export const resetDiscounts = (): ThunkAction => (dispatch: Dispatch) =>
  dispatch({
    type: actionConstants.RESET_PROMO_CART,
  });
