/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import styles from './styles.module.scss';
import { Button, Text, View } from '@az/starc-ui';
import { Filter } from '@az/starc-ui-icons';

export type FilterButtonProps = {
  label: string;
  onClick: (state: boolean) => void;
  dataTestid: string;
};

const FilterAndSortButton = (props: FilterButtonProps) => {
  const { onClick, label } = props;
  return (
    <View attributes={{ 'data-testid': props?.dataTestid }}>
      <Button
        variant="secondary"
        startIcon={Filter}
        onClick={(state) => onClick(!state)}
        className={styles.chip}
      >
        <Text
          variant="small-body"
          attributes={{ style: { textTransform: 'none', fontSize: '14px', lineHeight: '24px' } }}
        >
          {label}
        </Text>
      </Button>
    </View>
  );
};

export default FilterAndSortButton;
