/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { ComponentType } from 'react';
import cx from 'classnames';
import styles from '../styles.module.scss';
import azCommonStyles from '../../../../theme/globals.module.scss';
import BreadcrumbSeparator from './BreadcrumbSeparator';
import { type BreadcrumbModel } from '../../../../../api/types/browse-search-types';
import { isBreadcrumbModel, type IItem } from '../utils/isBreadcrumbModel';

type Props = {
  isLastItem: boolean;
  item: IItem | BreadcrumbModel;
  onClick?: (e: Event, item: IItem) => void;
  linkComponent: ComponentType<any>;
  lastItemDisabled?: boolean;
};

const BreadcrumbItem = (props: Props) => {
  const { isLastItem, item, onClick, linkComponent: Link, lastItemDisabled } = props;
  const isNewApiBreadcrumb = isBreadcrumbModel(item);

  return (
    <span className={cx(azCommonStyles['az-caption'], styles['breadcrumb-item'])}>
      {lastItemDisabled && isLastItem ? (
        <span className={cx(styles['breadcrumb-link'], styles.breadcrumbDisabled)}>
          {isNewApiBreadcrumb ? item.displayName : item?.label}
        </span>
      ) : (
        <Link
          href={isNewApiBreadcrumb ? item?.canonicalPath : item?.url}
          onClick={(e: any) => onClick?.(e, item)}
          className={cx(styles['breadcrumb-link'])}
        >
          {isNewApiBreadcrumb ? item.displayName : item?.label}
        </Link>
      )}
      {!isLastItem && <BreadcrumbSeparator />}
    </span>
  );
};

export default BreadcrumbItem;
