/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type QuickFilterModel } from '@/api/types/browse-search-types';
import type { FilterDataType, HandleFilterType } from '../../../types/filterTypes';

const isSelectedOrOneClick = (
  oneClickFiltersArray: Array<HandleFilterType>,
  filter: FilterDataType,
  isOneClick?: boolean,
  matchingQuickFilter?: QuickFilterModel
) => {
  matchingQuickFilter?.facetValues?.forEach((matchingFacet) => {
    const refinement = filter.refinements.find(
      (refinement) => refinement.label === matchingFacet.facetValueDisplayName
    );

    if (isOneClick && refinement && matchingFacet?.facetValueDisplayName === refinement.label) {
      const commonProperties = {
        refinement,
        displayName: filter.displayName,
        dimensionName: filter.dimensionName,
        quickFilter: filter.quickFilter,
      };

      if (
        refinement.properties?.isSelected === 'true' ||
        refinement.properties?.isSelected === true
      ) {
        oneClickFiltersArray.push({
          ...commonProperties,
          checked: true,
        });
      } else {
        oneClickFiltersArray.push({
          ...commonProperties,
          checked: false,
        });
      }
    }
  });
};

export const getQuickAndOneClickFilters = (
  oneClickFiltersArray: Array<HandleFilterType>,
  quickFiltersArray: Array<FilterDataType>,
  filter: FilterDataType,
  quickFilters?: QuickFilterModel[]
) => {
  const matchingQuickFilter = quickFilters?.find(
    (facet) => facet.facetInternalName === filter?.dimensionName
  );

  if (matchingQuickFilter?.isOneClickFilter) {
    isSelectedOrOneClick(
      oneClickFiltersArray,
      filter,
      matchingQuickFilter?.isOneClickFilter,
      matchingQuickFilter
    );
  } else if (!matchingQuickFilter?.isOneClickFilter && matchingQuickFilter?.multiSelectFlag) {
    quickFiltersArray.push(filter);
  }
};
