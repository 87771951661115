/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';
import { STARCTypography } from '@/components/STARCTypography';
import { CMSNullResult } from '@/features/contentstack/components/CMSNullResult/CMSNullResult';
import { CMSProductFinder } from '@/features/contentstack/components/CMSProductFinder/CMSProductFinder';
import { CMSYmme } from '@/features/contentstack/components/CMSYmme/CMSYmme';
import type {
  ContentStackNullResult,
  ContentStackProductFinder,
  ContentStackYmme,
} from '@/features/contentstack/interface';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useState } from 'react';
import { pageTypes } from '@/constants/page';

type Props = {
  content: Omit<ContentStackYmme, 'theme' | 'title'>;
  productFinderData?: ContentStackProductFinder;
  nullResultsData?: ContentStackNullResult;
};

const CMSShelfYmme = ({ content, productFinderData, nullResultsData }: Props) => {
  const [removeMargin, setRemoveMargin] = useState(true);

  const preferredVehicle = usePreferredVehicle();
  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();

  return (
    <>
      <CMSYmme
        content={content}
        redirectAfterVehicleChanged={redirectAfterVehicleChanged}
        removeBottomMargin={removeMargin}
        isProductFinderDisplayed={!!productFinderData}
        pageType={pageTypes.shelf}
      />
      {productFinderData && Boolean(preferredVehicle) && (
        <CMSProductFinder content={productFinderData} setRemoveMargin={setRemoveMargin} />
      )}
      {nullResultsData && (
        <STARCTypography>
          <CMSNullResult content={nullResultsData} setRemoveMargin={setRemoveMargin} />
        </STARCTypography>
      )}
    </>
  );
};

export { CMSShelfYmme };
