/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo, type MouseEvent } from 'react';
import cx from 'classnames';
import root from 'window-or-global';
import { useRouter } from 'next/router';
import { replaceHistoryStateOptionsOnClick } from '@/features/common/utils/replaceHistoryStateOptionsOnClick';
import { historyStateOptionAttributes } from '@/constants/historyStateOption';
import { SmartLink as Link } from '@/utils/smartLink';
import azCommonStyles from '@/theme/globals.module.scss';
import styles from './styles.module.scss';
import { kebabCase } from '@/utils/kebabCase';
import { sessionStorage } from '@/utils/sessionStorage';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
type Props = {
  productName?: string;
  CustomElement: keyof JSX.IntrinsicElements;
  url: string;
  titleStyles?: string;
  isSearchPageType: boolean;
  prodIndex?: number;
  onProductClick?: () => void;
  isSponsoredCard?: boolean;
};

export const ProductTitle = ({
  productName,
  url,
  titleStyles,
  CustomElement,
  onProductClick,
  isSearchPageType,
  prodIndex,
  isSponsoredCard,
}: Props) => {
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  const router = useRouter();
  const { searchText, filterByKeyWord } = router.query;
  const updatedUrl = useMemo(() => {
    if (searchText && !url.includes('searchText')) {
      return `${url}?searchText=${searchText as string}`;
    }
    if (filterByKeyWord && !url.includes('filterByKeyWord') && !url.includes('searchText')) {
      return `${url}?searchText=${filterByKeyWord as string}`;
    }
    return url;
  }, [url, searchText, filterByKeyWord]);

  const productNameKebabCase = kebabCase(productName ?? '');

  const handleClick = () => {
    onProductClick?.();
    if (isSearchPageType && prodIndex !== null && prodIndex !== undefined) {
      sessionStorage.setItem(
        'productRank',
        isSponsoredCard && isLoadMoreEnabled ? 'Recommended' : String(prodIndex + 1)
      );
    }
    replaceHistoryStateOptionsOnClick(
      historyStateOptionAttributes.latestSelectedPageYOffset,
      root.scrollY || root.pageYOffset
    );
  };

  const handleAuxClick = (e: MouseEvent) => {
    // Middle mouse button click
    if (e.button === 1) {
      onProductClick?.();
    }
  };

  return (
    <Link
      to={updatedUrl}
      samePage
      className={azCommonStyles['az-color-inherit']}
      data-testid={`${productNameKebabCase}-product-title-link`}
    >
      <CustomElement
        className={
          titleStyles
            ? titleStyles
            : cx(
                azCommonStyles['az-body-1-heavy'],
                styles.titleText,
                styles.newFulfillmentTitle,
                'product-title'
              )
        }
        onClick={handleClick}
        onAuxClick={handleAuxClick}
        data-testid={`${productNameKebabCase}-product-title`}
      >
        {productName}
      </CustomElement>
    </Link>
  );
};
