/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import styles from './styles.module.scss';
import { useLabels } from '@/hooks/useLabels';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const labelMap = {
  lblBestSeller: 'label_best_seller',
};

export const ProductBadging = () => {
  const altBestSellerBadgeEnabled = useMonetateDecisionFlag('alternativeBestSellerBadgeEnabled');
  const { lblBestSeller } = useLabels(labelMap);

  return (
    <div className={styles.badgeContainer}>
      {altBestSellerBadgeEnabled ? (
        <div className={styles.badgeV1}>
          <div className={styles.badgeArrow} />
          {lblBestSeller}
        </div>
      ) : (
        <div className={styles.badgeV2}>
          <div className={styles.badgeText}>{lblBestSeller}</div>
          <div className={styles.badgeContainerEnd} />
          <div className={styles.badgeHashMarks}>
            <div className={styles.badgeHashMark} />
            <div className={styles.badgeHashMark} />
          </div>
        </div>
      )}
    </div>
  );
};
