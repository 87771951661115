/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import SEOCopyBlock from '@/components/SEOCopyBlock';
import { buildSEOCopyBlock } from '@/features/common/utils/buildSEOCopyBlock';
import { useSeoMetadata } from '@/features/common/api/getSeoMetadata';
import { useLabels } from '@/hooks/useLabels';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useRouter } from 'next/router';
import { useContentStackPLPData } from '../../api/getContentStackProductListingPageData';
import { useFeatureFlag } from '@/features/globalConfig';

const labelMap = {
  about: 'label_generic_about',
};

export const CMSPLPSeoCopy = () => {
  const router = useRouter();
  const labels = useLabels(labelMap);

  const { data: shelfData } = useProductList();
  const makeOrModelName = shelfData?.makeorModelName;
  const shopByUrlMake = shelfData?.shopByUrlMake;
  const shopByUrlModel = shelfData?.shopByUrlModel;
  const shopByUrlYear = shelfData?.shopByUrlYear;
  const gcpUrlEnabled = useFeatureFlag('SEO_GCP_URL_ENABLED') === 'true';

  const { data: seoMetadataInfo } = useSeoMetadata({
    enabled: makeOrModelName !== undefined,
    vehicleMake: shopByUrlMake ?? undefined,
    vehicleModel: shopByUrlModel ?? undefined,
    vehicleYear: shopByUrlYear ?? undefined,
    gcpUrlEnabled,
  });

  const isMerchShelfPage = router.route === '/deals/[cmsPath]/[[...slug]]';
  const { data: plpCMSData } = useContentStackPLPData({ enabled: true });
  const merchSeoCopyBlock = isMerchShelfPage
    ? plpCMSData?.shelfresultbottom?.find((block) => !!block.copy_block)
    : null;

  const shelfSeoCopyBlock = seoMetadataInfo?.topContentSeoActive
    ? seoMetadataInfo.copyBlockTwo
    : buildSEOCopyBlock(
        seoMetadataInfo?.copyBlockOne ?? null,
        seoMetadataInfo?.copyBlockTwo ?? null
      );

  const seoCopyBlock = shelfSeoCopyBlock ?? merchSeoCopyBlock?.copy_block?.copy;

  const shelfHeading = seoMetadataInfo?.pageHeadingName ?? shelfData?.shelfHeading;
  const seoCopyHeading = shelfHeading ?? merchSeoCopyBlock?.copy_block?.heading;

  if (!seoCopyBlock) {
    return null;
  }

  return (
    <SEOCopyBlock title={labels.about} boldTitle={seoCopyHeading} seoCopyBlock={seoCopyBlock} />
  );
};
