/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Drawer } from '@/components/Drawer/Drawer';
import { useState, type ReactNode } from 'react';
import { SisterStoreFlyout } from '../components/SisterstoreFlyout/index';
import { Spinner } from '@/components/AZCustomComponent/Spinner';
import styles from '../components/SisterstoreFlyout/SisterStoreProvider.module.scss';
import { trackInStockNearYouLinkClick } from '@/utils/analytics/track/trackInStockNearYouLinkClick';
import { type FlyoutDataState, SisterStoreContext } from './SisterStoreContext';

interface SkuProviderProps {
  children: ReactNode;
}

export const SisterStoreProvider: React.FC<SkuProviderProps> = ({ children }) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  const [isSettingNewStore, setSettingNewStore] = useState(false);
  const [isUsingAutoClose, setIsUsingAutoClose] = useState(true);

  const [flyoutData, setFlyoutData] = useState<FlyoutDataState>({
    dealId: undefined,
    productDetails: undefined,
    quantityInCart: 0,
    shippingType: '',
    skuId: '',
  });

  const setUsingAutoClose = (autoClose: boolean) => {
    setIsUsingAutoClose(autoClose);
  };

  const setSisterStoreFlyoutIsOpen = (isOpen: boolean) => {
    setIsFlyoutOpen(isOpen);
  };

  const openSisterStoreFlyout = (data: FlyoutDataState) => {
    trackInStockNearYouLinkClick();
    setFlyoutData(data);
    setIsFlyoutOpen(true);
  };

  const closeSisterStoreFlyout = () => {
    setSettingNewStore(false);
    setIsFlyoutOpen(false);
  };

  const updateSettingNewStore = (isLoading: boolean) => {
    setSettingNewStore(isLoading);
  };

  const toggleSisterStoreFlyout = (data?: FlyoutDataState | undefined) => {
    if (data && !false) {
      setFlyoutData(data);
      setIsFlyoutOpen(true);
    }
    setIsFlyoutOpen(!isFlyoutOpen);
  };

  const { dealId, productDetails, quantityInCart, shippingType, skuId } = flyoutData;

  return (
    <>
      <SisterStoreContext.Provider
        value={{
          closeSisterStoreFlyout,
          setSisterStoreFlyoutIsOpen,
          toggleSisterStoreFlyout,
          openSisterStoreFlyout,
          isSisterStoreFlyoutOpen: isFlyoutOpen,
          updateSettingNewStore,
          setUsingAutoClose,
          isUsingAutoClose,
          isSettingNewStore,
        }}
      >
        <Drawer
          data-testid="drawer-sister-store-flyout"
          anchor={'right'}
          open={isFlyoutOpen}
          onClose={closeSisterStoreFlyout}
          classes={{ paper: styles.drawer, root: styles.drawerRoot }}
        >
          {isSettingNewStore ? (
            <div className={styles.loaderContainer}>
              <Spinner />
            </div>
          ) : (
            <SisterStoreFlyout
              handleClose={closeSisterStoreFlyout}
              dealId={dealId}
              productDetails={productDetails}
              quantityInCart={quantityInCart}
              shippingType={shippingType}
              skuId={skuId}
            />
          )}
        </Drawer>
        {children}
      </SisterStoreContext.Provider>
    </>
  );
};
