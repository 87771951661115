/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import Head from 'next/head';
import { CMSBanner } from '../CMSBanner/CMSBanner';
import { mapCitrusBannerToContentStackBanner } from '@/features/citrus/utils/mapCitrusBannerToContentStackBanner';
import { type ContentStackCitrusAd } from '../../interface';
import {
  useCitrusSearchPageData,
  useCitrusShelfData,
} from '@/features/citrus/api/getCitrusShelfData';
import { CMSShelfSpacer } from '../CMSShelfSpacer/CMSShelfSpacer';
import { useFeatureFlag } from '@/features/globalConfig';
import { isCitrusBannerWithLink } from '@/features/citrus/utils/isCitrusBannerWithLink';
import { extractOrigin } from '../../utils/extractOrigin';
import { useRouter } from 'next/router';

type Props = {
  content: Omit<ContentStackCitrusAd, 'title'>;
};

const sizePerDevice = {
  desktop: {
    width: 1166,
    height: 110,
  },
  mobile: {
    width: 668,
    height: 250,
  },
  tablet: {
    width: 1100,
    height: 115,
  },
};

export function CMSCitrusShelf({ content }: Props) {
  const citrusShelfBannersEnabled = useFeatureFlag('IS_CITRUS_SHELF_BANNERS_ENABLED') === 'true';
  const citrusSearchBannersEnabled =
    useFeatureFlag('IS_CITRUS_SEARCH_RESULTS_BANNERS_ENABLED') === 'true';

  const router = useRouter();
  const isSearchPage = router.pathname.includes('/searchresult');
  const { data: citrusShelfData } = useCitrusShelfData({ enabled: citrusShelfBannersEnabled });
  const { data: citrusSearchData } = useCitrusSearchPageData({
    enabled: citrusSearchBannersEnabled,
  });

  const searchBannersToDisplay = citrusSearchData?.ads.find(
    (adSlot) => adSlot.slotId === content.slot_id
  )?.banners;
  const shelfBannersToDisplay = citrusShelfData?.ads.find(
    (adSlot) => adSlot.slotId === content.slot_id
  )?.banners;
  const bannersToDisplay = isSearchPage ? searchBannersToDisplay : shelfBannersToDisplay;

  const citrusImgOrigin = !!bannersToDisplay && extractOrigin(bannersToDisplay[0]?.backgroundImage);

  return (
    <>
      {citrusImgOrigin ? (
        <Head>
          <link key="citrus-preconnect" rel="preconnect" href={citrusImgOrigin} />
        </Head>
      ) : null}
      {bannersToDisplay?.map((item, i) =>
        isCitrusBannerWithLink(item) ? (
          <CMSShelfSpacer key={i} removeHorizontalSpacing={{ s: true, l: true }}>
            <CMSBanner
              key={i}
              content={mapCitrusBannerToContentStackBanner(item)}
              sponsored
              citrusInfo={{ citrusAdId: item.citrusAdId }}
              legalPadding={{ s: true, m: true, l: false }}
              priority
              sizePerDevice={sizePerDevice}
            />
          </CMSShelfSpacer>
        ) : null
      )}
    </>
  );
}
