/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { type Ref, useState } from 'react';
import cx from 'classnames';
import { Grid } from '../../Grid';
import { useLocale } from '@/hooks/useLocale';
import { filterIcon } from '@/constants/images/filterIcon';
import FilterDataRefinement from '../FilterDataRefinement';
import ContentContainer from '../../AZCustomComponent/ContentContainer';
import type { FilterDataType, HandleFilterType } from '@/types/filterTypes';
import { useFilteredSortingOptions } from '@/hooks/useFilteredSortingOptions';
import FilterButton from '../FilterButton';
import FilterAndSortButton from '../FilterAndSortButton';
import FilterSubHeading from '../FiltersubHeading';
import CustomPriceRange from '../../AZCustomComponent/CustomPriceRange';
import QuickFilter from '../QuickFilter';
import { useFilterAndRedirect } from '@/hooks/useFilterAndRedirect';
import { useShowCustomPriceRangeFilter } from '@/hooks/useShowCustomPriceRangeFilter';
import { useRemoveAppliedPriceFilters } from '@/hooks/useRemoveAppliedPriceFilters';
import ChipTags from '../ChipTags';
import { useGetShelfSelectedFilters } from '@/hooks/useGetShelfSelectedFilters';
import { labelMap } from './labels';
import styles from './styles.module.scss';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { useRouter } from 'next/router';
import { routePaths } from '@/constants/routePaths';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { countryCodes } from '@/constants/locale';
import { priceFilterDisplayNames } from '../priceFilterDisplayNames';
import { useIsStoreSelected } from '@/features/header/hooks/useIsStoreSelected';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { usePriceHiding } from '@/hooks/usePriceHiding';
import { useLabels } from '@/hooks/useLabels';

export type FilterMobileComponentProps = {
  refQuickFilter?: Ref<HTMLElement>;
  staticFilterData: FilterDataType[] | undefined | null;
  dynamicFilterData: FilterDataType[] | undefined | null;
  pageType: string;
  onFilterSelect?: (a: HandleFilterType) => void;
  viewingPartTypes?: boolean;
  oneClickFilters?: Array<HandleFilterType>;
  quickFilters?: Array<FilterDataType>;
  clearAllChipTag?: React.ReactNode | null;
};

const FilterMobileComponent = ({
  refQuickFilter,
  staticFilterData,
  dynamicFilterData,
  pageType,
  onFilterSelect,
  viewingPartTypes = false,
  oneClickFilters,
  quickFilters,
  clearAllChipTag,
}: FilterMobileComponentProps) => {
  const handleFilterAndRedirect = useFilterAndRedirect();
  const removeCustomPriceRangeFilter = useRemoveAppliedPriceFilters();
  const showCustomPriceRangeFilter = useShowCustomPriceRangeFilter();
  const shelfSelectedFilters = useGetShelfSelectedFilters(staticFilterData, dynamicFilterData);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const [isQuickFilterOpen, setIsQuickFilterOpen] = useState(false);
  const isStoreSelected = useIsStoreSelected();
  const locale = useLocale();
  const router = useRouter();
  const isBrandPage = isBrandShelfPage(router.asPath);
  const isSearchPageType =
    pageType.includes('search') || router.pathname === routePaths.searchResult;
  const isShelfPageType = pageType.includes('shelf');
  const isPartTypesView = router.query.partTypesView === 'true';
  const isUS = locale === countryCodes.us;
  const isBR = locale === countryCodes.ptBr;
  const hidePricing = usePriceHiding();
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') &&
    isMobile &&
    isUS &&
    (isSearchPageType || isShelfPageType || isBrandPage);

  const onRemoveChipTag = (filter: HandleFilterType) => {
    if (filter.dimensionName === 'CustomPrice') {
      removeCustomPriceRangeFilter(() => onFilterSelect?.(filter));
    } else {
      handleFilterAndRedirect(filter, onFilterSelect);
    }
  };

  const [initialSortingOptions = [], sortIsQuickFilter] = useFilteredSortingOptions() || [];
  const sortingOptions = hidePricing
    ? initialSortingOptions.filter((option) => !option.parameter.includes('price'))
    : initialSortingOptions;

  const showSortOptions = !!sortingOptions.length;
  const hasQuickFilters =
    (!!quickFilters?.length || sortIsQuickFilter || showCustomPriceRangeFilter) &&
    (isShelfPageType || isSearchPageType || isBrandPage);

  const filterGridWidth = hasQuickFilters ? 12 : 6;
  const { isMobileFilterOpen, setIsMobileFilterOpen } = useProductListView();

  const { lblFilter, lblSelectedFilters, lblSortAndFilter } = useLabels(labelMap);
  let mobileFilterChipset = null;

  if (!isMobileFilterOpen) {
    mobileFilterChipset = (
      <div role="group" aria-label={lblSelectedFilters} className={styles.mobileChipSection}>
        <ul role="list">
          {oneClickFilters?.map((oneClickFilter, index) => (
            <ChipTags
              selectedFilter={oneClickFilter}
              index={index}
              handleFilter={onRemoveChipTag}
              key={`${oneClickFilter.refinement.label}${index}`}
            />
          ))}
        </ul>
      </div>
    );
  }

  const selectedFiltersLength =
    shelfSelectedFilters?.length > 0 ? ` (${shelfSelectedFilters.length})` : '';
  const showQuickFiltersMobile =
    isSearchPageType && viewingPartTypes ? !Boolean(router.query.searchText) : true;

  return (
    <section ref={refQuickFilter} className={styles.filterSection}>
      <ContentContainer classes={cx(styles.filterSectionContainer)} withPadding isTransparent>
        <Grid
          container
          spacing={1}
          alignItems={hasQuickFilters ? 'flex-start' : 'center'}
          justify="space-between"
          data-testid="filter-and-results-count-container"
          className={cx(styles.quickFilterContainer)}
        >
          <Grid
            item={!useNewShelfDesignMobile && !isMobile}
            md={filterGridWidth}
            className={cx(styles.shelfFilterBtnGroup, {
              [styles.noScroll]: isQuickFilterOpen,
              [styles.noFilterPaddingLeft]: viewingPartTypes && !showQuickFiltersMobile,
              [styles.withGap]: useNewShelfDesignMobile || isTablet || isMobile,
            })}
          >
            {isBR ? (
              <FilterButton
                className={cx({
                  [styles.quickFilter]: !useNewShelfDesignMobile,
                  [styles.slimMobileQuickFilter]: useNewShelfDesignMobile,
                })}
                onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
                data-testid="filter-button-mobile"
                icon={filterIcon}
                label={`${
                  isShelfPageType && showSortOptions && !sortIsQuickFilter
                    ? lblSortAndFilter
                    : lblFilter
                } ${selectedFiltersLength}`}
              />
            ) : (
              <FilterAndSortButton
                onClick={() => setIsMobileFilterOpen(!isMobileFilterOpen)}
                dataTestid="filter-and-sort-button-mobile"
                label={`${
                  (showSortOptions && isShelfPageType && !sortIsQuickFilter) || !isPartTypesView
                    ? lblSortAndFilter
                    : lblFilter
                } ${selectedFiltersLength}`}
              />
            )}
            {!!oneClickFilters?.length && mobileFilterChipset}
            {showQuickFiltersMobile && (
              <QuickFilterMobile
                handleFilterAndRedirect={handleFilterAndRedirect}
                quickFilters={quickFilters}
                onFilterSelect={onFilterSelect}
                pageType={pageType}
                setIsQuickFilterOpen={setIsQuickFilterOpen}
                showCustomPriceRangeFilter={showCustomPriceRangeFilter}
                isStoreSelected={isStoreSelected}
              />
            )}
            {clearAllChipTag && shelfSelectedFilters?.length > 1 && (
              <li className={styles.inlineListItem}>{clearAllChipTag}</li>
            )}
          </Grid>
        </Grid>
      </ContentContainer>
    </section>
  );
};

export const FilterMobile = React.memo(FilterMobileComponent);
FilterMobile.displayName = 'FilterMobile';

type QuickFilterMobileProps = {
  pageType: string;
  onFilterSelect: ((a: HandleFilterType) => void) | undefined;
  quickFilters: FilterDataType[] | undefined | null;
  showCustomPriceRangeFilter: boolean;
  setIsQuickFilterOpen: (a: boolean) => void;
  isStoreSelected: boolean;

  handleFilterAndRedirect: (
    filter: HandleFilterType,
    onFilterSelect?: ((a: HandleFilterType) => void) | undefined
  ) => void;
};
const QuickFilterMobile = ({
  showCustomPriceRangeFilter,
  setIsQuickFilterOpen,
  quickFilters,
  isStoreSelected,
  handleFilterAndRedirect,
  onFilterSelect,
  pageType,
}: QuickFilterMobileProps) => {
  const locale = useLocale();
  const isMX = locale === countryCodes.mx;
  const { lblSetCustomPriceRangeHeading } = useLabels(labelMap);

  return (
    <>
      {showCustomPriceRangeFilter && (
        <QuickFilter label={lblSetCustomPriceRangeHeading} handleChange={setIsQuickFilterOpen}>
          <div className={`${styles.filterCategory}`}>
            <FilterSubHeading title={lblSetCustomPriceRangeHeading.toUpperCase()} />
            <div className={styles.customPriceRangeContainer}>
              <CustomPriceRange quickFilter />
            </div>
          </div>
        </QuickFilter>
      )}
      {quickFilters?.map((filterItem: FilterDataType, index) => {
        const isMxPriceFilter =
          filterItem.dimensionName?.toLowerCase() === priceFilterDisplayNames.priceMX ||
          (isMX &&
            filterItem.dimensionName?.toLowerCase() === priceFilterDisplayNames.priceSearchUS);

        const isBrPriceFilter =
          locale === countryCodes.ptBr &&
          filterItem.dimensionName.toLowerCase() === priceFilterDisplayNames.priceSearchUS;

        if (
          !filterItem.refinements.length ||
          (isMxPriceFilter && !isStoreSelected) ||
          (isBrPriceFilter && !isStoreSelected)
        ) {
          return;
        }
        const { displayName } = filterItem;

        return (
          <QuickFilter
            key={`${displayName}${index}`}
            label={displayName}
            handleChange={setIsQuickFilterOpen}
          >
            <FilterDataRefinement
              classes={{
                root: styles.quickFilterRefinementRoot,
              }}
              filterItem={{ ...filterItem, quickFilter: true }}
              handleFilter={(filter) => {
                return handleFilterAndRedirect(filter, onFilterSelect);
              }}
              pageType={pageType}
            />
          </QuickFilter>
        );
      })}
    </>
  );
};
