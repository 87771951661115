/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import cx from 'classnames';
import Hidden from '@/components/Hidden';
import { FilterMobile } from '@/components/Filter/FilterMobile';
import { useFilteredSortingOptions } from '@/hooks/useFilteredSortingOptions';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import SortBy from '@/components/AZCustomComponent/SortBy';
import PageRecordCounter from '@/components/AZCustomComponent/PageRecordCounter';
import ListLayoutOptions from '@/components/ListLayoutOptions';
import ListLayoutOptionsV2 from '@/components/ListLayoutOptionsV2';
import ClearFiltersButton from '@/components/Filter/ClearFiltersButton';
import { useRouter } from 'next/router';
import { parseUrl } from '@/utils/urlHelpers';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import type { FilterDataType, HandleFilterType } from '@/types/filterTypes';
import { useGetShelfPageRecords } from '@/features/shelf/hooks/useGetShelfPageRecords';
import styles from './styles.module.scss';
import { useProductList } from '@/features/shelf/context/useProductList';
import {
  createRef,
  type MutableRefObject,
  type Ref,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useProductListView } from '@/features/shelf/context/useProductListView';
import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import { setSortByOrItemsPerPageFocus } from '@/actions/shelf/shelf';
import { useDispatch } from '@/hooks/redux/useDispatch';
import ChipTags from '@/components/Filter/ChipTags';
import { useRemoveAppliedPriceFilters } from '@/hooks/useRemoveAppliedPriceFilters';
import { useFilterAndRedirect } from '@/hooks/useFilterAndRedirect';
import { pageTypes } from '@/constants/page';
import { NumberOfResults } from '@/features/productListingPage/components/NumberOfResults';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useFeatureFlag } from '@/features/globalConfig';
import { countryCodes } from '@/constants/locale';
import { View } from '@az/starc-ui';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { useGetQuickAndOneClickFilters } from '@/hooks/useGetQuickAndOneClickFilters';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import { usePriceHiding } from '@/hooks/usePriceHiding';
import Image from '@/components/NextImage';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useSwipeable } from 'react-swipeable';
import { QuickFiltersDesktop } from '@/components/Filter/QuickFiltersDesktop';
import { useGetShelfSelectedFilters } from '@/hooks/useGetShelfSelectedFilters';
import { useIsSticky } from '../../hooks/useIsSticky';
import { useCombinedInfiniteQuery } from '@/hooks/useCombinedInfiniteQuery';
import { Grid } from '@/components/Grid';
import { ShareLinkButton } from '@/features/sharableLink';
import { useCountData } from '../../hooks/useCountData';
import azCommonStyles from '@/theme/globals.module.scss';

export type ListOptionProps = {
  refQuickFilter?: Ref<HTMLElement>;
  resultsListRef?: React.RefObject<HTMLDivElement>;
  pageType: string;
  onFilterSelect?: (a: HandleFilterType) => void;
  disableRecordsPerPage?: boolean;
  rppOptionsData?: string;
  staticFilterData?: FilterDataType[];
  searchText?: string;
  recordsPerPage?: number;
  removeMargin?: boolean;
  viewingPartTypes?: boolean;
};

const labelMap = {
  lblOf: 'label_checkout_shipping_Of',
  lblResults: 'label_Search_Results_Results',
  lblSelectedFilters: 'label_Selected_Filters',
  lblFor: 'label_Search_for',
};

const limit = 1;
const minRecs = 12;

export const ListOptions = ({
  refQuickFilter,
  pageType,
  onFilterSelect,
  disableRecordsPerPage,
  rppOptionsData,
  staticFilterData,
  searchText,
  recordsPerPage,
  removeMargin,
  resultsListRef,
  viewingPartTypes,
}: ListOptionProps) => {
  const isSearchPage = pageType === pageTypes.search;
  const { focusItemsPerPage, focusSortBy } = useSelector(({ shelf }: ReduxState) => shelf);
  const dispatch = useDispatch();
  const router = useRouter();
  const { url } = parseUrl(router.asPath);
  const { data: shelfData } = useProductList();
  const { data: searchProductData } = useSearchProductData({
    enabled: isSearchPage,
  });
  const shelfPageBody = shelfData?.shelfPageBody;
  const shelfLeftNav = shelfData?.shelfLeftNav;
  const { toggleListView, listViewState } = useProductListView();
  const isBrandPage = isBrandShelfPage(router.asPath);
  const isListView = isBrandPage ? listViewState.isBrandListView : listViewState.isListView;
  const { data: brandProductData } = useBrandProductData({ enabled: isBrandPage });
  const isNewGridAndListViewForMx = useFeatureFlag('IS_NEW_GRID_AND_LIST_VIEW_FOR_MX') === 'true';
  const isSharableLinksEnabled = useFeatureFlag('SHARABLE_LINKS_ENABLED') === 'true';
  const { data: productShelfResultsData } = useProductShelfResults();
  const [selectedOneClickFilterIndex, setSelectedOneClickFilterIndex] = useState(0);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const oneClickFilterRefs = useRef<Array<MutableRefObject<HTMLLIElement>>>([]);
  const unorderedListElement: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const staticFilters = isSearchPage
    ? searchProductData?.staticNavigation
    : isBrandPage
    ? brandProductData?.staticNavigation
    : shelfLeftNav?.[0].staticNavigation;
  const pageQuickFilters = isSearchPage
    ? searchProductData?.quickFilters
    : isBrandPage
    ? brandProductData?.quickFilters
    : productShelfResultsData?.productShelfResults?.quickFilters;

  const shelfSelectedFilters = useGetShelfSelectedFilters(
    staticFilters,
    shelfLeftNav?.[0]?.dynamicNavigation?.attributeNarrowingList
  );
  const handleListViewToggle = (view: boolean) => {
    toggleListView({
      type: isBrandPage ? 'TOGGLE_BRAND_LIST_VIEW' : 'TOGGLE_LIST_VIEW',
      payload: view,
    });
  };

  const setFocus = (sortBy: boolean, itemsPerPage: boolean) => {
    dispatch(setSortByOrItemsPerPageFocus(sortBy, itemsPerPage));
  };

  const removeCustomPriceRangeFilter = useRemoveAppliedPriceFilters();
  const handleFilterAndRedirect = useFilterAndRedirect();
  const onRemoveChipTag = (filter: HandleFilterType) => {
    if (filter.dimensionName === 'CustomPrice') {
      removeCustomPriceRangeFilter(() => onFilterSelect?.(filter));
    } else {
      handleFilterAndRedirect(filter, onFilterSelect);
    }
  };
  const { oneClickFilters, quickFilters } = useGetQuickAndOneClickFilters(
    pageQuickFilters,
    staticFilters,
    shelfLeftNav?.[0]?.dynamicNavigation?.attributeNarrowingList
  );

  const selectedOneClickFilters = oneClickFilters.filter((filter) => filter.checked === true);
  const selectedQuickFilters = quickFilters
    ?.map((facet) => facet.refinements.filter((filter) => filter.properties.isSelected).length)
    ?.reduce(
      (totalSelectedFilters, currentSelectedFilters) =>
        totalSelectedFilters + currentSelectedFilters,
      0
    );
  const selectedOneClickAndQuickFilters = selectedOneClickFilters.length + selectedQuickFilters;
  const clearAllChipTag = selectedOneClickAndQuickFilters > 1 && (
    <ClearFiltersButton pageType={pageType} />
  );
  const shouldShowClearAll = clearAllChipTag && selectedOneClickAndQuickFilters > 1;
  const pageRecData = useGetShelfPageRecords(shelfPageBody);

  const initialRecsPerPage =
    recordsPerPage ??
    (isBrandPage ? brandProductData?.recordsPerPage : searchProductData?.recordsPerPage) ??
    (pageRecData?.recsPerPage || minRecs);

  const recsPerPage = initialRecsPerPage === 0 ? minRecs : initialRecsPerPage;
  const shouldDisableRecsPerPage = disableRecordsPerPage ?? pageRecData?.disableRecordsPerPage;
  const sponsoredProductsLength =
    shelfData?.shelfParts?.filter((part) => part.isSponsoredCard).length ?? 0;
  const numRecs =
    (isBrandPage
      ? brandProductData?.total
      : pageRecData
      ? pageRecData.totalNumRecs
      : searchProductData?.total) ??
    (pageRecData?.totalNumRecs || 0);
  const totalNumRecs = numRecs + sponsoredProductsLength;
  const is24ProductViewEnabled = useFeatureFlag('IS_24_PRODUCT_VIEW_ENABLED') === 'true';
  const hidePricing = usePriceHiding();
  let rppOptions = rppOptionsData ?? (pageRecData?.rppOptions || minRecs.toString());
  if (is24ProductViewEnabled && rppOptions.split('12,').length === 2) {
    rppOptions = rppOptions.split('12,')[1];
  }
  const pageNumber: number = router.query.pageNumber ? Number(router.query.pageNumber) : 1;
  const firstPageRec =
    (isBrandPage
      ? brandProductData?.firstRecNum
      : pageRecData
      ? pageRecData.firstRecNum
      : searchProductData?.firstRecNum) ?? recsPerPage * (pageNumber - limit) + limit;
  const [initialSortingOptions = []] = useFilteredSortingOptions() || [];
  const sortingOptions = hidePricing
    ? initialSortingOptions.filter((option) => !option.parameter.includes('price'))
    : initialSortingOptions;
  const showSortOptions = !!sortingOptions && !!sortingOptions.length;
  const labels = useLabels(labelMap);
  const locale = useLocale();
  const isBrandsPage = isBrandShelfPage(url);
  const isShelfPageType = pageType.includes('shelf');
  const isSearchPageType = pageType.includes('search');
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') &&
    locale === countryCodes.us &&
    (isShelfPageType || isSearchPageType || isBrandPage);
  const counterSection = shouldDisableRecsPerPage ? null : (
    <div className={styles.pageRecordCounterContainer}>
      <PageRecordCounter
        recordsPerPage={recsPerPage}
        totalRecords={totalNumRecs}
        recordsPerPageOptions={rppOptions}
        enableRecordsPerPageOptions={totalNumRecs > (is24ProductViewEnabled ? 24 : 12)}
        focusItem={focusItemsPerPage}
        setFocus={setFocus}
        disableTotalMarkup
      />
    </div>
  );
  const isBR = locale === countryCodes.ptBr;
  const { hasNextPage } = useCombinedInfiniteQuery();
  const underRecsPerPage = totalNumRecs < recsPerPage;
  const { mobileFiltersRef } = useIsSticky(
    styles.stickyMobileFilters,
    isLoadMoreEnabled && hasNextPage
      ? styles.stickyFiltersLoadMore
      : totalNumRecs > recsPerPage
      ? styles.stickyFiltersPagination
      : styles.removeFiltersMargin,
    underRecsPerPage,
    resultsListRef
  );

  const shouldShowLeftArrow = () => {
    if (unorderedListElement?.current && oneClickFilterRefs?.current[0]) {
      if (
        oneClickFilterRefs?.current[0]?.current?.getBoundingClientRect()?.left <
        unorderedListElement?.current?.getBoundingClientRect()?.left
      ) {
        setHideLeftArrow(false);
      } else {
        setHideLeftArrow(true);
      }
    }
  };

  const shouldShowRightArrow = () => {
    if (
      unorderedListElement?.current &&
      oneClickFilterRefs?.current[oneClickFilterRefs.current.length - 1]
    ) {
      if (
        oneClickFilterRefs?.current[
          oneClickFilterRefs?.current.length - 1
        ]?.current?.getBoundingClientRect()?.right -
          1 >
        unorderedListElement?.current?.getBoundingClientRect()?.right
      ) {
        setHideRightArrow(false);
      } else {
        setHideRightArrow(true);
      }
    }
  };

  const scrollBlockScrolled = useCallback(() => {
    shouldShowLeftArrow();
    shouldShowRightArrow();
  }, []);

  useEffect(() => {
    let resizeObserver: ResizeObserver | null = null;
    let divElementRefValue: HTMLDivElement | null = null;

    if (unorderedListElement.current) {
      divElementRefValue = unorderedListElement?.current;
      unorderedListElement?.current?.addEventListener('scroll', scrollBlockScrolled);
      resizeObserver = new ResizeObserver(scrollBlockScrolled);
      resizeObserver.observe(unorderedListElement?.current);
    }

    return () => {
      if (divElementRefValue) {
        divElementRefValue.removeEventListener('scroll', scrollBlockScrolled);
        resizeObserver?.disconnect();
      }
    };
  }, [oneClickFilters, scrollBlockScrolled]);

  const handleLeftClick = useCallback(() => {
    const isScrolled =
      oneClickFilterRefs?.current?.length &&
      unorderedListElement?.current &&
      oneClickFilterRefs?.current[0]?.current?.getBoundingClientRect()?.left <
        unorderedListElement?.current?.getBoundingClientRect()?.left;
    if (isScrolled) {
      const partCards = oneClickFilterRefs.current;
      const targetPartCard =
        partCards[selectedOneClickFilterIndex > 0 ? selectedOneClickFilterIndex - 1 : 0];

      unorderedListElement.current.scrollTo({
        left: targetPartCard.current.offsetLeft - 6,
        top: 0,
        behavior: matchesTablet ? 'auto' : 'smooth',
      });
      setSelectedOneClickFilterIndex(
        selectedOneClickFilterIndex > 0 ? selectedOneClickFilterIndex - 1 : 0
      );
    }
  }, [matchesTablet, selectedOneClickFilterIndex]);

  const handleRightClick = useCallback(() => {
    const isScrollable = oneClickFilterRefs?.current?.length && unorderedListElement?.current;
    const isOverFlowing =
      unorderedListElement?.current &&
      oneClickFilterRefs?.current[
        oneClickFilterRefs?.current?.length - 1
      ]?.current?.getBoundingClientRect()?.right >
        unorderedListElement?.current?.getBoundingClientRect()?.right;
    if (isScrollable && isOverFlowing) {
      const partCards = oneClickFilterRefs.current;
      const targetPartCard =
        partCards[
          selectedOneClickFilterIndex < oneClickFilterRefs.current.length - 1
            ? selectedOneClickFilterIndex + 1
            : oneClickFilterRefs.current.length - 1
        ];
      unorderedListElement.current.scrollTo({
        left: targetPartCard.current.offsetLeft - 6,
        top: 0,
        behavior: matchesTablet ? 'auto' : 'smooth',
      });
      setSelectedOneClickFilterIndex(
        selectedOneClickFilterIndex < oneClickFilterRefs.current.length - 1
          ? selectedOneClickFilterIndex + 1
          : oneClickFilterRefs.current.length - 1
      );
    }
  }, [matchesTablet, selectedOneClickFilterIndex]);

  useEffect(() => {
    // If right arrow button is hidden, scroll to the end of the list
    if (hideRightArrow && !hideLeftArrow) {
      unorderedListElement?.current?.scrollTo({
        left: oneClickFilterRefs?.current[oneClickFilterRefs?.current?.length - 1]?.current
          ?.offsetLeft,
        top: 0,
        behavior: matchesTablet ? 'auto' : 'smooth',
      });
    }
  }, [hideLeftArrow, hideRightArrow, matchesTablet]);

  const refsLength =
    oneClickFilters.length + quickFilters.length + 1 + (shouldShowClearAll ? 1 : 0);
  if (oneClickFilters.length && oneClickFilterRefs.current.length !== refsLength) {
    oneClickFilterRefs.current = Array(refsLength)
      .fill(null)
      .map((_, i) => oneClickFilterRefs.current[i] || createRef());
  }
  const swipeableHandlers = useSwipeable({
    onSwipedLeft: () => handleRightClick(),
    onSwipedRight: () => handleLeftClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const filterGridWidth = !!quickFilters.length ? 12 : 6;
  const countData = useCountData(isSearchPage, isBrandPage, viewingPartTypes);

  const resultCount =
    countData.recsPerPage && countData.total > 0
      ? `${countData.firstRecNum}-${countData.lastRecNum} ${labels.lblOf.toLowerCase()} ${
          countData.total
        } ${labels.lblResults}`
      : null;
  const displayResults = (
    <Hidden mdUp>
      <Grid item={!useNewShelfDesignMobile} md={filterGridWidth}>
        {!!resultCount && (
          <>
            {!useNewShelfDesignMobile && (
              <div className={styles.resultsAndShareContainer}>
                <span
                  data-testid="mobile-results-count"
                  id="mobile-results-count"
                  className={cx(azCommonStyles['az-body-2-regular'], styles.resultsText)}
                >
                  {resultCount}
                </span>
                {isSharableLinksEnabled && (
                  <ShareLinkButton
                    pageName="shelf"
                    classes={{ container: styles.shareContainer }}
                  />
                )}
              </div>
            )}
          </>
        )}
      </Grid>
    </Hidden>
  );

  const sortByRef = oneClickFilterRefs?.current[0];
  const clearAllRef = shouldShowClearAll
    ? oneClickFilterRefs?.current[oneClickFilterRefs?.current.length - 1]
    : undefined;
  useEffect(() => {
    const sortByObserver = new IntersectionObserver(
      ([entry]) => {
        entry.target.setAttribute(
          'data-testid',
          entry.isIntersecting ? 'sort-by-chip-visible' : 'sort-by-chip-hidden'
        );
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    const clearAllObserver = new IntersectionObserver(
      ([entry]) => {
        entry.target.setAttribute(
          'data-testid',
          entry.isIntersecting ? 'clear-all-visible' : 'clear-all-hidden'
        );
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (sortByRef?.current) {
      sortByObserver.observe(sortByRef.current);
    }

    if (clearAllRef?.current) {
      clearAllObserver.observe(clearAllRef.current);
    }

    return () => {
      sortByObserver.disconnect();
      clearAllObserver.disconnect();
    };
  }, [clearAllRef, sortByRef]);

  return (
    <>
      <div {...swipeableHandlers} className={styles.filterChipsScrollContainer}>
        <div
          className={cx(styles.leftArrow, {
            [styles.hiddenArrow]: hideLeftArrow,
          })}
          data-testid={hideLeftArrow ? 'hidden-left-arrow' : 'left-arrow'}
          tabIndex={0}
          onClick={handleLeftClick}
          onKeyDown={(e) => {
            if ((e || window.event).keyCode == 13) {
              handleLeftClick();
            }
          }}
        >
          <Image
            className={styles.chevronImgHover}
            src="/images/arrow_right_hover.svg"
            alt=""
            height={68}
            width={57}
          />
          <Image
            className={styles.chevronImg}
            src="/images/arrow_right_initial.svg"
            alt="scroll carousel left"
            height={64}
            width={57}
          />
        </div>
        <div
          role="group"
          aria-label={labels.lblSelectedFilters}
          className={styles.filterChipsScroll}
          ref={unorderedListElement}
        >
          <ul role="list" className={styles.filterChipsList}>
            {!!showSortOptions && (
              <li ref={sortByRef} data-testid={'sort-by-chip-visible'}>
                <SortBy
                  focusItem={focusSortBy}
                  setFocus={setFocus}
                  sortingOptions={sortingOptions}
                  isQuickFilter
                  placement="bottom-start"
                />
              </li>
            )}
            {!!oneClickFilters && oneClickFilters.length !== 0
              ? oneClickFilters.map((oneClickFilter, index) => (
                  <ChipTags
                    selectedFilter={oneClickFilter}
                    index={index}
                    handleFilter={onRemoveChipTag}
                    oneClickFilterRefs={oneClickFilterRefs}
                    key={`${oneClickFilter.refinement.label}${index}`}
                  />
                ))
              : null}
            {quickFilters.map((quickFilter, index) => (
              <QuickFiltersDesktop
                quickFilter={quickFilter}
                onFilterSelect={onFilterSelect}
                pageType={pageType}
                index={index}
                shelfSelectedFilters={shelfSelectedFilters}
                oneClickFilterRefs={oneClickFilterRefs}
                oneClickFiltersLength={oneClickFilters.length}
                key={`${quickFilter.displayName}${index}`}
              />
            ))}
            {shouldShowClearAll && (
              <li className={styles.inlineListItem} ref={clearAllRef}>
                {clearAllChipTag}
              </li>
            )}
          </ul>
        </div>
        <div
          className={cx(styles.rightArrow, {
            [styles.hiddenArrow]: hideRightArrow,
          })}
          data-testid={hideRightArrow ? 'hidden-right-arrow' : 'right-arrow'}
          tabIndex={0}
          onClick={handleRightClick}
          onKeyDown={(e) => {
            if ((e || window.event).keyCode == 13) {
              handleRightClick();
            }
          }}
        >
          <Image
            className={styles.chevronImgHover}
            src="/images/arrow_right_hover.svg"
            alt=""
            height={68}
            width={57}
          />
          <Image
            className={styles.chevronImg}
            src="/images/arrow_right_initial.svg"
            alt="scroll carousel right"
            height={64}
            width={57}
          />
        </div>
      </div>

      <div className={styles.listOptionsContainer}>
        <NumberOfResults
          firstPageRec={firstPageRec}
          recsPerPage={recsPerPage}
          totalNumRecs={totalNumRecs}
          searchText={searchText}
          removeMargin={removeMargin}
        />

        <div className={styles.resultsOptionContainer}>
          {!isBrandsPage && !isLoadMoreEnabled && counterSection}
          {locale === countryCodes.us || isBR || isNewGridAndListViewForMx ? (
            <ListLayoutOptionsV2
              triggerShelfAnalytics={true}
              isList={isListView}
              toggleListView={handleListViewToggle}
            />
          ) : (
            <ListLayoutOptions
              triggerShelfAnalytics={true}
              isList={isListView}
              toggleListView={handleListViewToggle}
            />
          )}
        </div>
      </div>
      <div className={cx(styles.mobileFilters)} ref={mobileFiltersRef}>
        <FilterMobile
          refQuickFilter={refQuickFilter}
          staticFilterData={
            (shelfData?.shelfLeftNav?.[0]?.staticNavigation as unknown as FilterDataType[]) ??
            (shelfData?.shelfLeftNav as unknown as FilterDataType[]) ??
            staticFilterData ??
            shelfLeftNav?.[0]?.staticNavigation
          }
          dynamicFilterData={shelfLeftNav?.[0]?.dynamicNavigation?.attributeNarrowingList}
          pageType={pageType}
          onFilterSelect={onFilterSelect}
          oneClickFilters={oneClickFilters}
          quickFilters={quickFilters}
          clearAllChipTag={clearAllChipTag}
        />
      </div>
      {useNewShelfDesignMobile ? (
        <Hidden mdUp>
          <View padding={isMobile ? [0, 4, 2, 2] : [0, 4]}>
            <NumberOfResults
              firstPageRec={firstPageRec}
              recsPerPage={recsPerPage}
              totalNumRecs={totalNumRecs}
              searchText={searchText}
            />
          </View>
        </Hidden>
      ) : (
        displayResults
      )}
    </>
  );
};
