/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type MutableRefObject, useEffect } from 'react';
import scrollToElem from '../utils/crossBrowserHelpers/scroll';
type Props = {
  ref: MutableRefObject<any>;
  dependencies: Array<any>;
  conditions?: boolean;
  afterScroll?: Function;
  scrollOffset?: number;
};

export const useScrollToRef = ({
  ref,
  dependencies,
  conditions = false,
  afterScroll,
  scrollOffset = 80,
}: Props) => {
  useEffect(() => {
    if (ref && conditions) {
      const top = ref.current?.offsetTop - scrollOffset;
      scrollToElem(top);
      afterScroll?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ...dependencies]);
};
