/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
export const getSortValue = (sort: string): { sortOrder: string; sortFieldName: string } => {
  const sortArr = sort.split('-');
  const length = sortArr.length;

  if (length > 1) {
    return {
      sortOrder: sortArr[length - 1],
      sortFieldName: sortArr.slice(0, length - 1).join('-'),
    };
  }

  return {
    sortOrder: '',
    sortFieldName: sort,
  };
};
