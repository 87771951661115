/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { type AxiosInstance, isAxiosError } from 'axios';
import { requestBaseURL } from '@/config/serviceAPI';
import type {
  FacetModel,
  ProductSearchResponseModel,
  SearchRequestModel,
} from '@/api/types/browse-search-types';
import { getAxios } from '@/lib/axios';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { type NextRouter, useRouter } from 'next/router';
import { getApiOptions } from '../utils/getSearchApiOptions';
import { getMapFacet, useMapFacet } from '@/hooks/useMapFacet';
import {
  type InfiniteData,
  type QueryClient,
  type QueryFunctionContext,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import type { MappedSearchProductData } from '../interface';
import { usePageType } from '@/hooks/usePageType';
import { useGlobalState } from '@/hooks/useGlobalState';
import { getKiboDecisionFlag, useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import logger from '@/utils/logger';
import { countryCodes } from '@/constants/locale';
import { useMemo } from 'react';
import { useAppState } from '@/stores/AppState';
import { routePaths } from '@/constants/routePaths';
const STALE_TIME_5_MINUTES = 60000 * 5;

const PART_TYPES_URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/products/search`;

const validSortingOptions = new Set([
  'featured',
  'price-asc',
  'price-desc',
  'recently-added-desc',
  'customer-rating-desc',
  'best-seller-asc',
  'best-seller-desc',
]);

type GetSearchProductDataOptions = {
  mapFacet: ReturnType<typeof useMapFacet>;
  locale: Locale;
  facet: string | undefined;
  pageNumber: number | undefined;
  recordsPerPage: number | undefined;
  searchText: string | undefined;
  sort:
    | {
        sortFieldName: string | undefined;
        sortOrder: string | undefined;
      }
    | undefined;
  storeId: string | undefined;
  userSegment: string | undefined;
  vehicleId: string | undefined;
  preview: boolean;
  skipPdpRedirect: boolean;
  userProfileId?: string;
  eCookieId: string;
  rewardsId?: string;
};

export const searchProductDataSelector = (
  {
    searchResults,
    redirectUrl,
    redirectPageType,
    oemBrandName,
    oemPartNumber,
    lwTestExperience,
    xFusionQueryId,
  }: ProductSearchResponseModel,
  mapFacet: ReturnType<typeof useMapFacet> | undefined
): MappedSearchProductData => {
  return {
    redirectUrl,
    redirectPageType,
    oemBrandName,
    oemPartNumber,
    lwTestExperience,
    xFusionQueryId,
    isUnitOfMeasureMatch: searchResults?.isUnitOfMeasureMatch,
    interchangeSearchFlag: searchResults?.interchangeSearchFlag,
    correctedTerm: searchResults?.correctedTerm,
    recordsPerPage: searchResults?.recordsPerPage,
    total: searchResults?.totalNumberOfRecords,
    quickFilters: searchResults?.quickFilters,
    firstRecNum: searchResults?.firstRecordNumber,
    lastRecNum: searchResults?.lastRecordNumber,
    partTypeRecords: searchResults?.partTypeRecords,
    staticNavigation: searchResults?.facets?.map(
      mapFacet ?? ((facet: FacetModel) => getMapFacet(facet, 'Clearance'))
    ),
    searchedKeyword: searchResults?.searchedKeyword,
    records: searchResults?.skuRecords?.map?.((record) => ({
      active: record.activeFlag,
      alternatePartNumber: record.alternatePartNumber,
      application: record.applicationQuestions?.join?.(', ') ?? '',
      brandName: record.brandName,
      citrusAdId: record.citrusAdId,
      seoUrl: record.productDetailsPageUrl,
      imageUrl: record.itemImageUrl ?? '',
      productImageUrl: record.itemImageUrl ?? '',
      description: record.itemDescription,
      skuNumber: Number(record.itemId),
      lineCode: record.lineCode,
      systemCode: Number(record.originalSystemCode),
      partNumber: record.partNumber,
      partTypeName: record.partTypeName,
      productRepositoryId: record.eCommerceProductId,
      productId: record.eCommerceProductId,
      quickNote: record.quickNote,
      techNote: record.technicalNote,
      recordType: record.recordType,
      skuReviewEnabled: record.reviewEnabledFlag,
      vehicleFitmentLabel: record.vehicleFitmentLabel,
      vehicleSpecific: record.vehicleSpecificFlag,
      warrantyMonths: record.warrantyMonths,
      locationFilter: record.itemLocation,
      brand: record.brandName ?? '',
      name: record.itemDescription,
      productReviewsEnabled: record.reviewEnabledFlag,
      vehicleFit: record.vehicleFitmentFlag ?? false,
      originalPartTypeId: record.originalPartTypeId ?? '',
      productCanonicalUrl: record.productCanonicalUrl ?? '',
      productSeoUrl: record.productSeoUrl ?? '',
      interchangeSearchFlag: record.interchangeSearchFlag ?? false,
      oemBrandName: record.oemBrandName,
      oemPartNumber: record.oemPartNumber,
      warrantyText: record.warrantyText,
      sponsoredProductFlag: record.sponsoredProductFlag,
    })),
  };
};

const getSearchProductData = async (
  options: GetSearchProductDataOptions,
  axiosInstance?: AxiosInstance
) => {
  const { locale, searchText, userProfileId, eCookieId, rewardsId, ...rest } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  if (!searchText) {
    throw new Error('searchText is required');
  }
  const reqData: SearchRequestModel = {
    ...rest,
    country,
    customerType,
    salesChannel,
    searchText,
    userProfileId,
    ...(!!options.facet &&
      options.facet.includes('vehicle_fitment') && {
        ignoreVehicleSpecificProductsCheck: true,
      }),
  };

  const response = await getAxios(axiosInstance).post<ProductSearchResponseModel>(
    PART_TYPES_URL,
    reqData,
    {
      headers: {
        eCookieId,
        ...(!!rewardsId && { rewardsId }),
      },
    }
  );

  return response.data;
};

const {
  useData: useSearchParts,
  prefetch: prefetchParts,
  query: productDataQuery,
} = createQuery<ProductSearchResponseModel, GetSearchProductDataOptions>(
  'searchProductData',
  getSearchProductData
);

const handleSortingOptionError = async (router: NextRouter) => {
  try {
    // eslint-disable-next-line no-console
    console.error(
      `An error occurred while requesting search results. Invalid sorting option was input`
    );
    await router.replace('/errorPage');
    return;
  } catch (error) {
    throw new Error(`There was a problem redirecting to /errorPage`);
  }
};

export const useSearchProductData = (
  { enabled = true }: { enabled: boolean } = {
    enabled: true,
  }
) => {
  const enable24ProductView = useIs24ProductViewEnabled();
  const { state } = useAppState();
  const locale = useLocale();
  const router = useRouter();
  const { searchText } = router.query;
  const mapFacet = useMapFacet();
  const preferredVehicle = usePreferredVehicle();
  const vehicleId = preferredVehicle?.catalogVehicleId;
  const { data: storeDetailsData } = useStoreDetailsData();
  const storeNumber = storeDetailsData?.storeNumber;
  const [searchState] = useGlobalState('search');
  const { data: headerData } = useHeaderData();
  const { pageType } = usePageType();
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  const userProfileId = headerData?.myAccountMap?.profileId;
  const rewardsId = headerData?.myAccountMap?.rewardsId ?? '';

  let infiniteQueryData: InfiniteData<ProductSearchResponseModel> | undefined = undefined;
  let fetchNextPageSearchProductData = () => Promise.resolve();
  let hasNextPageSearchProductData: boolean | undefined = false;
  let fetchPreviousPageSearchProductData = () => Promise.resolve();
  let hasPreviousPageSearchProductData: boolean | undefined = false;
  let isFetchingSearchProductData: boolean | undefined = false;
  let isLoadingSearchProductData: boolean | undefined = false;
  let statusSearchProductResults = 'idle';
  let refetchSearchProductResults = () => Promise.resolve();

  const options = getApiOptions({
    router,
    locale,
    vehicleId,
    storeNumber,
    mapFacet,
    preview: showXMPreviewDate(),
    vehicleIdChanged: searchState?.preferredVehicleChanged,
    ...(userProfileId && { userProfileId }),
  });
  const sortParam = options.sort
    ? !options.sort.sortOrder
      ? `${options.sort.sortFieldName}`
      : `${options.sort.sortFieldName}-${options.sort.sortOrder}`
    : null;

  const isValidSortingOption = validSortingOptions.has(sortParam ?? '');

  if (options.sort && !isValidSortingOption && pageType === 'search') {
    void handleSortingOptionError(router);
  }
  const infiniteQueryResult = useInfiniteQuery({
    queryKey: [
      ...productDataQuery.getFullKey({
        ...options,
        pageNumber:
          typeof router.query.pageNumber === 'string' ? Number(router.query.pageNumber) : 1,
        recordsPerPage:
          enable24ProductView || isLoadMoreEnabled
            ? 24
            : typeof router.query.recsPerPage === 'string'
            ? Number(router.query.recsPerPage)
            : undefined,
        skipPdpRedirect: options.skipPdpRedirect ?? false,
        eCookieId: state.eCookieId,
        rewardsId,
      }),
      'infinite',
    ],
    queryFn: ({ pageParam = 1 }: { pageParam?: number }) =>
      getSearchProductData({
        ...options,
        pageNumber: pageParam ?? 1,
        recordsPerPage: Number(router.query.recsPerPage) || 24,
        skipPdpRedirect: options.skipPdpRedirect ?? false,
        eCookieId: state.eCookieId,
        rewardsId,
      }),
    getNextPageParam: (lastPage, allPages) => {
      const totalRecords = lastPage?.searchResults?.totalNumberOfRecords ?? 0;
      const recsPerPage = Number(router.query.recsPerPage) || 24;
      const currentPageNumber = allPages.length;
      const totalPages = Math.ceil(totalRecords / recsPerPage);

      if (currentPageNumber < totalPages) {
        return currentPageNumber + 1;
      }
      return undefined;
    },
    enabled: Boolean(
      enabled &&
        router.route === routePaths.searchResult &&
        isLoadMoreEnabled &&
        searchText &&
        typeof searchText === 'string' &&
        searchText !== '' &&
        (storeNumber || locale === countryCodes.mx || locale === countryCodes.ptBr)
    ),
    staleTime: STALE_TIME_5_MINUTES,
    onError: (err) => {
      logger.error({
        message: 'Error fetching search product results',
        meta: {
          error: isAxiosError(err) ? err.response : err,
        },
      });
    },
  });

  const regularQueryResult = useSearchParts({
    ...options,
    pageNumber: typeof router.query.pageNumber === 'string' ? Number(router.query.pageNumber) : 1,
    recordsPerPage:
      enable24ProductView || isLoadMoreEnabled
        ? 24
        : typeof router.query.recsPerPage === 'string'
        ? Number(router.query.recsPerPage)
        : undefined,
    skipPdpRedirect: options.skipPdpRedirect ?? false,
    enabled: Boolean(
      enabled &&
        router.route === routePaths.searchResult &&
        searchText &&
        typeof searchText === 'string' &&
        searchText !== '' &&
        (storeNumber || locale === countryCodes.mx || locale === countryCodes.ptBr) &&
        !isLoadMoreEnabled
    ),
    staleTime: STALE_TIME_5_MINUTES,
    eCookieId: state.eCookieId,
    rewardsId,
  });

  if (isLoadMoreEnabled) {
    const {
      data,
      fetchNextPage,
      hasNextPage,
      fetchPreviousPage,
      hasPreviousPage,
      isFetching,
      isLoading,
      status,
      refetch,
    } = infiniteQueryResult;
    fetchNextPageSearchProductData = () => fetchNextPage().then(() => {});
    hasNextPageSearchProductData = hasNextPage;
    fetchPreviousPageSearchProductData = () => fetchPreviousPage().then(() => {});
    hasPreviousPageSearchProductData = hasPreviousPage;
    isLoadingSearchProductData = isLoading;
    statusSearchProductResults = status;
    isFetchingSearchProductData = isFetching;
    refetchSearchProductResults = () => refetch().then(() => {});
    infiniteQueryData = data;
  }

  const mergedData = useMemo<ProductSearchResponseModel | undefined>(() => {
    if (!infiniteQueryData?.pages) {
      return undefined;
    }
    return infiniteQueryData?.pages?.reduce<ProductSearchResponseModel>(
      (acc, page) => {
        if (page.searchResults) {
          return {
            ...acc,
            searchResults: {
              ...acc.searchResults,
              ...page.searchResults,
              skuRecords: [
                ...(acc.searchResults?.skuRecords ?? []),
                ...(page.searchResults?.skuRecords ?? []),
              ],
            },
            redirectUrl: page.redirectUrl,
            oemBrandName: page.oemBrandName,
            oemPartNumber: page.oemPartNumber,
            redirectPageType: page.redirectPageType,
            interchangeSearchFlag: page.interchangeSearchFlag,
            lwTestExperience: page.lwTestExperience,
            xFusionQueryId: page.xFusionQueryId,
          };
        }
        return {
          ...acc,
          redirectUrl: page.redirectUrl,
          oemBrandName: page.oemBrandName,
          oemPartNumber: page.oemPartNumber,
          redirectPageType: page.redirectPageType,
          interchangeSearchFlag: page.interchangeSearchFlag,
          lwTestExperience: page.lwTestExperience,
          xFusionQueryId: page.xFusionQueryId,
        };
      },
      {
        searchResults: {
          skuRecords: [],
          recordsPerPage: enable24ProductView
            ? 24
            : typeof router.query.recsPerPage === 'string'
            ? Number(router.query.recsPerPage)
            : 24,
          searchedKeyword: '',
        },
      }
    );
  }, [infiniteQueryData, enable24ProductView, router.query.recsPerPage]);
  return {
    data: isLoadMoreEnabled
      ? searchProductDataSelector(
          mergedData ??
            ({
              searchResults: {
                skuRecords: [],
                recordsPerPage: enable24ProductView
                  ? 24
                  : typeof router.query.recsPerPage === 'string'
                  ? Number(router.query.recsPerPage)
                  : undefined,
                searchedKeyword: '',
              },
            } as ProductSearchResponseModel),
          mapFacet
        )
      : searchProductDataSelector(
          regularQueryResult.data ??
            ({
              searchResults: {
                skuRecords: [],
                recordsPerPage: enable24ProductView
                  ? 24
                  : typeof router.query.recsPerPage === 'string'
                  ? Number(router.query.recsPerPage)
                  : undefined,
                searchedKeyword: '',
              },
            } as ProductSearchResponseModel),
          mapFacet
        ),
    pageNumber: isLoadMoreEnabled
      ? infiniteQueryData?.pages.length ?? 1
      : router.query.pageNumber ?? 1,
    fetchNextPage: fetchNextPageSearchProductData,
    hasNextPage: hasNextPageSearchProductData,
    fetchPreviousPage: fetchPreviousPageSearchProductData,
    hasPreviousPage: hasPreviousPageSearchProductData,
    isFetching: isLoadMoreEnabled ? isFetchingSearchProductData : regularQueryResult.isFetching,
    isLoading: isLoadMoreEnabled ? isLoadingSearchProductData : regularQueryResult.isLoading,
    status: isLoadMoreEnabled ? statusSearchProductResults : regularQueryResult.status,
    refetch: isLoadMoreEnabled ? refetchSearchProductResults : regularQueryResult.refetch,
    fetchStatus: isLoadMoreEnabled
      ? infiniteQueryResult.fetchStatus
      : regularQueryResult.fetchStatus,
    isSuccess: isLoadMoreEnabled ? infiniteQueryResult.isSuccess : regularQueryResult.isSuccess,
    isError: isLoadMoreEnabled ? infiniteQueryResult.isError : regularQueryResult.isError,
  };
};

// TODO: data in react-query cache is not mapped. We should be able to remove searchProductDataSelector
export const getSearchProductDataFromCache = (
  queryClient: QueryClient,
  options: GetSearchProductDataOptions
): ProductSearchResponseModel | undefined => {
  const isLoadMoreEnabled = getKiboDecisionFlag(queryClient, 'loadMoreEnabled');
  if (isLoadMoreEnabled) {
    return queryClient.getQueryData<InfiniteData<ProductSearchResponseModel>>([
      ...productDataQuery.getFullKey(options),
      'infinite',
    ])?.pages?.[0];
  }
  return queryClient.getQueryData<ProductSearchResponseModel>(productDataQuery.getFullKey(options));
};

type SearchProductInfiniteQueryKey = readonly [string, GetSearchProductDataOptions, 'infinite'];

export const prefetchSearchProductDataInfinity = async (
  queryClient: QueryClient,
  options: GetSearchProductDataOptions,
  axiosInstance: AxiosInstance
) => {
  await queryClient.prefetchInfiniteQuery({
    queryKey: [...productDataQuery.getFullKey(options), 'infinite'],
    queryFn: ({ pageParam }: QueryFunctionContext<SearchProductInfiniteQueryKey, number>) =>
      getSearchProductData(
        {
          ...options,
          pageNumber: pageParam ?? 1,
          skipPdpRedirect: options.skipPdpRedirect ?? false,
        },
        axiosInstance
      ),
    getNextPageParam: (lastPage, allPages) => {
      const nextPageNumber = allPages.length + 1;
      return lastPage.searchResults?.lastRecordNumber ? nextPageNumber : undefined;
    },
  });
};

export const prefetchSearchProductData = async (
  queryClient: QueryClient,
  options: GetSearchProductDataOptions,
  axiosInstance: AxiosInstance
) => {
  await prefetchParts(queryClient, options, axiosInstance);
};
