/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import cx from 'classnames';
import root from 'window-or-global';
import Quantity from '../../AZCustomComponent/QuantityComponent';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { FULFILLMENT_METHODS } from '../../../constants/fulfillmentConstants';
import { cartConstants } from '../../../constants/cart';
import azCommonStyles from '../../../theme/globals.module.scss';
import { usePatchOrderItem } from '@/features/orders/api/patchOrderItem';
import type { ItemQohErrorMessage } from '@/utils/itemHelpers/types';
import { mergeDefaultWithState } from '@/utils/itemHelpers/fulfillmentHelpers/mergeDefaultWithState';
import { getMaxAvailQtyFromOptions } from '@/utils/itemHelpers/fulfillmentHelpers/getMaxAvailQtyFromOptions';
import { createItemQoHErrorMessage } from '@/utils/itemHelpers/fulfillmentHelpers/createItemQoHErrorMessage';
import { calculateNewFulfillmentProps } from '@/utils/itemHelpers/fulfillmentHelpers/calculateNewFulfillmentProps';
import { getItemFulfillmentoptionsFromGroups } from '@/utils/itemHelpers/fulfillmentHelpers/getItemFulfillmentoptionsFromGroups';
import styles from './styles.module.scss';
import type { UpdateQuantityQoHError } from '@/types/analytics';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { createFilteredFulfillmentAvailableAtSelectedQty } from '@/utils/analytics/legacyUtils/createFilteredFulfillmentAvailableAtSelectedQty';
import { createFulfillmentAvailableAtSelectedQtyMessage } from '@/utils/analytics/legacyUtils/createFulfillmentAvailableAtSelectedQtyMessage';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useQueryClient } from '@tanstack/react-query';
import {
  getProductSkuDetailsKey,
  getProductSkuDetailsFromCache,
} from '@/features/product/api/getProductSkuDetails';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { type getAllAvailableQuantitiesForSku } from '@/utils/getFulfillmentOption';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useRouter } from 'next/router';
import { useFeatureFlag } from '@/features/globalConfig';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { formatPrice } from '@/utils/validator/formatPrice';
import { useAvailabilityInfoData } from '@/features/orders/api/getAvailabilityInfo';

const qohErrorTypes = {
  cartQoH: 'Cart QOH',
};

const trackUpdateQuantityQoHError = (
  fulfillmentGroups: Array<FulfillmentGroup>,
  lineItem: any,
  requestedQuantity: number,
  itemQohErrorMessage: ItemQohErrorMessage
) => {
  const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
  const itemFulfillmentOptions = getItemFulfillmentoptionsFromGroups(fulfillmentGroups);
  const fulfillmentOptionsUpdated = calculateNewFulfillmentProps(
    itemFulfillmentOptions,
    Number(requestedQuantity)
  );
  const finalOptionsResult = mergeDefaultWithState(fulfillmentOptionsUpdated);
  const fulfillmentAvailableAtSelectedQty =
    createFilteredFulfillmentAvailableAtSelectedQty(
      finalOptionsResult,
      itemQohErrorMessage?.optionsWithError
    ) ?? '';
  const fulfillmentAvailableAtSelectedQtyMessage =
    createFulfillmentAvailableAtSelectedQtyMessage(finalOptionsResult, itemQohErrorMessage) ?? '';
  const productAvailableQuantity = getMaxAvailQtyFromOptions(finalOptionsResult).toString() ?? '';
  const brand = lineItem?.productInfo?.brand ?? '';
  const productId = lineItem?.productInfo?.skuId ?? '';
  const productCorePrice = lineItem?.lineItemPriceInfo?.corePrice?.toString() ?? '';
  const productDiscountPrice = lineItem?.lineItemPriceInfo?.dealAppliedAmount?.toString() ?? '';
  const productFullfillmentType = lineItem?.selectedFulfillmentType?.toString() ?? '';
  const productRequestedQuantity = requestedQuantity?.toString() ?? '';
  const clickData: UpdateQuantityQoHError = {
    eventType: 'updateQuantity',
    pageType: pageType,
    pageName: pageName,
    siteSection: siteSection,
    fulfillmentAvailableAtSelectedQtyList: [fulfillmentAvailableAtSelectedQty],
    fulfillmentAvailableAtSelectedQtyMessage: [fulfillmentAvailableAtSelectedQtyMessage],
    productAvailableQuantityList: [productAvailableQuantity],
    productBrandList: [brand],
    productCorePriceList: [productCorePrice],
    productDiscountPriceList: [productDiscountPrice],
    productFullfillmentTypeList: [productFullfillmentType],
    productIdList: [productId],
    productPartList: [],
    productRequestedQuantityList: [productRequestedQuantity],
    qohErrorType: [qohErrorTypes.cartQoH],
  };
  clickTrack(clickData);
};

type Props = {
  className?: string;
  data: any;
  isMini: boolean;
  label_shipping_NotAvailableForSelectedQty?: string;
  fulfillmentOptions?: Array<FulfillmentGroup>;
  showQuantityError?: (itemQohErrorMessage: ItemQohErrorMessage) => void;
  updatedValue?: (val: number) => void;
  updateQuantityError?: (errorMsg: boolean) => void;
  updateQuantityErrorItem?: (errorFlag: boolean, errorNdd?: boolean) => void;
  handleQuantityChange?: (
    quantity: number,
    quantityErrorMsg: boolean,
    showQuantityWarningMessage?: boolean
  ) => void;
  updateTotalQuantity?: (newQuantity: number, updatedOnMount?: boolean) => void;
  pageType?: string;
  updateIsLoading?: (isLoading: boolean) => void;
  updateRequestingOrderItem?: () => void;
  disableRemoveButton?: boolean;
  coreDeposit?: number;
  isCart?: boolean;
  readyStatus?: string;
  fulFillmentId?: number;
  skuAvailableQuantities: ReturnType<typeof getAllAvailableQuantitiesForSku>;
  isNewMessagingShown?: boolean | unknown;
  isDiscountedStrikeThroughPrice?: boolean;
  removePart?: () => Promise<void>;
};

const CommonCounterPartV2 = (props: Props) => {
  const [count, setCount] = useState(parseInt(props.data.itemQuantity || 1));
  const [quantityForADA, setQuantityForADA] = useState(props.data.itemQuantity);
  const [quantityErrorMsg, setQuantityErrorMsg] = useState(false);
  const [isIncOrDecBtnTouched, setIsIncOrDecBtnTouched] = useState(false);
  const rootClass = props.className
    ? `${styles.counterpart} ${props.className}`
    : styles.counterpart;
  const timeout = useRef<any>(null);
  const cartMaxQtyReachedLabel = useLabel('label_cart_lineItem_MaximumQuantityReached');
  const labelQuantityNotAvailable = useLabel('label_shipping_NotAvailableForSelectedQty');
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const labelAnd = useLabel('labels_order_and');
  const queryClient = useQueryClient();
  const router = useRouter();
  const { data: headerData } = useHeaderData();
  const locale = useLocale();
  const reloadCartPage = useFeatureFlag('RELOAD_CART_PAGE') === 'true';
  const enable24ProductView = useIs24ProductViewEnabled();
  const { refetch: refetchAvailabilityInfo } = useAvailabilityInfoData();

  const onAddToCartSuccess = () => {
    let skuIds: string[] = [];
    if (enable24ProductView) {
      const index =
        shelfData?.shelfParts.findIndex(
          (item) => item.skuNumber.toString() === data?.productInfo?.skuId
        ) ?? 0;
      // since there are two productSkuDetails queries we must pick the first or second half depending on its index
      shelfData?.shelfParts
        .slice(index >= 12 ? 12 : 0, index >= 12 ? undefined : 12)
        .forEach((part) => {
          skuIds.push(part.skuNumber.toString());
        });
    } else {
      shelfData?.shelfParts.forEach((part) => {
        skuIds.push(part.skuNumber.toString());
      });
    }

    isPDP && productDetails?.product?.itemId && skuIds.push(productDetails.product.itemId);
    props.isCart && skuIds.push(props.data.productInfo.skuId);

    if (skuIds && skuIds.length > 0) {
      const skuDetailsQueryOptions = {
        skuIds: skuIds,
        vehicleId: catalogVehicleId,
        storeNumber,
        isNewDealsApiEnabled,
      };
      const skuDetails = getProductSkuDetailsFromCache(queryClient, skuDetailsQueryOptions);
      const updatedSkuDetails = skuDetails?.map(
        (skuDetailItem: {
          skuPricingAndAvailability: { productId: string | undefined; skuId: string | undefined };
        }) => {
          if (skuDetailItem.skuPricingAndAvailability?.skuId === data?.productInfo?.skuId) {
            return {
              ...skuDetailItem,
              quantityInCart: count,
            };
          }
          return skuDetailItem;
        }
      );
      const skuDetailsKey = getProductSkuDetailsKey(skuDetailsQueryOptions);
      queryClient.setQueryData(skuDetailsKey, updatedSkuDetails);
    }
  };

  const onErrorCode = (errorCode: number | undefined, errorStatus: string | undefined) => {
    if (
      reloadCartPage &&
      (errorCode === 403 || (errorCode === 400 && (errorStatus === '7033' || '7025')))
    ) {
      router.reload();
    }
  };

  const { mutateAsync: patchOrderItem } = usePatchOrderItem({
    onAddToCartSuccess,
    onErrorCode,
  });
  const throttle = (fn: any, waitTime: number, immediate?: boolean) => {
    return (...args: Array<any>) => {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        if (!immediate) {
          fn.apply(this, [...args]);
        }
      }, waitTime);

      if (immediate && !timeout) {
        fn.apply(this, [...args]);
      }
    };
  };

  const [storeOrderMaxQty, onlineOrderMaxQty] = useMemo(() => {
    let storeOrderMaxQty = 0;
    let onlineOrderMaxQty = 0;
    props.fulfillmentOptions?.map((fulfillment) => {
      if (fulfillment.groupId === FULFILLMENT_METHODS.STOREORDER) {
        storeOrderMaxQty = fulfillment.maxAvailableQty;
      } else if (fulfillment.groupId === FULFILLMENT_METHODS.ONLINEORDER) {
        onlineOrderMaxQty = fulfillment.maxAvailableQty > 99 ? 99 : fulfillment.maxAvailableQty;
      }
    });
    return [storeOrderMaxQty, onlineOrderMaxQty];
  }, [props.fulfillmentOptions]);

  const selectedGroupAvailableQuantity = useCallback(
    (newFulfillmentId = props.data.selectedFulfillmentType) => {
      switch (true) {
        case newFulfillmentId === FULFILLMENT_METHODS.SAMEDAY:
          return props.skuAvailableQuantities?.sddQuantity;
        case newFulfillmentId === FULFILLMENT_METHODS.NEXTDAY:
          if (
            props.skuAvailableQuantities?.nddQuantity !== undefined &&
            props?.data?.itemQuantity > props.skuAvailableQuantities?.nddQuantity
          ) {
            return props.skuAvailableQuantities?.sthQuantity;
          } else {
            return props.skuAvailableQuantities?.nddQuantity;
          }
        case newFulfillmentId === FULFILLMENT_METHODS.ONLINEORDER:
          return props.skuAvailableQuantities?.sthQuantity;
        case newFulfillmentId === FULFILLMENT_METHODS.STOREORDER:
          return props.skuAvailableQuantities?.bopusQuantity;
      }
    },
    [props.skuAvailableQuantities, props.data?.itemQuantity, props.data?.selectedFulfillmentType]
  );

  useEffect(() => {
    props.updateTotalQuantity?.(count, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCount(props.data.itemQuantity);
  }, [props.data.itemQuantity]);

  useEffect(() => {
    const availableQtyforselectedFulfillment =
      selectedGroupAvailableQuantity() === undefined ? false : true;
    if (availableQtyforselectedFulfillment) {
      onLoadQuantityErrorCheckout();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOrderMaxQty, onlineOrderMaxQty, props.skuAvailableQuantities]);

  useEffect(() => {
    setQuantityErrorMsg(false);
  }, [props.fulFillmentId]);

  const onLoadQuantityErrorCheckout = useCallback(() => {
    const { isMini, data, updateQuantityErrorItem } = props;
    const { itemQuantity } = data;
    if (!isMini) {
      updateQuantityErrorItem?.(itemQuantity > selectedGroupAvailableQuantity()!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.skuAvailableQuantities, props.data]);

  const maxQuantityCheck = (newFulfillmentId?: number) => {
    const maxQuantityV2 = selectedGroupAvailableQuantity(newFulfillmentId)!;
    return Math.min(maxQuantityV2, 99);
  };

  const quantityErrorCheck = (checkQuantity: boolean, quantity: number) => {
    const { isMini, updateQuantityError, updateQuantityErrorItem, updatedValue } = props; // implement new sdd and ndd over quantity checks here

    if (!isMini && quantity > 0) {
      updateQuantityError?.(checkQuantity);
      updateQuantityErrorItem?.(checkQuantity, false);
    } else {
      count && updatedValue?.(count);
    }
  };

  const calculateItemOoHError = (quantity: number) => {
    const { data: lineItem, showQuantityError, fulfillmentOptions: fulfillmentGroups } = props;
    const noError: ItemQohErrorMessage = {
      errorMessage: '',
      optionsWithError: [],
    };
    const itemQohErrorMessage = createItemQoHErrorMessage(
      fulfillmentGroups!,
      quantity,
      labelAnd,
      labelQuantityNotAvailable
    );

    if (!!itemQohErrorMessage && itemQohErrorMessage.errorMessage.length > 0) {
      trackUpdateQuantityQoHError(fulfillmentGroups!, lineItem, quantity, itemQohErrorMessage);
      showQuantityError?.(itemQohErrorMessage);
    } else {
      showQuantityError?.(noError);
    }
  };

  const handleChange = (quantity: number) => {
    const showQuantityWarningMessage = true;
    const { handleQuantityChange, showQuantityError, fulFillmentId } = props;

    if (quantity === count) {
      return;
    }

    const nddQuantity = props.skuAvailableQuantities?.nddQuantity ?? 0;
    const sthQuantity = props.skuAvailableQuantities?.sthQuantity ?? 0;
    const isNddAvailable = nddQuantity > 0;
    let maxQuantity = null;
    let fulfillmentIdChanged = false;
    let newFulfillmentId;
    if (
      fulFillmentId === FULFILLMENT_METHODS.NEXTDAY &&
      nddQuantity < quantity &&
      sthQuantity > nddQuantity
    ) {
      fulfillmentIdChanged = true;
      newFulfillmentId = FULFILLMENT_METHODS.ONLINEORDER;
      maxQuantity = Math.min(maxQuantityCheck(FULFILLMENT_METHODS.ONLINEORDER), 99);
    } else if (
      quantity > 0 &&
      isNddAvailable &&
      fulFillmentId === FULFILLMENT_METHODS.ONLINEORDER &&
      nddQuantity >= quantity
    ) {
      fulfillmentIdChanged = true;
      newFulfillmentId = FULFILLMENT_METHODS.NEXTDAY;
      maxQuantity = Math.min(maxQuantityCheck(FULFILLMENT_METHODS.NEXTDAY), 99);
    } else {
      maxQuantity = Math.min(maxQuantityCheck(), 99);
    }

    const maxQuantityReached = quantity > maxQuantity;
    setIsIncOrDecBtnTouched(true);
    const adjustedQuantityForNegative = quantity < 0 ? count : quantity;
    const newQuantity = maxQuantityReached ? maxQuantity : adjustedQuantityForNegative;
    setQuantityForADA(newQuantity);
    !props.isMini && showQuantityError && calculateItemOoHError(newQuantity);
    if (maxQuantityReached) {
      handleQuantityChange?.(count, false, showQuantityWarningMessage);
      setQuantityErrorMsg(true);
      if (newQuantity !== count) {
        updateQuantity(newQuantity, fulfillmentIdChanged, newFulfillmentId);
      }
    } else {
      setQuantityErrorMsg(false);
      count && handleQuantityChange?.(count, false, showQuantityWarningMessage);
      quantityErrorCheck(false, quantity);
      updateQuantity(newQuantity, fulfillmentIdChanged, newFulfillmentId);
    }
  };

  const updateQuantity = (
    newQuantity: number,
    fulfillmentIdChanged = false,
    newFulfillmentId?: number
  ) => {
    const { updateTotalQuantity } = props;

    props.isMini && props.updatedValue?.(newQuantity);
    setCount(newQuantity > 0 ? newQuantity : 0);
    updateQuantityDataWrapper(
      newQuantity > 0 ? newQuantity : 0,
      fulfillmentIdChanged,
      newFulfillmentId
    );
    updateTotalQuantity?.(newQuantity > 0 ? newQuantity : 0);
  };

  const catalogVehicleId = headerData?.vehicleMap?.catalogVehicleId ?? '';
  const { data: storeDetailsData } = useStoreDetailsData();
  const isBopusEnabled = storeDetailsData?.bopusEnabled;
  const storeNumber = storeDetailsData?.storeNumber;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const isPDP = router.pathname === '/products/[...slug]';

  const { data: shelfData } = useProductList();
  const { data: productDetails } = useProductDetails({ enabled: isPDP });

  const updateQuantityDataWrapper = throttle(
    async (newQuantity: number, fulfillmentIdChanged: boolean, newFulfillmentId?: number) => {
      const { updateIsLoading, data, updateRequestingOrderItem } = props;
      if (!props.disableRemoveButton && newQuantity > 0) {
        updateRequestingOrderItem?.();
      }

      const fulfillmentId = fulfillmentIdChanged ? newFulfillmentId : props.fulFillmentId;

      if (newQuantity > 0) {
        try {
          await patchOrderItem({
            skuId: data.productInfo.skuId,
            productId: data.productInfo.productId,
            quantity: newQuantity.toString(),
            vehicleId: catalogVehicleId,
            storeNumber,
            fulfillmentTypeId: fulfillmentId,
            commerceItemId: data.lineItemId,
          });
          refetchAvailabilityInfo();
          /*
            Note: we should not refetch the header here since
            that would wipe out order details in redux.
          */
        } catch (e) {
          if (updateIsLoading) {
            updateIsLoading(false);
          }
        }
      }
    },
    1000
  );

  const renderMiniCartError = (disableAddToCartBtn: boolean) => {
    let returnValue = null;

    if (quantityErrorMsg) {
      returnValue = (
        <div
          role="alert"
          className={cx(azCommonStyles['az-caption'], styles.quantityAvailableError)}
          data-testid="max-qty-reached-error"
        >
          {cartMaxQtyReachedLabel}
        </div>
      );
    }

    props.handleQuantityChange?.(count, disableAddToCartBtn);
    props.isMini && props.updatedValue?.(count);
    return returnValue;
  };

  const renderEachItemPrice = () => {
    const { data, isMini, isCart, isDiscountedStrikeThroughPrice } = props;
    let amt;

    if (isDiscountedStrikeThroughPrice) {
      amt = data?.lineItemPriceInfo?.unitPrice;
    } else {
      amt = isMini ? data?.unitAmount ?? 0 : data?.lineItemPriceInfo?.listPrice ?? 0;
    }
    let returnValue = null;
    const cartWrapper = isCart ? styles.cartClass : '';

    const eachLabel = isBopusMexicoStoreEnabled ? (
      <Label label="label_cart_Each" />
    ) : (
      <Label label="label_cart_lineItem_Each" />
    );

    if (count > 1 && amt) {
      returnValue = (
        <div className={isCart ? (isBopusMexicoStoreEnabled ? styles.mxCart : cartWrapper) : ''}>
          <div
            className={cx(
              azCommonStyles['az-body-3-regular'],
              azCommonStyles['az-margin-top-4xs'],
              azCommonStyles['az-align-center'],
              styles.unitPrice
            )}
          >
            {eachLabel} ${formatPrice(parseFloat(amt))}
          </div>
        </div>
      );
    }

    return returnValue;
  };

  const { data, pageType, coreDeposit, isMini, removePart, isNewMessagingShown = false } = props;
  // @ts-expect-error validate and fix type error
  const isCorePriceAvailable = +coreDeposit !== 0;
  const unitAmount = isMini ? data?.unitAmount ?? 0 : data?.lineItemPriceInfo?.unitPrice ?? 0;
  const unitPrice = isMini ? data?.unitAmount ?? 0 : data?.lineItemPriceInfo?.unitPrice ?? 0;
  const updatedCoreDeposit = isMini
    ? // @ts-expect-error validate and fix type error
      +coreDeposit * count
    : // @ts-expect-error validate and fix type error
      +coreDeposit * quantityForADA;
  const ariaLabelPrice = isMini
    ? updatedCoreDeposit + unitPrice * count
    : updatedCoreDeposit + unitAmount * quantityForADA;
  const extraMargin = pageType === cartConstants.productDetails;
  let disableAddToCartBtn = false;

  //To not disable the button if the user reached max ndd qty if the product has sth qty available
  const nddQuantityCheck =
    Math.min(
      Math.max(
        maxQuantityCheck(FULFILLMENT_METHODS.NEXTDAY),
        maxQuantityCheck(FULFILLMENT_METHODS.ONLINEORDER)
      ),
      99
    ) < count;

  //To disable the button if the user selected ndd and reached sth available quantity
  const sthQuantityCheck = maxQuantityCheck(FULFILLMENT_METHODS.ONLINEORDER) < count;

  // To disable button for bopus when switching from ndd to bopus when count from ndd >= available bopus quantity
  const bopusQuantityCheck = maxQuantityCheck(FULFILLMENT_METHODS.STOREORDER) < count;

  let disableIncrementBtnV2;
  if (props.fulFillmentId === FULFILLMENT_METHODS.STOREORDER) {
    disableIncrementBtnV2 = bopusQuantityCheck;
  } else if (props.fulFillmentId === FULFILLMENT_METHODS.ONLINEORDER) {
    disableIncrementBtnV2 = sthQuantityCheck;
  } else if (props.fulFillmentId === FULFILLMENT_METHODS.NEXTDAY) {
    disableIncrementBtnV2 = nddQuantityCheck;
  } else {
    disableIncrementBtnV2 = maxQuantityCheck() < count;
  }

  const disableIncrementButton =
    quantityErrorMsg || maxQuantityCheck() === -1 || disableIncrementBtnV2;

  if (data.orderType === cartConstants.STOREORDER) {
    disableAddToCartBtn = count > storeOrderMaxQty;
  } else if (data.orderType === cartConstants.ONLINEORDER) {
    disableAddToCartBtn = count > onlineOrderMaxQty;
  }

  const selectedFulfillmentGroup = props.fulfillmentOptions?.find((option) => option.selected);
  const selectedFulfillmentOption = selectedFulfillmentGroup?.groupFulfillmentOptions.find(
    (option) => option.fulfillmentTypeId === props.fulFillmentId || option.selected
  );
  const isAvailable =
    selectedFulfillmentOption?.available ||
    (props.fulFillmentId === FULFILLMENT_METHODS.STOREORDER &&
      selectedFulfillmentOption?.sisterStoreAvailable);
  return (
    <div className={rootClass} data-testid="common-counter-v2">
      {!isNewMessagingShown && renderMiniCartError(disableAddToCartBtn)}
      <div className={azCommonStyles['az-align-center']}>
        <Quantity
          onInputChange={handleChange}
          onIncrementDecrement={handleChange}
          count={count}
          minQuantity={1}
          disableIncrementBtn={disableIncrementButton}
          hasError={!isNewMessagingShown && quantityErrorMsg}
          maxQuantity={maxQuantityCheck(props.fulFillmentId)}
          moreMargin={extraMargin}
          isIncOrDecBtnTouched={isIncOrDecBtnTouched}
          handleZeroInput={removePart}
          ariaLabelPrice={ariaLabelPrice}
          isCorePriceAvailable={isCorePriceAvailable}
          disabled={!isAvailable}
        />
        {renderEachItemPrice()}
      </div>
    </div>
  );
};

export default CommonCounterPartV2;
