/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type FilterSelectionType } from '@/types/filterTypes';
import { useCallback } from 'react';
import { useLabels } from './useLabels';
import { useGetSessionFiltersList } from './useGetSessionFiltersList';

const labelMap = { lblPriceCustom: 'label_Price_Custom' };

export const useGetFilterAnalytics = (): ((
  selectedFilter: FilterSelectionType,
  isChecked: boolean
) => string) => {
  const customPriceLabel = useLabels(labelMap);
  const filterSelectionList = useGetSessionFiltersList();

  return useCallback(
    (selectedFilter: FilterSelectionType, isChecked: boolean) => {
      const updatedFilterSelectionList = setSelectedFilterTypes(
        selectedFilter,
        isChecked,
        filterSelectionList,
        customPriceLabel.lblPriceCustom
      );

      const selectedFilters = setFilterAnalytics(updatedFilterSelectionList);
      const joinedSelectedFiltersObject = Object.values(selectedFilters).join(' | ');

      return joinedSelectedFiltersObject;
    },
    [customPriceLabel.lblPriceCustom, filterSelectionList]
  );
};

const setSelectedFilterTypes = (
  filter: FilterSelectionType,
  isChecked: boolean,
  selectedFilterTypes?: FilterSelectionType[],
  customPriceLabel?: string
): FilterSelectionType[] | undefined => {
  let filterSelectionList = selectedFilterTypes;

  const existingFilterSelection =
    filterSelectionList?.findIndex(
      (selectedFilter) =>
        selectedFilter.facet === filter.facet && selectedFilter.value === filter.value
    ) ?? -1;

  const existingCustomPriceFilter =
    filterSelectionList?.findIndex(
      (customPriceFilter) => customPriceFilter.facet === customPriceLabel
    ) ?? -1;

  const existingFilterIndex =
    filter.facet === customPriceLabel ? existingCustomPriceFilter : existingFilterSelection;

  if (existingFilterIndex !== -1) {
    if (isChecked && filterSelectionList) {
      filterSelectionList[existingFilterIndex] = filter.type
        ? filter
        : filterSelectionList[existingFilterIndex];
    } else {
      filterSelectionList?.splice(existingFilterIndex, 1);
    }
  } else if (isChecked && filter.facet && filter.value) {
    const filterWithType = { ...filter, type: filter.type ?? 'Standard' };
    filterSelectionList = selectedFilterTypes
      ? [...selectedFilterTypes, filterWithType]
      : [filterWithType];
  }

  return filterSelectionList;
};

const setFilterAnalytics = (filterSelectionList: FilterSelectionType[] = []) => {
  const selectedFiltersObject = <Record<string, string>>{};

  filterSelectionList?.forEach((selectedFilter) => {
    const filterObjectLabel = `${selectedFilter.type}: ${selectedFilter.facet}`;

    if (!selectedFiltersObject[filterObjectLabel]) {
      selectedFiltersObject[filterObjectLabel] = `${filterObjectLabel}: ${selectedFilter.value}`;
    } else {
      selectedFiltersObject[
        filterObjectLabel
      ] = `${selectedFiltersObject[filterObjectLabel]}, ${selectedFilter.value}`;
    }
  });

  return selectedFiltersObject;
};
