/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import { Drawer } from '@/components/Drawer/Drawer';
import { useLabels } from '@/hooks/useLabels';
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import { mapOrderItemsFromState } from '@/utils/itemHelpers/orderHelpers/mapOrderItemsFromState';
import { selectOrderDetailsFromState, selectOrdersDetailsOrderItems } from '@/constants/selectors';
import { resetDiscounts } from '@/actions/itemQuantity';
import type { Deal } from '../../interface';
import { useDealSkuDetails } from '../../api/getDealSkuDetails';
import { useDealList } from '../../api/getDealList';
import { routePaths } from '@/constants/routePaths';
import { useRouter } from 'next/router';
import { eventConstants, eventTypes } from '@/constants/event';
import type { ReduxState } from '@/types';
import type { OrderItemsFromState } from '@/types/reduxStore/orders';
import { BundleDealOptionList } from '../../lib/components/BundleDealOptionList';
import { BundleDeal } from '../../lib/components/BundleDeal';
import { BundleDealVehicleSelection } from '../../lib/components/VehicleSelection';
import { NavBar } from '@/components/NavBar';
import { FulfillmentSelection } from '../../lib/components/FulfillmentSelection';
import { TrapFocus } from '@/components/Dialog/Modal/TrapFocus';
import styles from '../../lib/components/styles.module.scss';
import { clickTrack } from '@/utils/analytics/clickTrack';
import type { CustomLinkName } from '@/types/analytics';
import { useFeatureFlag } from '@/features/globalConfig';
import { useHeaderData } from '@/features/header/api/getHeader';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { getErrorCode, getErrorCodeDescription } from '@/utils/errorsHandling';
import { MiniCartFlyoutComp } from '@/features/miniCartFlyout';
import { removeDataPart } from '@/actions/shelf/shelf';
import { DealDrawerConfirmation } from '../../lib/components/DealDrawerConfirmation';
import { trackInStockNearYouLinkClick } from '@/utils/analytics/track/trackInStockNearYouLinkClick';
import { usePageType } from '@/features/category/api/getPageType';
import { VehicleNotFit } from '../../lib/components/DealDrawerErrors/VehicleNotFit';

const labelMap = {
  label_deal_bundle_unexpected_error: 'label_deal_bundle_unexpected_error',
  label_deal_doesnt_fit_vehicle: 'label_deal_doesnt_fit_vehicle',
  label_deal_doesnt_fit_vehicle_cta: 'label_deal_doesnt_fit_vehicle_cta',
};

type Props = {
  open: boolean;
  skuId: string | undefined;
  skuPrice?: number;
  skuRetailPrice: string | number | string[] | number[];
  skuFulfillmentTypeId: number | string | null | undefined;
  skuQuantity: number | string;
  skuIsVehicleSpecific: boolean | undefined;
  closeDealsDrawer: () => void;
  handleDealsAddToCart?: () => void;
  dealsAddedToCart?: boolean;
  brand: string | undefined;
  partTypeId: string | undefined;
  productRepositoryId: string | undefined;
  analyticsPageType: string;
  onContinueShopping?: () => void;
  dealConfigurableLabel: string | null | undefined;
  fittingPartsURL: string | undefined;
};

const DealDrawerComponent = ({
  analyticsPageType,
  open,
  skuId,
  skuQuantity,
  skuPrice,
  skuRetailPrice,
  skuFulfillmentTypeId,
  closeDealsDrawer,
  skuIsVehicleSpecific,
  handleDealsAddToCart,
  dealsAddedToCart,
  brand,
  partTypeId,
  productRepositoryId,
  onContinueShopping,
  dealConfigurableLabel,
  fittingPartsURL,
}: Props) => {
  const dispatch = useDispatch();
  const labels = useLabels(labelMap);
  const router = useRouter();
  const { data: pageTypeData } = usePageType();
  const miniCartModalRef = useRef<HTMLDivElement>(null);
  const [groupId, setGroupId] = useState<string | null>(String(skuFulfillmentTypeId));
  const [fulfillmentId, setFulfillmentId] = useState<number | undefined>();
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [confirmSelectedDeal, setConfirmSelectedDeal] = useState(false);
  const [addedToCart, setAddedToCart] = useState<boolean | null>(dealsAddedToCart ?? false);
  const [needsVehicleSelection, setNeedsVehicleSelection] = useState(false);
  const [canAutoSelectFulfillment, setCanAutoSelectFulfillment] = useState(true);

  const ordersDetailsOrderItems = useSelector<ReduxState>(selectOrdersDetailsOrderItems);
  const orderDetailsFromState = useSelector(selectOrderDetailsFromState);
  const preferredVehicle = usePreferredVehicle();
  const preferredVehicleId = preferredVehicle?.catalogVehicleId;
  const dealDrawerFulfillmentOptionsEnabled =
    useFeatureFlag('DEAL_FULFILLMENT_OPTIONS_ENABLED') === 'true';
  const makeModelYearPageType = pageTypeData?.makeModelYearPath;
  const [miniCartDealData, setminiCartDealData] = useState<Set<string>>(new Set());
  const removeData = () => dispatch(removeDataPart());
  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const cartSuccessData = useSelector(({ shelf }: ReduxState) => shelf.cartSuccess);

  const isDealsDrawerMiniCartEnabled =
    useFeatureFlag('IS_DEALS_DRAWER_MINI_CART_ENABLED') === 'true';

  const submitToCart = () => {
    void router.push(routePaths.cart);
  };

  const mappedGrandOrder = mapOrderItemsFromState(ordersDetailsOrderItems as OrderItemsFromState);
  const orderLineItems = mappedGrandOrder.orderLineItems;

  useEffect(() => {
    const calculateVehicleSelectionNeed = () => {
      let vehicleSelectionIsNeed = false;
      const skuSpecific = skuIsVehicleSpecific || false;

      if (makeModelYearPageType && !preferredVehicleId) {
        vehicleSelectionIsNeed = true;
      } else {
        vehicleSelectionIsNeed = skuSpecific && !preferredVehicleId;
      }

      return vehicleSelectionIsNeed;
    };

    setNeedsVehicleSelection(calculateVehicleSelectionNeed());
  }, [makeModelYearPageType, preferredVehicleId, skuIsVehicleSpecific]);

  const queryEnabled = open && !needsVehicleSelection;
  const dealListResult = useDealList({
    enabled: queryEnabled,
    skuId,
  });

  const skuDetailsResult = useDealSkuDetails({
    enabled: queryEnabled,
    skuIds: skuId ? [skuId] : [],
  });
  const deals = dealListResult.data?.deals;
  const parentSkuDetails = skuId ? skuDetailsResult.data?.skus[skuId] : undefined;

  const error = dealListResult.error ?? skuDetailsResult.error;
  const errorCode = getErrorCode(error);
  const errorCodeDescription = getErrorCodeDescription(error);

  useEffect(() => {
    if (open) {
      return;
    }

    dealListResult.remove();
    skuDetailsResult.remove();
    setSelectedDeal(null);
    setConfirmSelectedDeal(false);
    setAddedToCart(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e) {
        if (e.keyCode === eventConstants.escKeyCode) {
          closeDealsDrawer();
        }
      }
    };

    document.addEventListener('keyup', handleKeyDown);
    return () => {
      document.removeEventListener('keyup', handleKeyDown);
    };
  }, [open, closeDealsDrawer]);

  useEffect(() => {
    setGroupId(String(skuFulfillmentTypeId));
  }, [skuFulfillmentTypeId]);

  useEffect(() => {
    if (errorCode && errorCodeDescription) {
      trackOnDealsError(errorCodeDescription, errorCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode, errorCodeDescription]);

  const currentPathIsCart = router.pathname === routePaths.cart;

  const handleClose = () => {
    setSelectedDeal(null);
    setAddedToCart(false);
    setConfirmSelectedDeal(false);
    setGroupId(null);
    dispatch(resetDiscounts());
    closeDealsDrawer();
    setCanAutoSelectFulfillment(true);
    if (addedToCart) {
      removeData();
      setminiCartDealData(new Set());
      if (onContinueShopping) {
        onContinueShopping();
      }
    }
  };
  const handleBack = () => {
    setConfirmSelectedDeal(false);
    setAddedToCart(false);
    setCanAutoSelectFulfillment(false);
  };

  const handleContinueShopping = () => {
    handleClose();
  };

  const handleInstockAnalytics = () => {
    if (dealDrawerFulfillmentOptionsEnabled) {
      trackOnDealInStockNearSelection(selectedDeal);
    }
  };

  const onDealSelection = (deal: Deal) => {
    if (!dealDrawerFulfillmentOptionsEnabled && !skuId) {
      setConfirmSelectedDeal(true);
      setGroupId(groupId);
    }

    setSelectedDeal(deal);
  };

  const onAddedToCart = () => {
    if (currentPathIsCart) {
      dispatch(resetDiscounts());
      closeDealsDrawer();
    } else {
      setAddedToCart(true);

      if (handleDealsAddToCart) {
        handleDealsAddToCart();
      }
    }
  };

  const onConfirmDealSelection = (groupTypeId: string | null) => {
    setGroupId(groupTypeId);
    setConfirmSelectedDeal(true);
  };

  const trackOnDealSelection = (dealInfo: { dealId: string; name: string | null }) => {
    const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
    const dealDetailsList: CustomLinkName = 'Deal Details List';
    const data = {
      eventType: 'dealsBuildBundle',
      dealId: dealInfo.dealId,
      dealName: dealInfo.name,
      pageName,
      pageType,
      siteSection,
      customLinkName: `${pageType}:${dealDetailsList}`,
    };
    clickTrack(data);
  };

  const trackOnDealInStockNearSelection = (dealInfo: Deal | null) => {
    const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
    let dealEventType = '';

    if (pageType === 'cart') {
      dealEventType = eventTypes.findAnotherStoreCart;
    }

    if (pageType === 'catalog_shelf') {
      dealEventType = eventTypes.findAnotherStoreShelf;
    }

    if (pageType === 'catalog_product-detail') {
      trackInStockNearYouLinkClick();
    }

    const inStockNearYou: CustomLinkName = 'In Stock Near You';
    const data = {
      eventType: dealEventType,
      dealId: dealInfo?.id,
      dealName: dealInfo?.name,
      pageName,
      pageType,
      siteSection,
      customLinkName: `${pageType}:${inStockNearYou}`,
    };
    clickTrack(data);
  };

  const trackOnDealsAvailableLink = (dealDrawerInfo: {
    dealIdListString: string;
    dealNameListString: string;
  }) => {
    const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
    const dealAvailableList: CustomLinkName = 'Deal Available List';
    const data = {
      eventType: 'dealsAvailableLink',
      dealId: dealDrawerInfo.dealIdListString,
      dealName: dealDrawerInfo.dealNameListString,
      pageName,
      pageType,
      siteSection,
      customLinkName: `${pageType}:${dealAvailableList}`,
      productIdList: [skuId],
      originalPartTypeIdList: [partTypeId],
      productRepositoryIdList: [productRepositoryId],
      productBrandList: [brand],
      productPriceList: [skuRetailPrice.toString().replace(',', '.')],
      productDealMessage: [dealConfigurableLabel ? dealConfigurableLabel : 'See Available Deals'],
    };
    clickTrack(data);
  };

  const handleDoesNotFitOnClick = () => {
    if (!__IS_SERVER__ && window.utag_data) {
      clickTrack({
        customLinkName: `${window.utag_data.pageType} - Choose Another Deal Item`,
        eventType: 'choose_another_deal_item',
        pageName: window.utag_data.pageName,
        pageType: window.utag_data.pageType,
        siteSection: window.utag_data.siteSection,
      });
    }

    closeDealsDrawer();
  };

  const trackOnDealsError = (err: string, errCode: string) => {
    const {
      preferredVehicleEngine = '',
      preferredVehicleId = '',
      preferredVehicleMake = '',
      preferredVehicleModel = '',
      preferredVehicleYear = '',
      preferredStoreId = '',
      pageType = '',
    } = root.utag_data || {};
    const dealDrawerError: CustomLinkName = 'Deal Drawer Error';
    const data = {
      customLinkName: `${pageType}:${dealDrawerError}`,
      eventType: eventTypes.dealDrawerError,
      errorMsg: err,
      errorCode: errCode,
      errorMsgType: dealDrawerError,
      productIdList: [skuId],
      preferredVehicleEngine,
      preferredVehicleId,
      preferredVehicleMake,
      preferredVehicleModel,
      preferredVehicleYear,
      preferredStoreId,
    };
    clickTrack(data);
  };

  let content;
  let ariaLabelledBy;

  if (dealListResult.isError || skuDetailsResult.isError) {
    content = (
      <>
        <NavBar showBackButton={false} showCloseButton handleClose={closeDealsDrawer} />
        {errorCode === '3076' && parentSkuDetails ? (
          <VehicleNotFit
            fittingPartsURL={fittingPartsURL ?? '/'}
            parentSkuCardProps={{
              skuDetails: parentSkuDetails,
              skuQuantity: skuQuantity,
              skuPrice: skuPrice,
              skuRetailPrice: skuRetailPrice,
            }}
            onVehicleDoesNotFitCtaOnClick={handleDoesNotFitOnClick}
          />
        ) : (
          <div className={styles.errorContainer}>
            <NotificationBlock
              type="error"
              message={errorCodeDescription || labels.label_deal_bundle_unexpected_error}
            />
          </div>
        )}
      </>
    );
  } else if (
    (dealListResult.isLoading && dealListResult.isFetching) ||
    (skuDetailsResult.isLoading && skuDetailsResult.isFetching)
  ) {
    content = (
      <div className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  } else if (needsVehicleSelection) {
    ariaLabelledBy = 'dealsModal';
    content = (
      <div className={styles.vehicleSelectionContainer}>
        <BundleDealVehicleSelection onClose={handleClose} />
      </div>
    );
  } else if (addedToCart) {
    if (!isDealsDrawerMiniCartEnabled) {
      content = (
        <DealDrawerConfirmation
          onClose={handleClose}
          onBackClick={handleBack}
          onContinueShopping={handleContinueShopping}
        />
      );
    } else {
      content = (
        <MiniCartFlyoutComp
          handleClose={handleClose}
          isModalOpen={addedToCart}
          image={parentSkuDetails?.productImageUrl ?? ''}
          successData={cartSuccessData}
          productDetails={{}}
          miniCartData={miniCartMap}
          closeStoreModal={handleClose}
          cartUrl={submitToCart}
          productTitle={selectedDeal?.name ?? ''}
          pageName="pdp"
          orderDetailsFromState={orderDetailsFromState}
          miniCartDealData={miniCartDealData}
          parentRef={miniCartModalRef}
        />
      );
    }
  } else {
    if (
      confirmSelectedDeal &&
      parentSkuDetails &&
      (groupId || skuFulfillmentTypeId) &&
      selectedDeal
    ) {
      content = (
        <BundleDeal
          analyticsPageType={analyticsPageType}
          deal={selectedDeal}
          parentSkuDetails={parentSkuDetails}
          skuFulfillmentTypeId={fulfillmentId ?? skuFulfillmentTypeId ?? groupId}
          skuIsVehicleSpecific={skuIsVehicleSpecific}
          onAddedToCart={onAddedToCart}
          onClose={handleClose}
          onBackClick={canAutoSelectFulfillment ? undefined : handleBack}
          cartItems={orderLineItems}
          trackOnDealSelection={trackOnDealSelection}
          handleErrorAnalytics={trackOnDealsError}
          dealItemSkuSet={miniCartDealData}
          setminiCartDealData={setminiCartDealData}
        />
      );
    } else if (
      selectedDeal &&
      parentSkuDetails &&
      (groupId || skuFulfillmentTypeId) &&
      dealDrawerFulfillmentOptionsEnabled
    ) {
      content = (
        <FulfillmentSelection
          dealId={selectedDeal.id}
          preferredVehicleId={preferredVehicleId}
          parentSkuDetails={parentSkuDetails}
          isDealDrawer
          skuFulfillmentTypeId={groupId ? groupId : skuFulfillmentTypeId}
          setFulfillmentId={setFulfillmentId}
          canAutoSelectFulfillment={canAutoSelectFulfillment}
          onConfirmDealSelection={onConfirmDealSelection}
          onClose={handleClose}
          handleInstockAnalytics={handleInstockAnalytics}
          handleErrorAnalytics={trackOnDealsError}
          dealName={selectedDeal.name ?? ''}
        />
      );
    } else {
      content = !!(deals && parentSkuDetails) && (
        <BundleDealOptionList
          parentSkuDetails={parentSkuDetails}
          skuQuantity={skuQuantity}
          skuPrice={skuPrice}
          skuRetailPrice={skuRetailPrice}
          deals={deals}
          trackOnDealsAvailableLink={trackOnDealsAvailableLink}
          onSelect={onDealSelection}
          onClose={handleClose}
          onBackClick={handleBack}
        />
      );
    }
  }

  return (
    <Drawer
      data-testid="deals-bundle-drawer"
      anchor={'right'}
      open={open}
      PaperProps={{
        className: styles.drawer,
      }}
      onClose={handleClose}
      drawerRef={miniCartModalRef}
    >
      <TrapFocus open={open}>
        <div
          role="dialog"
          aria-modal="true"
          aria-labelledby={ariaLabelledBy}
          className={styles.bundleDrawerBody}
        >
          {content}
        </div>
      </TrapFocus>
    </Drawer>
  );
};

export const DealDrawer = memo(DealDrawerComponent);
