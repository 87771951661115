/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type CitrusCarouselResponse } from '@/features/citrus';

export const getSkuIdsForSearchPage = (
  searchProductSkus: string[],
  citrusSearchPageData: CitrusCarouselResponse | undefined
) => {
  const citrusSponsoredSkus = citrusSearchPageData?.ads
    .slice(0, 3)
    .map((ad) => ad.gtin)
    .filter(Boolean);
  const skuIds = [...searchProductSkus];
  if (citrusSponsoredSkus?.length) {
    skuIds.unshift(...citrusSponsoredSkus);
  }

  // Remove duplicate SKU IDs
  return skuIds.filter((item, index, shelf) => shelf.indexOf(item) == index);
};
