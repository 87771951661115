/**
 * Copyright 2021-2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type CategoryData } from '@/features/category';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { type BrandData } from '@/features/brands';
import type {
  ShelfHead,
  ShelfLeftNav,
  ShelfPageBodyComplete,
  ShelfPageBodyItemGeneric,
  ShelfResultsBottomItemTwo,
} from '@/types/legacy';

type NormalizeShelfResponseParams = {
  data: CategoryData | BrandData | undefined;
  pathname: string;
};

type NormalizedShelfResponse = {
  catLabel?: string | undefined;
  productId?: null | string;
  brandName?: string;
  shelfLeftNav: ShelfLeftNav | undefined;
  shelfResultBottom:
    | [ShelfPageBodyItemGeneric, ShelfResultsBottomItemTwo]
    | Array<{ '@type': string }>;
  shelfResultHeader: ShelfPageBodyItemGeneric[];
  shelfPageBody: ShelfPageBodyComplete;
  shelfHead: ShelfHead[];
};

export const normalizeShelfResponse = ({
  data,
  pathname,
}: NormalizeShelfResponseParams): NormalizedShelfResponse => {
  const result: NormalizedShelfResponse = {
    catLabel: '',
    shelfLeftNav: undefined,
    shelfResultBottom: [],
    shelfResultHeader: [],
    shelfPageBody: [],
    shelfHead: [] as ShelfHead[],
  };

  if (!data) {
    return result;
  }

  if (isBrandShelfPage(pathname)) {
    if (isBrandData(data)) {
      const resultBottom:
        | [ShelfPageBodyItemGeneric, ShelfResultsBottomItemTwo]
        | Array<{ '@type': string }> = (data.contents.contents[0].brandShelfBottom ?? []) as Array<{
        '@type': string;
      }>;

      result.brandName = data.brandName;
      result.catLabel = data.brandName;
      result.shelfLeftNav = data.contents.contents[0].brandShelfLeftNav ?? [];
      result.shelfResultBottom = resultBottom;
      result.shelfResultHeader = data.contents.contents[0].brandShelfHeader ?? [];
      result.shelfPageBody = data.contents.contents[0].brandShelfPageBody ?? [];
      result.shelfHead = data.contents.contents[0].brandShelfHead ?? [];

      return result;
    }

    result.brandName = undefined;
    result.catLabel = undefined;

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (data !== null && isCategoryData(data)) {
    result.catLabel = '';
    result.productId = data.productId;
    result.shelfLeftNav =
      data.contents?.contents[0].shelfLeftNav ?? ([] as unknown as ShelfLeftNav);
    result.shelfResultBottom = data.contents?.contents[0].shelfResultBottom ?? [];
    result.shelfResultHeader = data.contents?.contents[0].shelfResultHeader ?? [];
    result.shelfPageBody = data.contents?.contents[0].shelfPageBody ?? [];
    result.shelfHead = data.contents?.contents[0].shelfHead ?? [];
  }

  return result;
};

const isCategoryData = (item: BrandData | CategoryData): item is CategoryData => {
  return Boolean(
    item &&
      typeof item === 'object' &&
      item.contents &&
      typeof item.contents === 'object' &&
      item.contents.contents &&
      Array.isArray(item.contents.contents) &&
      item.contents.contents[0] &&
      typeof item.contents.contents[0] === 'object' &&
      'shelfPageBody' in item.contents.contents[0]
  );
};

const isBrandData = (item: BrandData | CategoryData): item is BrandData => {
  return Boolean(
    item &&
      item.contents?.contents?.[0] &&
      ('brandShelfBottom' in item.contents.contents[0] ||
        'brandShelfHeader' in item.contents.contents[0] ||
        'brandShelfBody' in item.contents.contents[0] ||
        'brandShelfPageBody' in item.contents.contents[0] ||
        'brandShelfHead' in item.contents.contents[0])
  );
};
