/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useRef, useState, useEffect, useCallback, useContext, memo } from 'react';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import { RebateOnlyBadge } from '@/features/pdp/components/RebateOnlyBadge';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { SaveNowBadge } from '../SaveNowBadge';
import CorePrice from '@/components/AZCustomComponent/CorePrice';
import FitmentButton from '@/components/AZCustomComponent/FitmentButton';
import { type ReduxState } from '@/types';
import { FulfillmentOptions as FulfillmentOptionsMX } from '@/components/FulfillmentOptions';
import OldFulfillmentOptionsV2 from '@/components/OldFulfillmentOptionsV2';
import { ProductPrice } from '../ProductPrice';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { cartConstants } from '@/constants/cart';
import CartButton, { type CartButtonRef } from '@/components/AZCustomComponent/CartButton';
import actionTypes from '@/types/action';
import { convertOrderTypeToFulfillmentType } from '@/utils/itemHelpers/lineItemHelpers/convertOrderTypeToFulfillmentType';
import { getMaxAvailQtyFromOptions } from '@/utils/itemHelpers/fulfillmentHelpers/getMaxAvailQtyFromOptions';
import { DealsDrawerButton } from '@/features/deals/components/DrawerOpenButton/DrawerOpenButton';
import styles from './styles.module.scss';
import type { QOHErrorAnalytics, QohErrorAnalyticsData } from '@/types/analytics';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { useFeatureFlag } from '@/features/globalConfig';
import { interpolateLabel } from '@/features/i18n/utils/interpolateLabel';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { useProductList } from '../../../../../context/useProductList';
import type { DealInfo } from '@/types/availability';
import type { ShelfPart } from '@/types/legacy';
import type { SkuPricingAndAvailabilityFromAPI } from '@/types/availability';
import type { FulfillmentGroup } from '@/types/reduxStore/skuDetails';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { useRouter } from 'next/router';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { sessionStorage } from '@/utils/sessionStorage';
import { setFilterScrollBrand } from '@/actions/shelf/shelf';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { parseUrl } from '@/utils/urlHelpers';
import { trackNearbyStoreUpdate } from '@/utils/analytics/track/trackNearbyStoreUpdate';
import { SearchStoreModal } from '@/components/Store/StoreModal/SearchStoreModal';
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';
import { ProductDeals } from '../ProductDeals';
import { usePageType } from '@/hooks/usePageType';
import { pageTypes } from '@/constants/page';
import { NewFulfillmentSection } from '../../../../../components/NewFulfillmentSection';
import cx from 'classnames';
import { usePriceHiding } from '@/hooks/usePriceHiding';
import { setInteractionLocation } from '@/features/ymme/utils/ymmeLocationHelper';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const trackShelfQoHFailure = (qohErrorAnalyticsData: QohErrorAnalyticsData) => {
  const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
  const data: QOHErrorAnalytics = {
    eventType: 'addToCartQohError',
    pageName,
    pageType,
    siteSection,
    originalPartTypeIdList: [qohErrorAnalyticsData.originalPartTypeId ?? ''],
    productAvailableQuantityList: [qohErrorAnalyticsData.productAvailableQuantity.toString() ?? ''],
    productBrandList: qohErrorAnalyticsData.productBrandList,
    productCorePriceList: qohErrorAnalyticsData.productCorePriceList,
    productDiscountPriceList: [''],
    productFulfillmentTypeList: [
      convertOrderTypeToFulfillmentType(qohErrorAnalyticsData.orderType).toString() ?? '',
    ],
    productIdList: [qohErrorAnalyticsData.skuNumber ?? ''],
    productPartList: [''],
    productPartNumberList: [qohErrorAnalyticsData.partNumber ?? ''],
    productPriceList: [qohErrorAnalyticsData.productPrice.toString() ?? ''],
    productRepositoryIdList: qohErrorAnalyticsData.productRepositoryId.toString(),
    productRequestedQuantityList: [qohErrorAnalyticsData.requestQuantity.toString()],
    qohErrorMessage: [qohErrorAnalyticsData.errorMessage],
    qohErrorMsgType: ['Shelf QOH'],
  };
  clickTrack(data);
};

type Props = {
  cents: string;
  configurableSku: boolean;
  corePrice: number;
  dealInfo: DealInfo;
  dollars: string;
  fitsVehicle: boolean;
  itemPresentInCart: boolean | undefined;
  hasVehicle: boolean;
  productId: string | undefined;
  productName: string;
  productImage: string;
  shipToHomeAvailable: boolean | undefined;
  skuNumber: number;
  storePickupAvailable: boolean | undefined;
  storeSelected: boolean | undefined;
  skuPricingAndAvailability: SkuPricingAndAvailabilityFromAPI | undefined;
  toggleShowYmme: () => void;
  vehicleSpecific: boolean;
  url: string;
  prodIndex: number;
  fulfillmentOptions: FulfillmentGroup[] | undefined;
  price: string;
  productEdited: boolean;
  shippingType: string;
  count: number;
  trackQOHError: boolean;
  handleQuantityChange: (a: number) => void;
  updateCart: (count?: number) => Promise<void>;
  handleChangeShippingType: (a: string) => void;
  handleChangeShippingTypeV2: (
    c: typeof cartConstants.STOREORDER,
    b: typeof FULFILLMENT_METHODS.STOREORDER,
    a: number,
    sisterStoreAvailable?: boolean,
    f?: number
  ) => void;
  fulfillmentId?: number;
  setTrackQOHError: (a: boolean) => void;
  handleAddToCart: (count?: number) => Promise<void>;
  showDealDrawerButton: boolean;
  onDealClick: (ref: React.RefObject<HTMLButtonElement>) => void;
  clearance: boolean | undefined;
  groupId: number | null;
  part?: ShelfPart;
  productUpdatedToCart?: boolean;
  setProductUpdatedToCart?: (cartUpdated: boolean) => void;
  setFulfillmentId?: (fulfillmentId: number) => void;
  dealConfigurableLabel?: string | null;
  partsThatFitUrl: string;
  renderProductAttributeChips?: JSX.Element;
  productAttributeChipsEnabled?: boolean;
};

const labelMap = {
  lblGoToCart: 'label_CategoryPage_body_GoToCart',
  lblAdd: 'label_MyAccountVehicle_maintenanceInterval_Add',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblSetVehicleFit: 'hyperlink_YMME_Body_SetYourVehicle',
  lblBeginCustomization: 'label.configurableSku.customizeYourFloorMat',
  lblSelectStore: 'label_DetailsPage_body_SelectStore',
  labelSeeProductPrice: 'label_See_Product_Price',
  lblSetVehicleToSeePricing: 'label_set_vehicle_to_see_pricing',
  label_update_cart: 'label_update_cart',
  label_check_nearby_stores: 'label_check_nearby_stores',
  label_for: 'label_checkout_shipping_For',
  labelUpdateCartToProductItems: 'label_update_cart_to_product_items',
};

const ProductPricingAndAvailabilityComponent = ({
  cents,
  corePrice,
  dealInfo,
  configurableSku,
  dollars,
  fitsVehicle,
  itemPresentInCart,
  hasVehicle,
  productId,
  productImage,
  shipToHomeAvailable,
  skuPricingAndAvailability,
  storeSelected,
  skuNumber,
  storePickupAvailable,
  toggleShowYmme,
  vehicleSpecific,
  url,
  productName,
  prodIndex,
  fulfillmentOptions,
  price,
  productEdited,
  shippingType,
  count,
  trackQOHError,
  updateCart,
  handleChangeShippingType,
  handleChangeShippingTypeV2,
  setTrackQOHError,
  handleAddToCart,
  showDealDrawerButton,
  onDealClick,
  clearance,
  groupId,
  part,
  productUpdatedToCart,
  setProductUpdatedToCart,
  setFulfillmentId,
  dealConfigurableLabel,
  partsThatFitUrl = '',
  handleQuantityChange,
  fulfillmentId,
  renderProductAttributeChips,
  productAttributeChipsEnabled,
}: Props) => {
  const seeDealsButtonRef = useRef(null);
  const locale = useLocale();
  const labels = useLabels(labelMap);
  const router = useRouter();
  const loadingSkuNumber = useSelector<ReduxState>((state) => state.shelf.loadingSkuNumber);
  const readyStatus = useSelector<ReduxState>((state) => state.shelf.readyStatus);
  const errorMessage = useSelector<ReduxState>((state) => state.shelf.errorMessageDefault) as
    | string
    | undefined;
  const requestQuantity = useSelector<ReduxState>((state) => state.shelf.quantity) as
    | number
    | undefined;
  const { pageType } = usePageType();

  const isSearchPageType = pageType === pageTypes.search;
  const orderType = useSelector<ReduxState>((state) => state.shelf.orderType) as string | undefined;
  const updateCartEnabled = useFeatureFlag('UPDATE_CART_ENABLED') === 'true';
  const dealDrawerShelfEnabled = useFeatureFlag('DEAL_DRAWER_SHELF_ENABLED') === 'true';
  const strikeThroughPricingFlag = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const discountedPrice = skuPricingAndAvailability?.discountedPrice;
  const showDiscountedPrice = strikeThroughPricingFlag && !!discountedPrice;
  const { data: shelfData } = useProductList();
  const makeOrModelName = shelfData?.makeorModelName;
  const originalPartTypeId = shelfData?.shelfParts[prodIndex]?.originalPartTypeId;
  const productRepositoryId = shelfData?.shelfParts[prodIndex]?.productRepositoryId;
  const partNumber = shelfData?.shelfParts[prodIndex]?.partNumber;
  const recordType = shelfData?.shelfParts[prodIndex]?.recordType;
  const cartButtonRef = useRef<CartButtonRef>(null);
  const refIsPresentInCart = useRef<boolean>();
  const [dealsModalOpen, setDealsModal] = useState(false);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const isMxWeb = locale === countryCodes.mx;
  const isBrWeb = locale === countryCodes.ptBr;
  const dispatch = useDispatch();
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const shelfPart = shelfData?.shelfParts[prodIndex];
  const availabilityInfoVO = shelfPart?.availabilityInfoVO;
  const { data: storeDetailsData } = useStoreDetailsData();
  const { setPageSpecificData } = usePageSpecificData();
  const isBopusEnabled = storeDetailsData?.bopusEnabled;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;
  const orderDetailsLoadingSkuNumber = useSelector<ReduxState>(
    (state) => state.orders.details.loadingSkuNumber
  );

  const shouldShowDealsButton =
    showDealDrawerButton && dealDrawerShelfEnabled && !showDiscountedPrice;
  const filterScrollBrand = useSelector<ReduxState>((state) => state.shelf.filterScrollBrand);
  const checkoutNearbyStoresEnabled = useFeatureFlag('CHECKOUT_NEARBY_STORES_ENABLED') === 'true';
  const hidePricing = usePriceHiding();

  const productAvailableQuantity =
    (skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions &&
      getMaxAvailQtyFromOptions(
        skuPricingAndAvailability.skuAvailabilityInfo.fulfillmentOptions
      )) ??
    0;

  const handleDealsModal = () => {
    setDealsModal(true);
  };

  const handleCloseModal = () => {
    setDealsModal(false);
  };

  const toggleStoreModal = () => {
    setStoreModalOpen((previousValue) => !previousValue);
  };

  useEffect(() => {
    if (itemPresentInCart && refIsPresentInCart.current !== null) {
      cartButtonRef.current?.onSuccessfulAdd();
    }
    refIsPresentInCart.current = true;
  }, [itemPresentInCart]);

  useEffect(() => {
    if (productUpdatedToCart && refIsPresentInCart.current !== null) {
      cartButtonRef.current?.onSuccessfulAdd(true);
      setProductUpdatedToCart && setProductUpdatedToCart(false);
    }
    refIsPresentInCart.current = true;
  }, [productUpdatedToCart, setProductUpdatedToCart]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  useEffect(() => {
    if (
      sessionStorage.getItem('addOrUpdateCart') === 'true' &&
      (isLoadMoreEnabled
        ? shelfData?.shelfParts.find(
            (part) => part.skuNumber === Number(sessionStorage.getItem('skuId'))
          )
          ? sessionStorage.getItem('skuId') === skuPricingAndAvailability?.skuId
          : prodIndex === 0
        : sessionStorage.getItem('skuId') === skuPricingAndAvailability?.skuId)
    ) {
      const fullfillmentOption = fulfillmentOptions?.find(
        (option) => option.groupId == FULFILLMENT_METHODS.STOREORDER
      );
      const fullFillmaxQuantity = fullfillmentOption?.maxAvailableQty ?? 0;
      handleChangeShippingTypeV2(
        cartConstants.STOREORDER,
        FULFILLMENT_METHODS.STOREORDER,
        fullFillmaxQuantity,
        fullfillmentOption?.groupFulfillmentOptions[0].sisterStoreAvailable,
        FULFILLMENT_METHODS.STOREORDER
      );

      if (groupId === FULFILLMENT_METHODS.STOREORDER) {
        if (!itemPresentInCart) {
          void handleAddThenUpdateCart(Number(sessionStorage.getItem('quantity')) ?? count);
        } else {
          void updateCart(Number(sessionStorage.getItem('quantity')) ?? count);
        }
        sessionStorage.removeItem('addOrUpdateCart');
        sessionStorage.removeItem('skuId');
        sessionStorage.removeItem('quantity');
        sessionStorage.removeItem('productId');
        sessionStorage.removeItem('storeNumber');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, skuPricingAndAvailability?.skuId]);
  const handleAddThenUpdateCart = async (quantity: number) => {
    await handleAddToCart(quantity).then(async () => {
      await updateCart(quantity);
    });
  };
  const errorSku = useSelector<ReduxState>((state) => state.shelf.errorSku);
  const getQohErrorAnalyticsData = useCallback(() => {
    const qohErrorAnalyticsData = {
      originalPartTypeId: originalPartTypeId ?? '',
      productAvailableQuantity: productAvailableQuantity,
      productRepositoryId: [productRepositoryId ?? ''],
      productBrandList: [skuPricingAndAvailability?.brandName ?? ''],
      productCorePriceList: [`${corePrice}`],
      partNumber: partNumber ?? '',
      productPrice: skuPricingAndAvailability?.retailPrice ?? 0,
      orderType: orderType ?? '',
      errorMessage: errorMessage ?? '',
      requestQuantity: requestQuantity ?? '',
      skuNumber: `${skuNumber}`,
      productIdList: [part?.skuNumber.toString() ?? ''],
      productPartList: [part?.category ?? ''],
    };

    return qohErrorAnalyticsData;
  }, [
    corePrice,
    errorMessage,
    orderType,
    originalPartTypeId,
    partNumber,
    productAvailableQuantity,
    productRepositoryId,
    requestQuantity,
    skuNumber,
    skuPricingAndAvailability?.brandName,
    skuPricingAndAvailability?.retailPrice,
    part?.category,
    part?.skuNumber,
  ]);
  const failedAddToCart = useCallback(() => {
    const didItFail = readyStatus === actionTypes.ADD_TO_CART_SHELF_FAILURE;
    return didItFail;
  }, [readyStatus]);
  useEffect(() => {
    if (trackQOHError) {
      const failed = !!failedAddToCart;
      const thisSkuFailed = errorSku === Number(skuNumber).toFixed();

      if (failed && thisSkuFailed) {
        const analyticsData = getQohErrorAnalyticsData();
        // @ts-expect-error verify requestQuantity value can be set to 0 instead of ''
        trackShelfQoHFailure(analyticsData);
      }
    }

    return () => {
      setTrackQOHError(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackQOHError, getQohErrorAnalyticsData, failedAddToCart, errorSku, skuNumber]);

  useEffect(() => {
    if (
      isLoadMoreEnabled
        ? shelfData?.shelfParts.find(
            (part) => part.skuNumber === Number(sessionStorage.getItem('skuId'))
          )
          ? sessionStorage.getItem('skuId') === skuPricingAndAvailability?.skuId
          : prodIndex === 0
        : sessionStorage.getItem('skuId') === skuPricingAndAvailability?.skuId
    ) {
      const setStoreTrack = sessionStorage.getItem('sister_store_update_type');

      if (setStoreTrack === 'select_store_and_add_to_cart') {
        const qohErrorAnalyticsData = getQohErrorAnalyticsData();
        trackNearbyStoreUpdate(
          'In-Stock Near You: Set Store and Add to Cart',
          'In-Stock Near You: Set Store and Add to Cart',
          {
            eventType: 'In-Stock Near You: Set Store and Add to Cart',
            customLinkName: 'In-Stock Near You: Set Store and Add to Cart',
            storeId: storeDetailsData?.storeNumber,
            storeSearchTerm: storeDetailsData?.storeNumber,
            productRecommendationTypeList: part?.isSponsoredCard ? 'Sponsored Product' : 'AutoZone',
            productRank: Number(prodIndex) + 1,
            ...qohErrorAnalyticsData,
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleButtonClick = async (path: string) => {
    if (!configurableSku) {
      await handleAddToCart();
    } else if (hasVehicle && configurableSku) {
      const [pathname, query] = path.split('?');
      void router.push({
        pathname,
        query,
      });
    } else {
      toggleShowYmme();
    }
  };

  const ariaLabelAddProductToCart = `${labels.lblAdd} ${productName} ${labels.lblToCart} `;
  const addToCart = `${labels.lblAdd} ${labels.lblToCart}`;
  const ariaLabelCheckNearbyStores = `${labels.label_check_nearby_stores} ${labels.label_for} ${productName} `;
  const ariaLabelUpdateCartWithQuantityProduct = interpolateLabel(
    labels.labelUpdateCartToProductItems,
    {
      quantity: count,
      product: productName,
    }
  );

  const storeData = skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.find(
    (item: { groupTypeId: number; sisterStoreAvailable: boolean }) => {
      return item.groupTypeId === groupId;
    }
  );

  const homeDeliveryAvailable =
    shippingType === cartConstants.ONLINEORDER
      ? storeData?.available
      : skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.some(
          (option) =>
            (option.fulfillmentTypeId === FULFILLMENT_METHODS.ONLINEORDER ||
              option.fulfillmentTypeId === FULFILLMENT_METHODS.SAMEDAY ||
              option.fulfillmentTypeId === FULFILLMENT_METHODS.NEXTDAY) &&
            option.available
        );

  const calcButtonLabel = () => {
    if (configurableSku && hasVehicle) {
      return labels.lblBeginCustomization;
    }
    if (configurableSku && !hasVehicle) {
      return labels.lblSetVehicleToSeePricing;
    }
    if (
      !itemPresentInCart &&
      storeData?.sisterStoreAvailable &&
      !homeDeliveryAvailable &&
      shippingType === cartConstants.STOREORDER &&
      checkoutNearbyStoresEnabled
    ) {
      return labels.label_check_nearby_stores;
    }

    if (itemPresentInCart) {
      if (productEdited && (updateCartEnabled || isBopusMexicoStoreEnabled)) {
        return labels.label_update_cart;
      } else {
        return labels.lblGoToCart;
      }
    }

    if (isMxWeb && !storeSelected) {
      return labels.labelSeeProductPrice;
    }
    return addToCart;
  };
  const calcAriaLabel = () => {
    if (configurableSku && hasVehicle) {
      return labels.lblBeginCustomization;
    }
    if (configurableSku && !hasVehicle) {
      return labels.lblSetVehicleToSeePricing;
    }
    if (
      storeData?.sisterStoreAvailable &&
      !storeData.available &&
      shippingType === cartConstants.STOREORDER &&
      checkoutNearbyStoresEnabled
    ) {
      return ariaLabelCheckNearbyStores;
    }
    if (itemPresentInCart) {
      if (productEdited && (updateCartEnabled || isBopusMexicoStoreEnabled)) {
        return ariaLabelUpdateCartWithQuantityProduct;
      } else {
        return labels.lblGoToCart;
      }
    }

    if (isMxWeb && !storeSelected) {
      return labels.labelSeeProductPrice;
    }
    return ariaLabelAddProductToCart;
  };

  const calcButtonDisabled = () => {
    const storeData = skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.find(
      (item: { groupTypeId: number; sisterStoreAvailable: boolean }) => {
        return item.groupTypeId === groupId;
      }
    );
    if (
      checkoutNearbyStoresEnabled &&
      storeData?.sisterStoreAvailable &&
      !storeData?.available &&
      shippingType === 'STOREORDER'
    ) {
      return false;
    }
    return (
      (isMxWeb && storeSelected && (!storePickupAvailable || productAvailableQuantity <= 0)) ||
      (!isMxWeb && !shipToHomeAvailable && !storePickupAvailable && !configurableSku) ||
      (!isMxWeb && configurableSku && !shipToHomeAvailable)
    );
  };
  const { toggleSisterStoreFlyout } = useContext(SisterStoreContext);
  const calcButtonOnClick = () => {
    //prevent brand filter autoscrolling to be applied in the background when item added to cart
    if (isBrandShelfPage(router.asPath) && filterScrollBrand) {
      dispatch(setFilterScrollBrand(false));
    }
    if (
      !itemPresentInCart &&
      storeData?.sisterStoreAvailable &&
      !storeData.available &&
      shippingType === 'STOREORDER'
    ) {
      toggleSisterStoreFlyout({
        skuId: skuPricingAndAvailability?.skuId ?? '',
        shippingType: shippingType,
        productDetails: undefined,
        quantityInCart: part?.quantityInCart ?? 0,
      });
    } else {
      if (isMxWeb && !storeSelected) {
        return toggleStoreModal();
      } else if (productEdited && (updateCartEnabled || isBopusMexicoStoreEnabled)) {
        return updateCart();
      } else {
        return handleButtonClick(url);
      }
    }
  };

  const storeAndAddToCartObj = {
    disabled: calcButtonDisabled(),
    onClick: () => calcButtonOnClick(),
    loading:
      loadingSkuNumber === String(skuNumber) || orderDetailsLoadingSkuNumber === String(skuNumber),
  };

  let productPrice = null;
  let fulFillmentOption = null;
  const hasDeals = dealInfo.dealAvailable || dealInfo.rebatesAvailable;
  productPrice =
    isMobile || hidePricing ? null : (
      <div className={`${styles.productPriceSpacing} product-price-wrapper`}>
        <ProductPrice
          cents={cents}
          dollars={dollars}
          showPriceCaption={configurableSku && Number(price) > 0}
          isDiscountShown={dealInfo.dealAvailable}
          itemPresentInCart={itemPresentInCart}
          prodIndex={prodIndex}
          count={count}
          price={price}
          clearance={clearance}
          showDealDrawerButton={showDealDrawerButton}
          onDealClick={onDealClick}
          isMobile={isMobile}
          corePrice={corePrice}
          handleQuantityChange={handleQuantityChange}
          fulfillmentId={fulfillmentId}
          shippingType={shippingType}
        />
        {!isBopusMexicoStoreEnabled && <CorePrice corePrice={corePrice} />}
        {dealDrawerShelfEnabled && <RebateOnlyBadge badgeDetails={dealInfo} />}
        {shouldShowDealsButton && (
          <div className={styles.dealsButtonWrapper}>
            <DealsDrawerButton
              seeDealsButtonRef={seeDealsButtonRef}
              handleClick={onDealClick}
              dealConfigurableLabel={dealConfigurableLabel}
              customClass=""
            />
          </div>
        )}

        {hasDeals && !dealDrawerShelfEnabled && (
          <div className={styles.badgeContainer}>
            <SaveNowBadge badgeDetails={dealInfo} dealsCheck={handleDealsModal} />
            <ProductDeals
              dealsModalOpen={dealsModalOpen}
              skuNumber={skuNumber}
              productImage={productImage}
              productId={productId}
              productName={productName}
              handleCloseModal={handleCloseModal}
            />
          </div>
        )}
      </div>
    );
  fulFillmentOption =
    fulfillmentOptions && !isMxWeb && !isBrWeb ? (
      <div className="fulfillment-option-wrapper">
        {isBopusNewMsgApplicable ? (
          <NewFulfillmentSection
            fulfillmentOptionsGroupList={fulfillmentOptions}
            shippingType={shippingType}
            skuId={skuPricingAndAvailability?.skuId}
            isDealDrawer={false}
            quantityInCart={part?.quantityInCart ?? 0}
            setFulfillmentId={setFulfillmentId}
          />
        ) : (
          <OldFulfillmentOptionsV2
            fulfillmentOptionsGroupList={fulfillmentOptions}
            changeShippingType={handleChangeShippingTypeV2}
            shippingType={shippingType}
            productName={productName}
            skuId={skuPricingAndAvailability?.skuId}
          />
        )}
      </div>
    ) : (
      <div className="fulfillment-option-wrapper">
        <FulfillmentOptionsMX
          changeShippingType={handleChangeShippingType}
          shippingType={shippingType}
          availabilityInfoVO={availabilityInfoVO}
          skuPricingAndAvailability={skuPricingAndAvailability}
          skuId={skuPricingAndAvailability?.skuId}
          quantityInCart={part?.quantityInCart ?? 0}
          productName={productName}
          storeSelected={storeSelected}
          inline={false}
          dollars={dollars}
          cents={cents}
        />
      </div>
    );

  const handleFitmentButtonClick = () => {
    setPageSpecificData({
      productFindingMethod: 'Product Fitment Banner',
      pfmLocation: 'Product Fitment Banner',
      eventType: 'productFitmentBannerClick',
    });
  };

  const handleOpenYmmeFitment = () => {
    if (hasVehicle && fitsVehicle) {
      setInteractionLocation('fitmentThisFits');
    } else if (hasVehicle && !fitsVehicle) {
      setInteractionLocation('fitmentThisDoesNotFit');
    } else {
      setInteractionLocation('fitmentCheckFits');
    }
    toggleShowYmme();
  };

  return (
    <div>
      {productPrice}
      {isTablet || recordType !== 'app' ? null : (
        <div className={styles.fitSec}>
          <FitmentButton
            customClass={cx(styles.sponsoredTextMargin, styles.newFulfillmentFitmentMobile)}
            {...{
              fitsVehicle,
              hasVehicle,
              toggleShowYmme: handleOpenYmmeFitment,
              vehicleSpecific,
              productName,
              makeOrModelName,
            }}
            onClickSeeFittingParts={() => {
              handleFitmentButtonClick();
              if (isSearchPageType) {
                void router.push(partsThatFitUrl);
              } else {
                void router.push(parseUrl(router.asPath).url);
              }
            }}
          />
          {productAttributeChipsEnabled && renderProductAttributeChips}
        </div>
      )}
      <div>
        {fulFillmentOption}
        {locale !== countryCodes.ptBr ? (
          <div className={styles.addToCartButtonContainer}>
            <CartButton
              ref={cartButtonRef}
              data-testid={`cart-action-shelf-${prodIndex}`}
              ariaLabel={calcAriaLabel()}
              variant="contained"
              {...storeAndAddToCartObj}
              fullWidth
              className={styles.addToCartButton}
            >
              {calcButtonLabel()}
            </CartButton>
          </div>
        ) : null}

        {storeModalOpen && <SearchStoreModal closeStoreModal={toggleStoreModal} />}
      </div>
    </div>
  );
};

export const ProductPricingAndAvailability = memo(ProductPricingAndAvailabilityComponent);
