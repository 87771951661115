/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getGlobalConfigFromCache, getFeatureFlag } from '@/features/globalConfig';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { useLabels } from '@/hooks/useLabels';
import { prefetchProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { ProductListProvider } from '@/features/shelf/context/ProductListProvider';
import { ProductListViewProvider } from '@/features/shelf/context/ProductListViewProvider';
import { getXMPreviewState } from '@/stores/XMPreviewState';
import { handleLoadMoreRedirect, isEmptyObject } from '@/utils/common';
import { getDeviceType } from '@/utils/getDeviceType.server';
import { configureSharedPageData } from '@/utils/configureSharedPageData.server';
import { parseUrl } from '@/utils/urlHelpers';
import Head from 'next/head';
import { useEffect } from 'react';
import { dehydrate } from '@tanstack/react-query';
import { BrandShelf } from '../../components/BrandShelf';
import { routePaths } from '../../constants/routePaths';
import type { PageContextType } from '../../next-types';
import Router, { useRouter } from 'next/router';
import { interpolateLabel } from '../../utils/labels/interpolateLabel';
import { getPathnameFromAsPath } from '../../utils/urlHelpers';
import { mutateVehicles } from '@/features/ymme/api/postVehicles';
import logger from '@/utils/logger';
import { cookieConstant } from '@/constants/cookies';
import { type BrandOptions, type GetProductBrandDataOptions } from '@/features/brands';
import {
  useBrandProductData,
  prefetchProductBrand,
  getBrandProductDataFromCache,
  prefetchBrandInfinityData,
} from '@/features/brands/api/getProductBrandData';
import { useBrand, prefetchBrand, getBrandFromCache } from '@/features/brands/api/getBrand';
import { getMapFacet } from '@/hooks/useMapFacet';
import type { FacetModel } from '@/api/types/browse-search-types';
import { getClientSideQueryClient } from '../../lib/react-query';
import environmentConfig from '@/config/environment';
import { getCookies } from '@/utils/cookie';
import { SisterStoreProvider } from '@/features/fulfillment/context/SisterStoreProvider';
import { is24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import type { AppState } from '@/stores/interface';
import { countryCodes } from '@/constants/locale';
import { getKiboDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import {
  getPLPDataFromCache,
  prefetchContentStackProductListingPageData,
} from '@/features/productListingPage/api/getContentStackProductListingPageData';
import { type NextApiRequest, type NextApiResponse } from 'next';

export const config = {
  unstable_JsPreload: false,
};

const SEO_LABEL_MAP = {
  description: 'label_ShopByBrand_description',
  title: 'label_ShopByBrand_title',
};

type Props = {
  noindexDisabled: string | undefined | null;
};

function BrandShelfPage({ noindexDisabled }: Props) {
  const seoLabelMap = useLabels(SEO_LABEL_MAP);
  const brandResult = useBrand();
  const brandProductResult = useBrandProductData();
  const brandName = brandResult.isSuccess ? brandResult.data?.brandName || '' : '';
  const { asPath } = useRouter();
  const pathName = getPathnameFromAsPath(asPath);
  const redirectPath = brandProductResult.data?.redirectLocation
    ? brandProductResult.data?.redirectLocation
    : brandResult.data?.redirectPage
    ? brandResult.data?.redirectPage
    : brandResult.data?.location;

  useEffect(() => {
    if (!redirectPath) {
      return;
    }

    const parsedPath = parseUrl(redirectPath);

    let searchParams = {};
    let sanitizedPath = redirectPath;

    if (!isEmptyObject(parsedPath?.query)) {
      searchParams = parsedPath.query;
      sanitizedPath = parsedPath.url;
    }

    Router.replace({
      pathname: sanitizedPath,
      query: searchParams,
    });
  }, [redirectPath]);

  if (redirectPath) {
    return null;
  }

  return (
    <>
      <Head>
        <title key="title">
          {interpolateLabel(seoLabelMap.title, {
            brandName,
          })}
        </title>
        <meta
          key="metaDescription"
          name="description"
          content={interpolateLabel(seoLabelMap.description, {
            brandName,
          })}
        />
        <link key="linkCanonical" rel="canonical" href={`https://www.autozone.com${pathName}`} />
        {noindexDisabled !== 'false' && <meta name="robots" content="noindex, follow" />}
      </Head>
      <SisterStoreProvider>
        <BrandShelf />
      </SisterStoreProvider>
    </>
  );
}

BrandShelfPage.getInitialProps = async (context: PageContextType): Promise<{}> => {
  const { asPath, res, store, req, query } = context;

  if (__IS_SERVER__) {
    handleLoadMoreRedirect(req as NextApiRequest, res as NextApiResponse);
    const cookies = getCookies({ req, res });
    const { axios, queryClient, initialAppState } = await configureSharedPageData(context);
    const globalConfig = getGlobalConfigFromCache(queryClient);
    const brandsEnabled = globalConfig?.BRAND_PROFILE_FEATURE_ENABLED === 'true';
    const deviceType = getDeviceType(req);
    const enable24ProductViewFlagValue = globalConfig?.IS_24_PRODUCT_VIEW_ENABLED === 'true';
    const isCMSEnabled = getKiboDecisionFlag(queryClient, 'cmsBrandShelfPageEnabled');
    const isLoadMoreEnabled = getKiboDecisionFlag(queryClient, 'loadMoreEnabled');
    const enable24ProductView = is24ProductViewEnabled({
      query,
      deviceType,
      flagValue: enable24ProductViewFlagValue,
    });

    if (brandsEnabled) {
      const { workspaceId, previewDate } = getXMPreviewState(
        globalConfig?.workspaceId,
        globalConfig?.previewDate
      );
      const headerData = getHeaderDataFromCache(queryClient);

      if (query.vehicleId && query.vehicleId !== '0') {
        try {
          await mutateVehicles(
            queryClient,
            {
              vehicleId: `${''}${query.vehicleId}`,
              locale: initialAppState.locale,
            },
            axios
          );
        } catch (err) {
          logger.error({
            message: `Error when adding vehicle ${query.vehicleId} from query param in /brands/[brandName]`,
            meta: {
              url: asPath,
            },
          });
        }
      }

      let searchProductSkus: string[] = [];

      const sortArr =
        typeof context.query.sort === 'string'
          ? context.query.sort?.split('-')
          : context.query.sort ?? [];
      const sortOrder = sortArr[sortArr.length - 1];
      const sortFieldName = sortArr.slice(0, sortArr.length - 1).join('-');
      const productBrandDataOptions: GetProductBrandDataOptions = {
        vehicleId: query.vehicleId || getPreferredVehicle(headerData)?.catalogVehicleId,
        storeId: headerData?.storeNumber,
        locale: initialAppState.locale ?? 'en-US',
        seoUrl: asPath,
        facet: context.query.facet ? String(context.query.facet) : undefined,
        pageNumber:
          typeof context.query.currentPage === 'string'
            ? Number(context.query.currentPage)
            : typeof context.query.pageNumber === 'string'
            ? Number(context.query.pageNumber)
            : 1,
        minPrice: context.query.minPrice ? String(context.query.minPrice) : undefined,
        maxPrice: context.query.maxPrice ? String(context.query.maxPrice) : undefined,
        sort: sortFieldName && sortOrder ? `${sortFieldName}-${sortOrder}` : undefined,
        recordsPerPage:
          typeof context.query.recsPerPage === 'string'
            ? Number(context.query.recsPerPage)
            : enable24ProductView
            ? 24
            : undefined,
        mapFacet: (facet: FacetModel) => getMapFacet(facet, 'Clearance'),
        preview: environmentConfig.SHOW_XM_PREVIEW_DATE,
      };
      await prefetchProductBrand(queryClient, productBrandDataOptions, axios);

      if (isLoadMoreEnabled) {
        await prefetchBrandInfinityData(queryClient, productBrandDataOptions, axios);
      } else {
        await prefetchProductBrand(queryClient, productBrandDataOptions, axios);
      }
      const productBrandData = getBrandProductDataFromCache(queryClient, productBrandDataOptions);

      searchProductSkus =
        productBrandData?.records?.map((record) => record.skuNumber.toString()) ?? [];

      const brandDataOptions: BrandOptions = {
        path: asPath,
        workspaceId,
        previewDate,
        vehicleId: (query.vehicleId || getPreferredVehicle(headerData)?.catalogVehicleId) ?? '0',
        storeNumber: headerData?.storeNumber,
      };

      await prefetchBrand(queryClient, brandDataOptions, axios);

      const brandData = getBrandFromCache(queryClient, brandDataOptions);
      const userSegments = headerData?.segments?.sort() ?? [];
      const defaultBrandShelfQueryOptions: Parameters<
        typeof prefetchContentStackProductListingPageData
      >[0] = {
        queryClient,
        context,
        userSegments,
        productListingPageType: 'Brand Shelf (Default)',
        url: getPathnameFromAsPath(asPath),
      } as const;

      if (isCMSEnabled) {
        await prefetchContentStackProductListingPageData({ ...defaultBrandShelfQueryOptions });
      }

      const defaultBrandShelfData = getPLPDataFromCache({ ...defaultBrandShelfQueryOptions });
      const premiumBrands =
        defaultBrandShelfData?.additional_attributes_default_brand_shelf?.premium_brands?.map(
          (brand) => brand.toLowerCase()
        ) ?? [];

      const skuIds = searchProductSkus;

      const brandName = typeof query['brandName'] === 'string' ? query['brandName'] : undefined;

      await Promise.all([
        brandName &&
          premiumBrands.includes(brandName) &&
          prefetchContentStackProductListingPageData({
            queryClient,
            context,
            productListingPageType: 'Brand Shelf (Premium)',
            url: getPathnameFromAsPath(asPath),
            userSegments,
          }),
        skuIds.length > 0
          ? prefetchProductSkuDetails(axios, queryClient!, {
              skuIds: skuIds,
              storeNumber: headerData?.storeNumber,
              vehicleId: query.vehicleId
                ? query.vehicleId
                : getPreferredVehicle(headerData)?.catalogVehicleId ?? '0',
              isNewDealsApiEnabled:
                getFeatureFlag(globalConfig, 'IS_NEW_DEALS_API_ENABLED') === 'true',
            })
          : undefined,
      ]);

      if (brandData?.redirect) {
        const redirectPath = brandData.redirectPage ? brandData.redirectPage : brandData.location;

        if (redirectPath) {
          const parsedPath = parseUrl(redirectPath);
          let sanitizedPath = redirectPath;

          if (!isEmptyObject(parsedPath?.query)) {
            sanitizedPath = parsedPath.url;
          }

          const { appData } = store.getState();
          const isBot = appData.deviceType === 'bot';
          // Do not send full path with query params for bots
          // We explicitly append recsPerPage: 24 for bots when constructing the URL when making the query in prefetchBrand.
          // However, even though we are requesting 24 recsPerPage,
          // SEO does not want this query param to show up in the redirect url since bots are technically not be able to change recsPerPage.
          const path = isBot ? sanitizedPath : redirectPath;
          res?.writeHead(301, { Location: path }).end();
        }
      }

      let isBrandListView = false;
      const brandShelfListGridViewCookie = cookies[cookieConstant.names.brandShelfListGridView];
      if (brandShelfListGridViewCookie) {
        isBrandListView = brandShelfListGridViewCookie !== cookieConstant.values.shelfListGridView;
      }

      let isListView =
        globalConfig?.IS_DEFAULT_GRID_VIEW_ENABLED === 'true'
          ? false
          : initialAppState?.locale !== countryCodes.mx;
      const shelfListGridViewCookie = cookies[cookieConstant.names.shelfListGridView];
      if (shelfListGridViewCookie) {
        isListView = shelfListGridViewCookie !== cookieConstant.values.shelfListGridView;
      }

      return {
        initialAppState,
        dehydratedState: dehydrate(queryClient),
        noindexDisabled: getFeatureFlag(globalConfig, 'NOINDEX_BRAND_PAGES'),
        isBrandListView,
        isListView,
      };
    } else {
      res?.writeHead(302, { Location: routePaths.blankURL }).end();
    }

    return {
      noindexDisabled: globalConfig?.NOINDEX_BRAND_PAGES,
    };
  } else {
    const queryClient = getClientSideQueryClient();
    const globalConfig = getGlobalConfigFromCache(queryClient);
    const brandsEnabled = globalConfig?.BRAND_PROFILE_FEATURE_ENABLED === 'true';

    if (!brandsEnabled) {
      Router.replace(routePaths.blankURL);
    }

    return {
      noindexDisabled: globalConfig?.NOINDEX_BRAND_PAGES,
    };
  }
};

BrandShelfPage.getLayout = (
  page: React.ReactElement,
  pageProps: { isBrandListView: boolean; initialAppState: AppState; isListView: boolean } | {}
) => {
  let isListView = true;
  let isBrandListView = false;

  if (
    'initialAppState' in pageProps &&
    'IS_DEFAULT_GRID_VIEW_ENABLED' in pageProps.initialAppState
  ) {
    isListView = !pageProps.initialAppState.IS_DEFAULT_GRID_VIEW_ENABLED;
  }

  if ('isBrandListView' in pageProps) {
    isBrandListView = pageProps.isBrandListView;
  }
  if ('isListView' in pageProps) {
    isListView = pageProps.isListView;
  }

  if ('initialAppState' in pageProps && pageProps.initialAppState.locale === 'es-MX') {
    isListView = pageProps.initialAppState.locale !== 'es-MX';
  }

  if ('initialAppState' in pageProps && pageProps.initialAppState.locale === countryCodes.ptBr) {
    isListView = false;
  }

  return (
    <ProductListProvider>
      <ProductListViewProvider isBrandListView={isBrandListView} isListView={isListView}>
        {page}
      </ProductListViewProvider>
    </ProductListProvider>
  );
};

export default BrandShelfPage;
