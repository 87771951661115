/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import root from 'window-or-global';
import { createItemFulfillmentAvailabilityString } from '@/utils/itemHelpers/fulfillmentHelpers/createItemFulfillmentAvailabilityString';
import type { FulfillmentOptionType } from '@/actions/shelf/types';
import { badges } from '@/constants/analytics/badges';
import type {
  ShelfLeftNav,
  ShelfPageBodyComplete,
  ShelfPageBodyItemResultsList,
  ShelfPart,
  SortingOption,
} from '@/types/legacy';
import { type FilterDataType, type FilterSelectionType } from '@/types/filterTypes';
import { type IItem } from '@/components/AZCustomComponent/BreadcrumbSTARC/utils/isBreadcrumbModel';
import { type HeaderData } from '@/features/header';
import { type ParsedUrlQuery } from 'querystring';
import { type MappedBrandShelfResults } from '@/features/brands';
import { sessionStorage } from '../sessionStorage';
import { determineOptionsToDisplayButtons } from '@/features/fulfillment/api/optionsToDisplay';

export const displayViews = {
  list: 'List View',
  grid: 'Grid View',
  nullResults: '-Null Results',
  nullResultsCartridge: '-Null Results Cartridge',
} as const;

export const getShelfAnalytics = (currentProps: {
  parts: ShelfPart[] | undefined;
  shelfPageBody?: ShelfPageBodyComplete;
  brandPageBody?: MappedBrandShelfResults;
  shelfCatLabel?: string;
  productFilterData?:
    | {
        '@type': string;
        refinementList: Array<{ label: string }>;
      }
    | {
        mobile: boolean;
        tablet: boolean;
        desktop: boolean;
        '@type': string;
        contents: [
          {
            '@type': string;
            refinementList: Array<{ label: string }>;
          }
        ];
      }
    | null;
  selectedNullCartridge: string;
  getCustomPriceFilterLabel: (
    includeCustomPriceLabel?: boolean,
    minPrice?: string | null,
    maxPrice?: string | null
  ) => string;
  getFilterAnalytics: (selectedFilter: FilterSelectionType, isChecked: boolean) => string;
  query: ParsedUrlQuery;
  headerData: HeaderData | undefined;
  sessionFiltersList?: FilterSelectionType[];
  makeOrModelName?: string | undefined | null;
  defaultEngine: { value: string; label: string } | string | null | undefined;
  shelfBreadcrumb?: IItem[] | undefined;
  staticNavigation?: FilterDataType[] | undefined;
  shelfLeftNav?: ShelfLeftNav | undefined;
  isList?: boolean;
  dealBadgeEnabled: boolean;
  enableVdpDsUniqueDisplay: boolean;
}): {
  badgesInfo: string[];
  bopusList: boolean[];
  categoryLevel1: string;
  categoryLevel2: string;
  categoryLevel3: string;
  dealFlagList: boolean[];
  dealBundleCountList: string[];
  displayViewType: string;
  fulfillmentAvailabilityList: string[];
  nddAfterCutOffFlag: boolean[];
  nddEligibleList: boolean[];
  nddEligibleProductsCount: number;
  originalPartTypeIdList: string[];
  pageDepth: string;
  productBrandList: string[];
  productCorePriceList: string[];
  productFilterSelection: string | undefined;
  productIdList: string[];
  productNotAvailableList: boolean[];
  productPart: string;
  productPrice: string[];
  productRepositoryIdList: string[];
  searchResultCount: string;
  resultsPerPage: string;
  sthList: boolean[];
  sthSpecialOrderList: boolean[];
  clearanceList: string[];
  productRecommendationTypeList: string[];
  sisterStoreAvailableList: string[];
  productPartNumberList: string[];
  productFitmentCheckMessage: string[];
  productDealMessage: string[];
  productFulfillmentOptionSeenByCustomer: string[];
} => {
  const {
    parts,
    shelfPageBody,
    brandPageBody,
    shelfCatLabel,
    productFilterData,
    selectedNullCartridge,
    getCustomPriceFilterLabel,
    getFilterAnalytics,
    sessionFiltersList,
    query,
    headerData,
    makeOrModelName,
    defaultEngine,
  } = currentProps;
  const categoryLevel1 = currentProps.shelfBreadcrumb?.[0]?.displayName ?? '';
  const categoryLevel2 = currentProps.shelfBreadcrumb?.[1]?.displayName ?? '';
  const categoryLevel3 = currentProps.shelfBreadcrumb?.[2]?.displayName ?? '';
  const staticNavigations =
    currentProps.shelfLeftNav?.[0]?.staticNavigation ?? currentProps.staticNavigation ?? [];
  const dynamicNavigations =
    currentProps.shelfLeftNav?.[0]?.dynamicNavigation?.attributeNarrowingList ?? [];
  const hasVehicle = headerData?.vehicleMap.catalogVehicleId ?? false;
  const setFiltersInSessionStorage = (filterAnalytics: string) => {
    sessionStorage.setItem('filterSelectionAnalytics', filterAnalytics);
    if (!filterAnalytics.length) {
      sessionStorage.removeItem('filterSelectionAnalytics');
    }
  };

  const staticAndDynamicNavigations = [...staticNavigations, ...dynamicNavigations];

  sessionFiltersList?.forEach((sessionFilter) => {
    let sessionFilterIsStillSelected = false;
    staticAndDynamicNavigations.forEach((facet) => {
      const foundFilter = facet.refinements.find(
        (refinement) =>
          refinement.label === sessionFilter.value &&
          facet.displayName === sessionFilter.facet &&
          refinement.properties.isSelected
      );
      sessionFilterIsStillSelected = sessionFilterIsStillSelected ? true : !!foundFilter;
    });

    if (!sessionFilterIsStillSelected) {
      const filterAnalytics = getFilterAnalytics(sessionFilter, sessionFilterIsStillSelected);
      setFiltersInSessionStorage(filterAnalytics);
    }
  });

  staticAndDynamicNavigations.forEach((facet) => {
    facet.refinements.forEach((refinement) => {
      const filterWithType: FilterSelectionType = {
        facet: facet.displayName,
        value: refinement.label,
      };

      const filterAnalytics = getFilterAnalytics(
        filterWithType,
        !!refinement.properties.isSelected
      );
      setFiltersInSessionStorage(filterAnalytics);
    });
  });

  if (
    (query.minPrice || query.maxPrice) &&
    !Array.isArray(query.minPrice) &&
    !Array.isArray(query.maxPrice)
  ) {
    const customPriceFilter = getCustomPriceFilterLabel(true, query.minPrice, query.maxPrice);
    const filterDataArray = customPriceFilter.split(': ');
    const filterObject: FilterSelectionType = {
      type: filterDataArray[0],
      facet: filterDataArray[1],
      value: filterDataArray[2],
    };

    const analyticsWithCustomPrice = getFilterAnalytics(filterObject, true);
    setFiltersInSessionStorage(analyticsWithCustomPrice);
  } else {
    const customPriceFilter = getCustomPriceFilterLabel(true);
    const filterDataArray = customPriceFilter.split(': ');
    const filterObject: FilterSelectionType = {
      type: filterDataArray[0],
      facet: filterDataArray[1],
      value: filterDataArray[2],
    };

    const analyticsWithCustomPrice = getFilterAnalytics(filterObject, false);
    setFiltersInSessionStorage(analyticsWithCustomPrice);
  }

  const productFilterSelection = sessionStorage.getItem('filterSelectionAnalytics') ?? undefined;
  const productIdList: string[] = [];
  const productPartNumberList: string[] = [];
  const productBrandList: string[] = [];
  const bopusList: boolean[] = [];
  const sthList: boolean[] = [];
  const nddEligibleList: boolean[] = [];
  const dealFlagList: boolean[] = [];
  const dealBundleCountList: string[] = [];
  const productPrice: string[] = [];
  const productCorePriceList: string[] = [];
  const nddAfterCutOffFlag: boolean[] = [];
  const badgesInfo: string[] = [];
  const sthSpecialOrderList: boolean[] = [];
  const originalPartTypeIdList: string[] = [];
  const productRepositoryIdList: string[] = [];
  const productFitmentCheckMessage: string[] = [];
  const fulfillmentAvailabilityList: string[] = [];
  let nddEligibleProductsCountV2 = 0;
  const clearanceList: string[] = [];
  const productRecommendationTypeList: string[] = [];
  const productPart = shelfCatLabel || '';
  const sisterStoreAvailableList: string[] = [];
  const productDealMessage: string[] = [];
  const productFulfillmentOptionSeenByCustomer: string[] = [];

  parts &&
    parts.length > 0 &&
    parts.map((part) => {
      if (!!part) {
        originalPartTypeIdList.push(`${part.originalPartTypeId}`);
        const dealsAvailable =
          (part.skuPricingAndAvailability?.dealsAvailableQty ?? 0) > 0 ? true : false;

        if (dealsAvailable) {
          const discountDisplay = getDiscountDisplay(part);

          productDealMessage.push(discountDisplay);
        } else {
          productDealMessage.push('');
        }

        productRepositoryIdList.push(part.skuPricingAndAvailability?.productId ?? '');
        productIdList.push(part.skuNumber.toString() ?? '');
        productPartNumberList.push(part.partNumber ?? '');
        productBrandList.push(part.brand ?? '');
        bopusList.push(part.skuPricingAndAvailability?.storePickupAvailable ?? false);
        const { fulfillmentOptions = [] } =
          part.skuPricingAndAvailability?.skuAvailabilityInfo || {};
        const sisterStoreAvailable = fulfillmentOptions.filter((option: FulfillmentOptionType) => {
          return option.sisterStoreAvailable;
        });

        if (fulfillmentOptions) {
          fulfillmentAvailabilityList.push(
            // @ts-expect-error fix type
            createItemFulfillmentAvailabilityString(fulfillmentOptions)
          );
          nddEligibleProductsCountV2 += fulfillmentOptions.reduce((nddCount, option) => {
            if (option.fulfillmentTypeId === 107) {
              nddCount++;
            }
            return nddCount;
          }, 0);
        }

        const [bopus, sth, ndd] = determineOptionsToDisplayButtons(part.fulfillmentOptions);
        const optionsShown = [bopus, sth, ndd]
          .filter((option) => option?.available)
          .map((option) => String(option?.fulfillmentTypeIdOriginal))
          .join('|');
        productFulfillmentOptionSeenByCustomer.push(optionsShown);
        sthList.push(part.skuPricingAndAvailability?.shipToHomeAvailable ?? false);
        nddEligibleList.push(part.aZBadgesFlagVO?.eligibleForNextDay ?? false);
        dealFlagList.push(part.skuPricingAndAvailability?.dealAvailable ?? false);
        dealBundleCountList.push(
          part.skuPricingAndAvailability?.dealsAvailableQty &&
            part.skuPricingAndAvailability.dealsAvailableQty !== 0
            ? part.skuPricingAndAvailability.dealsAvailableQty.toString()
            : ''
        );
        productPrice.push(part.skuPricingAndAvailability?.retailPrice.toString() ?? '');
        productCorePriceList.push(part.skuPricingAndAvailability?.corePrice.toString() ?? '');
        nddAfterCutOffFlag.push(
          part.skuPricingAndAvailability?.skuAvailabilityInfo.nddAfterCutOffFlag ?? false
        );
        part.skuPricingAndAvailability?.skuAvailabilityInfo.clearanceItem
          ? clearanceList.push('1')
          : clearanceList.push('0');
        const specialOrder = part.skuPricingAndAvailability?.specialOrder ?? false;
        const shipToHomeAvailable = part.skuPricingAndAvailability?.shipToHomeAvailable ?? false;
        const dealAvailable = part.aZBadgesFlagVO?.dealAvailable ?? false;
        const eligibleForNextDay = part.aZBadgesFlagVO?.eligibleForNextDay ?? false;
        sthSpecialOrderList.push(specialOrder && shipToHomeAvailable);

        if (dealAvailable && eligibleForNextDay) {
          badgesInfo.push(`${badges.deal} | ${badges.nextDayDelivery}`);
        } else if (dealAvailable) {
          badgesInfo.push(badges.deal);
        } else if (eligibleForNextDay) {
          badgesInfo.push(badges.nextDayDelivery);
        } else {
          badgesInfo.push('');
        }
        part.isSponsoredCard
          ? productRecommendationTypeList.push('Sponsored Product')
          : productRecommendationTypeList.push('AZ Product');
        makeOrModelName && !defaultEngine
          ? productFitmentCheckMessage.push(`Check if this fits your ${makeOrModelName}`)
          : !part.vehicleFit && !part.vehicleSpecific
          ? productFitmentCheckMessage.push('')
          : !hasVehicle
          ? productFitmentCheckMessage.push('check if this fits your vehicle')
          : part.vehicleFit
          ? productFitmentCheckMessage.push('fits your vehicle')
          : productFitmentCheckMessage.push("doesn't fit your vehicle");
        if (sisterStoreAvailable.length) {
          sisterStoreAvailableList.push('1');
        } else {
          sisterStoreAvailableList.push('');
        }
      }
    });
  const productNotAvailableList = bopusList.map<boolean>((list, index) => {
    return !list && !sthList[index];
  });
  const shelfPageBodyData = shelfPageBody?.find(
    (item) => item['@type'] === 'ShelfPageBody_ResultsList'
  ) as ShelfPageBodyItemResultsList | undefined;

  const resultsPerPage =
    (shelfPageBodyData?.recsPerPage?.toString() || brandPageBody?.recordsPerPage.toString()) ?? '';
  const searchResultCount =
    (shelfPageBodyData?.totalNumRecs?.toString() || brandPageBody?.total?.toString()) ?? '';
  const firstRecNum = shelfPageBodyData?.firstRecNum ?? '';
  const lastRecNum = shelfPageBodyData?.lastRecNum ?? '';
  const displayView = currentProps.isList ?? true;
  const displayViewType = displayView ? displayViews.list : displayViews.grid;
  const nullResultsDisplayType = getNullResultsDisplayViewType(parts, productFilterData);
  const nullCartridgeData = selectedNullCartridge
    ? {
        nullCartridgeSelected: selectedNullCartridge,
      }
    : {};
  const pageDepth = `${firstRecNum} to ${lastRecNum} of ${searchResultCount}`;
  delete root.utag_data?.rewardPoints;
  // TODO: probably not needed, but due to type inconsistency in the data layer, we need to check for the existence of the property
  const unifiedProductFilterData = !productFilterData
    ? null
    : 'refinementList' in productFilterData
    ? productFilterData
    : 'contents' in productFilterData
    ? productFilterData.contents[0]
    : null;

  return {
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
    productFilterSelection,
    productPart,
    productIdList,
    productPartNumberList,
    productBrandList,
    nddEligibleProductsCount: nddEligibleProductsCountV2,
    dealFlagList,
    dealBundleCountList,
    productPrice,
    productCorePriceList,
    resultsPerPage,
    searchResultCount,
    displayViewType: `${displayViewType}${nullResultsDisplayType}`,
    pageDepth,
    badgesInfo,
    sthSpecialOrderList,
    originalPartTypeIdList,
    productRepositoryIdList,
    fulfillmentAvailabilityList,
    bopusList,
    sthList,
    nddEligibleList,
    productNotAvailableList,
    nddAfterCutOffFlag,
    productRecommendationTypeList,
    productFitmentCheckMessage,
    sisterStoreAvailableList,
    productDealMessage,
    ...nullCartridgeData,
    ...getProductFilterAnalytics(unifiedProductFilterData),
    clearanceList,
    productFulfillmentOptionSeenByCustomer,
  };
};

export const getProductFilterAnalytics = (
  productFilterData?:
    | {
        refinementList: Array<{ label: string }>;
      }
    | {
        '@type': string;
        refinementList: Array<{
          label: string;
        }>;
      }
    | null
) => {
  const productFilterRefinements = productFilterData?.refinementList;

  if (Array.isArray(productFilterRefinements) && productFilterRefinements.length) {
    const nullCartridgeRecommendations = productFilterRefinements.reduce(
      (result: string[], currentValue) => {
        result.push(`${currentValue.label}`);
        return result;
      },
      []
    );
    return {
      nullCartridgeRecommendations: nullCartridgeRecommendations.join('|'),
    };
  }

  return {};
};

export const getNullResultsDisplayViewType = (
  parts: ShelfPart[] | undefined,
  productFilterData?: {
    '@type'?: string;
    refinementList?: Array<{ label: string }>;
  } | null
): string => {
  const productFilterDataExists = productFilterData?.refinementList?.length ?? 0 > 0;
  const zeroResults = !parts?.length;

  if (zeroResults && !productFilterDataExists) {
    return displayViews.nullResults;
  } else if (zeroResults && productFilterDataExists) {
    return displayViews.nullResultsCartridge;
  }

  return '';
};

export const getSelectedSortingOptionLabel = (
  queryParameter?: string | null,
  sortingOptions?: SortingOption[] | null,
  defaultValue?: string | null
): string => {
  const defaultLabel = defaultValue || '';

  if (Array.isArray(sortingOptions) && sortingOptions.length) {
    const selectedSortingOption = sortingOptions.find(
      (option) => option.parameter === queryParameter
    );
    return selectedSortingOption ? selectedSortingOption.name : defaultLabel;
  }

  return defaultLabel;
};

export const getDiscountDisplay = (part: ShelfPart) => {
  const dealAvailable = part.skuPricingAndAvailability?.dealAvailable ?? false;
  const retailPrice = parseFloat(String(part.skuPricingAndAvailability?.wasRetailPrice ?? 0));
  const discountedPrice = parseFloat(String(part.skuPricingAndAvailability?.retailPrice ?? 0));
  const savingsPercent = Math.abs(Math.ceil(100 - (discountedPrice / retailPrice) * 100));
  const discountAmount = Math.abs(
    parseFloat(String(retailPrice)) - parseFloat(String(discountedPrice))
  );
  const dealLeadingMessage =
    part.skuPricingAndAvailability?.dealConfigurableLabel || 'See Available Deals';
  if (dealAvailable && retailPrice > discountedPrice) {
    return `${dealLeadingMessage} - ${
      discountAmount > savingsPercent ? `$${discountAmount}` : `${savingsPercent}%`
    }`;
  } else if (dealAvailable) {
    return part.skuPricingAndAvailability?.dealConfigurableLabel || 'See Available Deals';
  } else if (retailPrice > discountedPrice) {
    return discountAmount > savingsPercent ? `$${discountAmount}` : `${savingsPercent}%`;
  }
  return '';
};
