/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

/**
***********************************************************************************
This component is a clone of the STARC Breadcrumb. All references to this file
should be replaced with the STARC Breadcrumb once STARC is successfully integrated.
***********************************************************************************
*/

import type { ComponentType } from 'react';
import cx from 'classnames';
import styles from '../styles.module.scss';
import BreadcrumbItem from './BreadcrumbItem';
import { type BreadcrumbModel } from '../../../../../api/types/browse-search-types';
import { isBreadcrumbModel, type IItem } from '../utils/isBreadcrumbModel';

type IProps = {
  data: Array<IItem | BreadcrumbModel>;
  showSEOData?: boolean;
  onClick?: (e: Event, item: IItem) => void;
  linkComponent: ComponentType<any>;
  headComponent: ComponentType<any>;
  baseSchemaURL: string;
  itemClassName?: string;
  className?: string;
  lastItemDisabled?: boolean;
};

function BreadcrumbSTARC(props: IProps) {
  const {
    className,
    onClick,
    linkComponent,
    headComponent: Head,
    baseSchemaURL,
    showSEOData = true,
    data = [],
    lastItemDisabled,
  } = props;
  const UCID = 'st-breadcrumbs';

  const getBreadcrumbItemsJSON = (breadcrumbsList: IItem[], baseLink: string) => {
    return breadcrumbsList.map((breadcrumb, index) => {
      const breadcrumbUrl = breadcrumb.url
        ? typeof breadcrumb.url === 'string'
          ? breadcrumb.url
          : breadcrumb.url.pathname
        : '';
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: breadcrumb.label || breadcrumb.displayName,
        item: `${baseLink}${breadcrumbUrl}`,
      };
    });
  };

  if (data && data.length > 0) {
    return (
      <>
        {showSEOData ? (
          <Head>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                  '@context': 'https://schema.org/',
                  '@type': 'BreadcrumbList',
                  itemListElement: getBreadcrumbItemsJSON(data, baseSchemaURL),
                }),
              }}
            />
          </Head>
        ) : null}
        <nav
          role="navigation"
          aria-label="Breadcrumb"
          className={cx(UCID, styles.breadcrumb, className)}
        >
          {data.map((item, i) => {
            const isLastItem = i === data.length - 1;
            return (
              <BreadcrumbItem
                item={item}
                isLastItem={isLastItem}
                key={`${i}-${isBreadcrumbModel(item) ? item?.displayName : item.label ?? ''}`}
                onClick={onClick}
                linkComponent={linkComponent}
                lastItemDisabled={lastItemDisabled}
              />
            );
          })}
        </nav>
      </>
    );
  }

  return null;
}

export default BreadcrumbSTARC;
