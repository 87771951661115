/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Fragment } from 'react';
import { type ContentStackProductFinder } from '../../interface';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import InfoToolTip from '@/components/AZCustomComponent/InfoToolTip';
import { View, Text, Hidden, Divider } from '@az/starc-ui';
import { CMSImage } from '../CMSImage/CMSImage';
import styles from './CMSProductFinder.module.scss';
import { mapCMSDataToProductAttributes } from '../../utils/mapCMSDataToProductAttributes';
import { Warning } from './Warning/Warning';
import { useEffect } from 'react';

type Props = {
  content: NonNullable<ContentStackProductFinder>;
  setRemoveMargin: (value: boolean) => void;
};

export const CMSProductFinder = ({ content, setRemoveMargin }: Props) => {
  const {
    background_image,
    description = '',
    heading = '',
    warning_message,
    value_text_orange,
  } = content;
  const { data } = useProductShelfResults();
  const attributes = mapCMSDataToProductAttributes(data);

  useEffect(() => {
    if (setRemoveMargin) {
      setRemoveMargin(true);
      return () => {
        setRemoveMargin(false);
      };
    }
  }, [setRemoveMargin]);

  if (!data?.productShelfResults?.totalNumberOfRecords || !attributes.length) {
    return null;
  }

  return (
    <View className={styles.productFinderContainer}>
      <View padding={[0, 4]}>
        <Divider />
      </View>
      <View className={styles.innerContainer} direction="column">
        <Text variant="display-4" textCase="uppercase" weight="medium" className={styles.header}>
          {heading}
        </Text>
        <View direction={{ s: 'column-reverse', m: 'row' }} align={'center'} className={styles.gap}>
          <View.Item grow>
            <View maxWidth={{ m: '534px' }}>
              <Text
                className={styles.description}
                attributes={{ dangerouslySetInnerHTML: { __html: description } }}
              >
                {null}
              </Text>
            </View>

            <Hidden hide={{ s: true, m: false }}>
              {warning_message && (
                <View className={styles.warningMsgContainer}>
                  <Warning warning={warning_message} />
                </View>
              )}
            </Hidden>
          </View.Item>
          <View.Item grow>
            <View
              as="ul"
              direction="row"
              justify={'center'}
              align="start"
              gap={2}
              width={{ s: '100%' }}
              wrap={false}
            >
              {attributes.map(({ attribute, description, value }, index) => {
                const cmsAttribute = content.column?.[index];

                return (
                  <Fragment key={`${attribute ?? 'attribute'} - ${index}`}>
                    <View
                      as="li"
                      direction="column"
                      align="center"
                      className={styles.attributeContainer}
                    >
                      <View>
                        <Text
                          weight="bold"
                          color={value_text_orange ? 'accent' : 'primary'}
                          className={styles.attributeValue}
                        >
                          {value}
                        </Text>
                      </View>
                      {cmsAttribute?.image && cmsAttribute.image.image_url && (
                        <View className={styles.textless}>
                          <CMSImage image={cmsAttribute.image} className={styles.attributeImage} />
                        </View>
                      )}
                      <View direction="column" align="center">
                        <View direction="row" align="center">
                          <Text variant="small-body" className={styles.attributeBottomText}>
                            {attribute}
                          </Text>
                          {(cmsAttribute?.tooltip_header || cmsAttribute?.tooltip_column) && (
                            <View padding={[0, 0, 0, 1]} justify="center">
                              <InfoToolTip
                                iconSizePx={18}
                                header={cmsAttribute.tooltip_header ?? ''}
                                body={cmsAttribute.tooltip_column ?? ''}
                                placement="top"
                              />
                            </View>
                          )}
                        </View>
                        {description && (
                          <View>
                            <Text className={styles.attributeDescription}>{description}</Text>
                          </View>
                        )}
                      </View>
                    </View>
                    {index < attributes.length - 1 && (
                      <View as="li" className={styles.attributeDivider} />
                    )}
                  </Fragment>
                );
              })}
            </View>
          </View.Item>
        </View>
        {background_image?.image_url && (
          <CMSImage image={background_image} className={styles.backgroundImage} />
        )}
        <Hidden hide={{ m: true }}>
          <View className={styles.warningMsgContainer}>
            {warning_message && <Warning warning={warning_message} />}
          </View>
        </Hidden>
      </View>
    </View>
  );
};
