/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import { type FormattedContentStackSeoYmmeBuilder } from '@/features/contentstack/interface/models';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { usePageType } from '@/features/category/api/getPageType';
import { getSeoYMMEContent } from '../../contentstack/lib/seoData/getContentStackYMMESeoContent';
import { type PageContextType } from '@/next-types';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const URL = `/api/contentstack/seoYMMEContent`;
const getContentStackYMMESeoData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCSYMMSEOContentKey>>) => {
  const [, , { year, make, model, productId }] = queryKey;
  const { data } = await getAxiosForClient().get<FormattedContentStackSeoYmmeBuilder>(URL, {
    params: {
      year,
      make,
      model,
      productId,
    },
  });
  return data;
};

const getCSYMMSEOContentKey = ({
  year,
  make,
  model,
  productId,
}: {
  year: string | null;
  make: string | null;
  model: string | null;
  productId: string;
}) => ['cms', 'ymmSEOBuilder', { year, make, model, productId }] as const;

export const useContentStackYMMESeoData = ({ enabled = true }: { enabled?: boolean }) => {
  const { data: pageTypeData } = usePageType();
  const isCMSSeoYMMEContentEnabled = useMonetateDecisionFlag('cmsSeoYMMEContentEnabled');
  return useQuery({
    queryKey: getCSYMMSEOContentKey({
      year: pageTypeData?.year ?? null,
      make: pageTypeData?.make ?? null,
      model: pageTypeData?.model ?? null,
      productId: pageTypeData?.catalogRepositoryId ?? '',
    }),
    queryFn: getContentStackYMMESeoData,
    enabled: enabled && isCMSSeoYMMEContentEnabled,
  });
};

export const prefetchContentStackYMMESeoData = ({
  queryClient,
  context,
  year,
  make,
  model,
  productId,
}: {
  queryClient: QueryClient;
  context: PageContextType;
  year?: string;
  make?: string;
  model?: string;
  productId?: string;
}) => {
  return queryClient.prefetchQuery({
    queryKey: getCSYMMSEOContentKey({
      year: year ?? '',
      make: make ?? '',
      model: model ?? '',
      productId: productId ?? '',
    }),
    queryFn: () =>
      getSeoYMMEContent(context.req, { ...context.query, year, make, model, productId }),
  });
};
