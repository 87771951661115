/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  type GetProductListResultsUsingGetParamsModel,
  type ProductShelfResponseModel,
} from '@/api/types/browse-search-types';
import { requestBaseURLGCP } from '@/config/serviceAPI';
import { type ProductShelfResponse } from '../interface';
import { type AxiosInstance, isAxiosError } from 'axios';
import { type InfiniteData, type QueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { getAxios } from '@/lib/axios';
import { parseUrl, stringifyUrl } from '@/utils/urlHelpers';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { pageTypes } from '@/constants/page';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { useRouter } from 'next/router';
import { useAppState } from '@/stores/AppState';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { usePageType } from './getPageType';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { useCMSShelfPageConfig } from '@/features/contentstack/hooks/useCMSShelfPageConfig';
import { useContentStackPLPData } from '@/features/productListingPage/api/getContentStackProductListingPageData';
import logger from '@/utils/logger';
import { useMemo } from 'react';
import { getKiboDecisionFlag, useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { createQuery } from '@/utils/createReactQuery';
import type { Store } from '@/types';
const CATEGORY_SHELF_URL = `${requestBaseURLGCP}/external/product-discovery/browse-search/v1/product-shelves`;
import { routePaths } from '@/constants/routePaths';

type Options = {
  locale?: Locale;
  vehicleId: string | undefined;
  taxonomyPath?: string;
  tag?: string;
  canonicalPath?: string;
  pageType?: string;
  botEnabledFacetPath?: string;
  path?: string | undefined;
  recsPerPage?: string | undefined;
  makeModelYearPath?: string | undefined;
  storeId?: string | undefined;
  preview: boolean;
  ignoreVehicleSpecificProductsCheck?: boolean;
  rewardsId: string;
  eCookieId: string;
  pageNumber?: number;
};

const getProductShelfResults = async (options: Options, axiosInstance?: AxiosInstance) => {
  const {
    eCookieId,
    locale,
    taxonomyPath,
    vehicleId,
    canonicalPath,
    pageType,
    preview,
    path,
    recsPerPage,
    botEnabledFacetPath,
    makeModelYearPath,
    ignoreVehicleSpecificProductsCheck,
    rewardsId,
    tag,
    storeId,
    pageNumber,
  } = options;

  if (!canonicalPath) {
    throw new Error('canonicalPath must be provided in getCategoryShelfResults');
  }

  if (pageType !== pageTypes.ProductShelf && !tag) {
    throw new Error('No product shelf data on categories pages');
  }

  const country = getCountryFromLocale(locale || 'en-US');
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';
  const parsedUrl = parseUrl(path ?? '');
  const isClearanceShelf = path?.startsWith(`${routePaths.clearance}`);

  const params: GetProductListResultsUsingGetParamsModel = {
    country,
    customerType,
    salesChannel,
    preview,
    canonicalPath,
    pageNumber,
    recordsPerPage: recsPerPage
      ? Number(recsPerPage)
      : parsedUrl.query.recsPerPage
      ? Number(parsedUrl.query.recsPerPage)
      : undefined,
    sort: parsedUrl.query.sort,
    vehicleId,
    storeId,
    ...(tag && {
      ruleTags: tag,
      isLandingPage: true,
      facet: parsedUrl.query.facet ?? undefined,
    }),
    ignoreVehicleSpecificProductsCheck: isClearanceShelf,
    ...(!tag && {
      taxonomyPath,
      botEnabledFacetPath,
      searchedKeyword: !parsedUrl.query.redirectUrl
        ? parsedUrl.query.filterByKeyWord ?? parsedUrl.query.searchText
        : undefined,
      makeModelYearPath: makeModelYearPath === '' ? undefined : makeModelYearPath,
      facet: parsedUrl.query.facet ?? undefined,
      partNumberSearch: Boolean(parsedUrl.query.partNumberSearch) ?? undefined,
      minPrice: parsedUrl.query.minPrice,
      maxPrice: parsedUrl.query.maxPrice,
      ...((!!ignoreVehicleSpecificProductsCheck && { ignoreVehicleSpecificProductsCheck: true }) ||
        (!!parsedUrl.query.ignoreVehicleSpecificProductsCheck && {
          ignoreVehicleSpecificProductsCheck: true,
        })),
    }),
  };

  const response = await getAxios(axiosInstance).get<ProductShelfResponseModel>(
    CATEGORY_SHELF_URL,
    {
      ...(country === 'USA' && {
        headers: {
          eCookieId,
          ...(!!rewardsId && { rewardsId }),
        },
      }),
      params,
    }
  );
  return productShelfResultsSelector(response.data);
};

const productShelfResultsSelector = ({
  productShelfResults,
  redirectUrl,
  xFusionQueryId,
}: ProductShelfResponseModel): ProductShelfResponse => {
  return {
    productShelfResults,
    redirectLocation: redirectUrl ?? '',
    xFusionQueryId,
  };
};

export const {
  useData: useProductShelfResultsData,
  prefetch: prefetchProductShelfResultsData,
  query: productsShelfQuery,
} = createQuery<ProductShelfResponse, Options>(
  'productshelf-results',
  async (options, axiosInstance) => getProductShelfResults(options, axiosInstance)
);

export const useProductShelfResults = ({
  enabled = true,
  fromRedirect = false,
  ...rest
}: {
  enabled?: boolean;
  fromRedirect?: boolean;
} = {}) => {
  const router = useRouter();
  const headerResult = useHeaderData();
  const preferredVehicle = getPreferredVehicle(headerResult.data);
  const locale = useLocale();
  const appState = useAppState();
  const { recordsPerPage } = useCMSShelfPageConfig();
  const isMerchShelfPage = router.route === '/deals/[cmsPath]/[[...slug]]';
  const { data: plpCMSData, isSuccess: isPLPCMSDataSuccess } = useContentStackPLPData({
    enabled: true,
  });
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  // TODO: replace with router.asPath when Next bug is fixed
  // https://github.com/vercel/next.js/issues/46876
  const reqUrl = __IS_SERVER__ ? appState.state.reqUrl : router.asPath;

  if (reqUrl == null) {
    throw new Error(`A 'path' option is required`);
  }

  const { data: pageTypeData } = usePageType();
  const parsedUrl = parseUrl(reqUrl);

  const lwTag = !!plpCMSData?.additional_attributes_merch_shelf_page?.lucidworks_fusion_tag
    ? plpCMSData.additional_attributes_merch_shelf_page.lucidworks_fusion_tag
    : undefined;

  const taxonomyPath = pageTypeData?.taxonomyPath || undefined;
  delete parsedUrl.query.loadMore;
  const cleanedReqUrl = stringifyUrl(parsedUrl);

  const options = {
    rewardsId: headerResult.data?.myAccountMap?.rewardsId ?? '',
    eCookieId: appState.state.eCookieId,
    locale,
    vehicleId: preferredVehicle?.catalogVehicleId,
    pageType: pageTypeData?.pageType || undefined,
    canonicalPath: pageTypeData?.canonicalPath || parsedUrl.url,
    taxonomyPath,
    tag: lwTag,
    botEnabledFacetPath: pageTypeData?.botEnabledFacetPath || undefined,
    makeModelYearPath: pageTypeData?.makeModelYearPath || undefined,
    recsPerPage: recordsPerPage ? String(recordsPerPage) : undefined,
    storeId: headerResult.data?.storeNumber ?? undefined,
    pageNumber:
      typeof router.query.currentPage === 'string'
        ? Number(router.query.currentPage)
        : typeof router.query.pageNumber === 'string'
        ? Number(router.query.pageNumber)
        : 1,
    path: router.route === '/cms/taxonomy-selector' ? taxonomyPath : cleanedReqUrl || '',
    preview: showXMPreviewDate(),
    ...(!!fromRedirect && { ignoreVehicleSpecificProductsCheck: fromRedirect }),
    ...rest,
  };
  let infiniteQueryData: InfiniteData<ProductShelfResponse> | undefined;
  let fetchNextPageProductShelfResults = () => Promise.resolve();
  let hasNextPageProductShelfResults: boolean | undefined = false;
  let fetchPreviousPageProductShelfResults = () => Promise.resolve();
  let hasPreviousPageProductShelfResuts: boolean | undefined = false;
  let isFetchingProductShelfResults: boolean | undefined = false;
  let isLoadingProductShelfResults: boolean | undefined = false;
  let statusProductShelfResults = 'idle';
  let refetchProductShelfResults = () => Promise.resolve();
  const infiniteQueryResult = useInfiniteQuery({
    queryKey: [...productsShelfQuery.getFullKey(options), 'infinite'],
    queryFn: ({ pageParam = 1 }: { pageParam?: number }) => {
      return getProductShelfResults({
        ...options,
        pageNumber: pageParam,
        recsPerPage: '24',
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalRecords = lastPage?.productShelfResults?.totalNumberOfRecords ?? 0;
      const recsPerPage = Number(options.recsPerPage) || 24;
      const currentPageNumber = allPages.length;
      const totalPages = Math.ceil(totalRecords / recsPerPage);

      if (currentPageNumber < totalPages) {
        return currentPageNumber + 1;
      }
      return undefined;
    },
    enabled: isMerchShelfPage
      ? isPLPCMSDataSuccess && !!lwTag
      : enabled &&
        isLoadMoreEnabled &&
        pageTypeData?.pageType === 'ProductShelf' &&
        (router.route === '/[...seoUrlPath]' || router.route === '/cms/taxonomy-selector'),
    staleTime: 30 * 60000,
    onSuccess: async (data: InfiniteData<ProductShelfResponse>) => {
      const lastPage = data.pages[data.pages.length - 1];
      if (lastPage.redirectLocation) {
        await router.replace(lastPage.redirectLocation);
      }
    },
    onError: async (err) => {
      logger.error({
        message: 'Error fetching product shelf results',
        meta: {
          error: isAxiosError(err) ? err.response : err,
        },
      });

      try {
        if (isAxiosError(err) && err.response?.status === 400) {
          await router.replace('/errorPage');
          return;
        }
      } catch (error) {
        throw new Error(`There was a problem redirecting to /errorPage`);
      }
    },
  });
  const regularQueryResult = useProductShelfResultsData({
    ...options,
    enabled: isMerchShelfPage
      ? isPLPCMSDataSuccess && !!lwTag
      : enabled &&
        !isLoadMoreEnabled &&
        pageTypeData?.pageType === 'ProductShelf' &&
        (router.route === '/[...seoUrlPath]' || router.route === '/cms/taxonomy-selector'),
    staleTime: 30 * 60000,
    pageNumber: parsedUrl.query.pageNumber ? Number(parsedUrl.query.pageNumber) : 1,
    onSuccess: async (data) => {
      if (data.redirectLocation) {
        await router.replace(data.redirectLocation);
      }
    },
    ...(!!fromRedirect && { ignoreVehicleSpecificProductsCheck: fromRedirect }),
    ...rest,
    onError: async (error) => {
      try {
        // eslint-disable-next-line no-console
        console.log(`An error occurred while requesting product shelf results: `, error);
        await router.replace('/errorPage');
        return;
      } catch (error) {
        throw new Error(`There was a problem redirecting to /errorPage`);
      }
    },
  });
  if (isLoadMoreEnabled) {
    const {
      data,
      fetchNextPage,
      hasNextPage,
      fetchPreviousPage,
      hasPreviousPage,
      isFetching,
      isLoading,
      status,
      refetch,
    } = infiniteQueryResult;
    fetchNextPageProductShelfResults = () => fetchNextPage().then(() => {});
    hasNextPageProductShelfResults = hasNextPage;
    fetchPreviousPageProductShelfResults = () => fetchPreviousPage().then(() => {});
    hasPreviousPageProductShelfResuts = hasPreviousPage;
    isLoadingProductShelfResults = isLoading;
    statusProductShelfResults = status;
    isFetchingProductShelfResults = isFetching;
    refetchProductShelfResults = () => refetch().then(() => {});
    infiniteQueryData = data;
  }

  const mergedData = useMemo(() => {
    if (!infiniteQueryData?.pages) return undefined;
    return infiniteQueryData?.pages?.reduce(
      (acc: ProductShelfResponse, page: ProductShelfResponse) => {
        const { redirectPageType, redirectUrl, xFusionQueryId } = page;
        if (page?.productShelfResults) {
          return {
            ...acc,
            productShelfResults: {
              ...acc?.productShelfResults,
              ...page?.productShelfResults,
              skuRecords: [
                ...(acc?.productShelfResults?.skuRecords ?? []),
                ...(page?.productShelfResults?.skuRecords ?? []),
              ],
            },
            redirectPageType,
            redirectUrl,
            xFusionQueryId,
          };
        }
        return acc;
      },
      { productShelfResults: { skuRecords: [] } } as unknown as ProductShelfResponse
    );
  }, [infiniteQueryData]);

  return {
    data: isLoadMoreEnabled ? mergedData : regularQueryResult.data,
    pageNumber: isLoadMoreEnabled ? infiniteQueryData?.pages.length ?? 1 : options.pageNumber,
    fetchNextPage: fetchNextPageProductShelfResults,
    hasNextPage: hasNextPageProductShelfResults,
    fetchPreviousPage: fetchPreviousPageProductShelfResults,
    hasPreviousPage: hasPreviousPageProductShelfResuts,
    isFetching: isLoadMoreEnabled ? isFetchingProductShelfResults : regularQueryResult.isFetching,
    isLoading: isLoadMoreEnabled ? isLoadingProductShelfResults : regularQueryResult.isLoading,
    status: isLoadMoreEnabled ? statusProductShelfResults : regularQueryResult.status,
    refetch: isLoadMoreEnabled ? refetchProductShelfResults : regularQueryResult.refetch,
  };
};

export const prefetchProductShelfResults = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  options: Options
) => {
  return prefetchProductShelfResultsData(queryClient, options, axiosInstance);
};

export const prefetchProductShelfResultsInfinite = async (
  axiosInstance: AxiosInstance,
  queryClient: QueryClient,
  options: Options
) => {
  return queryClient.prefetchInfiniteQuery({
    queryKey: [...productsShelfQuery.getFullKey(options), 'infinite'],
    queryFn: ({ pageParam = 1 }) =>
      getProductShelfResults(
        {
          ...options,
          pageNumber: pageParam as number,
          recsPerPage: options.recsPerPage as string,
        },
        axiosInstance
      ),
    getNextPageParam: (lastPage, allPages) => {
      const nextPageNumber = allPages.length + 1;
      return lastPage.productShelfResults?.lastRecordNumber ? nextPageNumber : undefined;
    },
  });
};

export const getProductShelfResultsFromCache = (
  queryClient: QueryClient,
  store: Store,
  options: Options
): ProductShelfResponse | undefined => {
  const isLoadMoreEnabled = getKiboDecisionFlag(queryClient, 'loadMoreEnabled');
  const { appData } = store.getState();
  if (isLoadMoreEnabled) {
    return queryClient.getQueryData<InfiniteData<ProductShelfResponse>>([
      ...productsShelfQuery.getFullKey({
        recsPerPage: appData?.deviceType === 'bot' ? '24' : undefined,
        ...options,
        locale: 'en-US',
      }),
      'infinite',
    ])?.pages[0];
  }
  return queryClient.getQueryData<ProductShelfResponse>(
    productsShelfQuery.getFullKey({
      recsPerPage: appData?.deviceType === 'bot' ? '24' : undefined,
      ...options,
      locale: 'en-US',
    })
  );
};

export const useProduleShelfFacets = () => {
  const router = useRouter();
  const headerResult = useHeaderData();
  const locale = useLocale();
  const appState = useAppState();

  // TODO: replace with router.asPath when Next bug is fixed
  // https://github.com/vercel/next.js/issues/46876
  const reqUrl = __IS_SERVER__ ? appState.state.reqUrl : router.asPath;

  if (reqUrl == null) {
    throw new Error(`A 'path' option is required`);
  }

  const { data: pageTypeData } = usePageType();

  return useProductShelfResultsData({
    rewardsId: headerResult.data?.myAccountMap?.rewardsId ?? '',
    eCookieId: appState.state.eCookieId,
    locale,
    vehicleId: undefined,
    pageType: pageTypeData?.pageType ?? 'ProductShelf',
    canonicalPath: pageTypeData?.canonicalPath ?? parseUrl(reqUrl).url,
    taxonomyPath: pageTypeData?.taxonomyPath,
    botEnabledFacetPath: undefined,
    makeModelYearPath: undefined,
    recsPerPage: '1',
    storeId: headerResult.data?.storeNumber ?? undefined,
    path: reqUrl,
    preview: showXMPreviewDate(),
    staleTime: 30 * 60000,
    onSuccess: async (data) => {
      if (data.redirectLocation) {
        await router.replace(data.redirectLocation);
      }
    },
  });
};
