/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Head from 'next/head';
import { SmartLink as Link } from '../../../utils/smartLink';
import { getBaseSchemaUrl } from '../../../utils/common';
import cx from 'classnames';
import styles from './styles.module.scss';
import BreadcrumbSTARC from './__starc_components__/BreadcrumbSTARC';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { useLocale } from '@/hooks/useLocale';
import { type BreadcrumbModel } from '../../../../api/types/browse-search-types';
import { type IItem } from './utils/isBreadcrumbModel';

const trackBreadCrumbInteraction = (linkName: string, breadCrumbValue: string) => {
  clickTrack({
    breadCrumbLinkName: linkName,
    eventType: 'breadcrumbLinkInteraction',
    breadCrumbValue: breadCrumbValue,
  });
};

type IProps = {
  data?: Array<IItem | BreadcrumbModel>;
  showSEOData?: boolean;
  customClass?: string;
  lastItemDisabled?: boolean;
};

const Breadcrumb = ({ data = [], showSEOData, customClass, lastItemDisabled = false }: IProps) => {
  const locale = useLocale();
  const baseSchemaURL = getBaseSchemaUrl(locale);

  const setBreadcrumbAnalytics = (e: Event, item: IItem) => {
    const clickedUrl: string = item.url
      ? typeof item.url === 'string'
        ? item.url
        : item.url.pathname
      : '';

    if (item.url === '#') {
      e.preventDefault();
    }

    const breadcrumbList = data.map((el) => el.displayName);
    const breadCrumbValue = breadcrumbList.join('|');
    trackBreadCrumbInteraction(clickedUrl, breadCrumbValue);
  };

  return (
    <div data-testid="breadCrumb" className={cx(styles['breadcrumb-wrapper'], customClass)}>
      {data.length > 0 && (
        <BreadcrumbSTARC
          data={data}
          showSEOData={showSEOData}
          baseSchemaURL={baseSchemaURL}
          headComponent={Head}
          linkComponent={Link}
          onClick={(e, item) => setBreadcrumbAnalytics(e, item)}
          lastItemDisabled={lastItemDisabled}
        />
      )}
    </div>
  );
};

export default Breadcrumb;
