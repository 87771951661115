/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { CMSCertona } from '@/features/contentstack/components/CMSCertona/CMSCertona';
import type {
  ContentStackMerchandisingShelfPage,
  ContentStackShelfPage,
} from '@/features/contentstack/interface';
import React from 'react';
import {
  type CertonaBlockProps,
  type CertonaRecommendation,
  ScrolledPartCardSkeleton,
} from '@/features/certona';
import { CMSShelfSpacer } from '@/features/contentstack/components/CMSShelfSpacer/CMSShelfSpacer';
import { useProductList } from '../../../context/useProductList';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { FeatureFlag, useFeatureFlag } from '@/features/globalConfig';
import { DynamicLinks } from '@/features/dlm/components/DynamicLinks';
import { VehicleDynamicLinks } from '@/features/dlm/components/VehicleDynamicLinks';
import dynamic from 'next/dynamic';
import { ShopByModelList } from '@/features/shopByModel';
import { useCategoryYears } from '@/features/category/api/getCategoryYears';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import ShopByPaginationStyleLinks from '@/components/ShopByPaginationStyleLinks';
import { getShopByType } from '../../../utils/getShopByType';
import { CMSPLPSeoCopy } from '@/features/productListingPage/components/CMSPLPSeoCopy/CMSPLPSeoCopy';

type Props = {
  certonaRecommendations: CertonaRecommendation[];
  content:
    | NonNullable<ContentStackShelfPage['shelfresultbottom']>
    | NonNullable<ContentStackMerchandisingShelfPage['shelfresultbottom']>;
  yearsTableData?: {
    productId?: string | null;
    make?: string;
    model?: string;
    hasAssociatedPartType?: boolean;
  };
} & CertonaBlockProps;

const LazyYearsTable = dynamic(
  () => import('../../YearsTable/YearsTable').then(({ YearsTable }) => ({ default: YearsTable })),
  { ssr: true }
);

const CMSResultBottom = ({
  certonaRecommendations,
  content,
  yearsTableData,
  onCertonaRecommendationClick,
  setAddedCertonaItemObject,
  onCertonaAddToCartClick,
}: Props) => {
  const { data: shelfData } = useProductList();
  const shelfProductId = shelfData?.productId;
  const locale = useLocale();
  const makeModelYearPageType = shelfData?.makeModelYearPageType;
  const yearsTableEnabled = useFeatureFlag('YEARS_TABLE_ENABLED') === 'true';
  const { data: productShelfResultsData, status } = useProductShelfResults();
  const shopByType = productShelfResultsData?.productShelfResults
    ? getShopByType(productShelfResultsData.productShelfResults)
    : null;
  const isShelfCertonaAddToCartEnabled =
    useFeatureFlag('IS_SHELF_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';
  const { data: yearsData } = useCategoryYears({
    enabled: status === 'success' && (shopByType === 'ShopByModel' || shopByType === 'ShopByYear'),
    year: Boolean(productShelfResultsData?.productShelfResults?.year),
  });

  return (
    <>
      {content.map((modularBlock, index) => {
        const containerID = modularBlock.certona?.certona?.[0]?.container_id;
        const ignoredContainerIDs = [
          'accessoriesshelf1_rr',
          'partshelf1_rr',
          'accessoriesshelf_rr',
          'partshelf_rr',
        ];
        const shouldIgnoreSpacing = Boolean(
          containerID && ignoredContainerIDs.includes(containerID)
        );
        if ('seo_copy_content' in modularBlock && modularBlock.seo_copy_content) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSPLPSeoCopy />
            </CMSShelfSpacer>
          );
        }

        if (modularBlock.certona?.certona?.[0]) {
          return (
            <CMSShelfSpacer key={index} shouldIgnoreCarouselSpacing={shouldIgnoreSpacing}>
              <CMSCertona
                content={modularBlock.certona.certona[0]}
                certonaRecommendations={certonaRecommendations}
                onCertonaRecommendationClick={onCertonaRecommendationClick}
                onCertonaAddToCartClick={onCertonaAddToCartClick}
                setAddedCertonaItemObject={setAddedCertonaItemObject}
                onlyShowAvailableProductRecommendations={isShelfCertonaAddToCartEnabled}
                //TO-DO update loading state for mobile once they are added in this https://track.autozone.com/browse/SEB-11317
                LoadingSkeleton={ScrolledPartCardSkeleton}
                subtitle={modularBlock.certona.certona[0].subtitle}
              />
            </CMSShelfSpacer>
          );
        }

        if (modularBlock.pagination_style_links?.enable) {
          return (
            <React.Fragment key={index}>
              {shelfProductId && locale === countryCodes.us && !makeModelYearPageType && (
                <FeatureFlag flag="DLM_SHELF_ENABLED">
                  <CMSShelfSpacer>
                    <DynamicLinks seoRepositoryId={shelfProductId} />
                  </CMSShelfSpacer>
                </FeatureFlag>
              )}
              {shelfProductId && locale === countryCodes.us && makeModelYearPageType && (
                <FeatureFlag flag="DLM_YMM_SHELF_ENABLED">
                  <CMSShelfSpacer>
                    <VehicleDynamicLinks seoRepositoryId={shelfProductId} />
                  </CMSShelfSpacer>
                </FeatureFlag>
              )}
            </React.Fragment>
          );
        }

        if (modularBlock.year_range_for_makemodel_by_model?.enable) {
          return (
            <React.Fragment key={index}>
              {shopByType === 'ShopByModel' && !!yearsData?.shopByMMYList.length && (
                <ShopByModelList
                  data={{
                    shopByMMYList: yearsData.shopByMMYList,
                    canonicalPath: yearsData.canonicalPath,
                    taxonomyPath: yearsData.taxonomyPath,
                    pageType: yearsData.pageType,
                    title: yearsData.title ?? '',
                    desktop: true,
                    tablet: true,
                    mobile: true,
                  }}
                />
              )}
              {yearsTableEnabled &&
                productShelfResultsData?.productShelfResults?.yearsTableEnableFlag &&
                yearsTableData && (
                  <LazyYearsTable
                    productId={yearsTableData?.productId}
                    make={yearsTableData?.make}
                    model={yearsTableData?.model}
                    hasAssociatedPartType={yearsTableData?.hasAssociatedPartType}
                  />
                )}
              {shopByType === 'ShopByYear' && yearsData && !!yearsData.shopByMMYList.length && (
                <ShopByPaginationStyleLinks
                  data={{
                    title: yearsData.title ?? '',
                    shopByPaginationStyleLinks: yearsData.shopByMMYList ?? [],
                    canonicalPath: yearsData.canonicalPath,
                    taxonomyPath: yearsData.taxonomyPath,
                    pageType:
                      yearsData.pageType ?? productShelfResultsData?.productShelfResults?.pageType,
                    desktop: true,
                    tablet: true,
                    mobile: true,
                  }}
                />
              )}
            </React.Fragment>
          );
        }
        return null;
      })}
    </>
  );
};

export { CMSResultBottom };
