/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import usePrevious from '@/utils/usePrevious';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useProductListData } from '../api/getProductList';
import { ProductListContext } from '../ProductListContext';

export const ProductListProvider = ({ children }: { children: React.ReactNode }) => {
  const [shouldFetchSecondaryProductSkus, setShouldFetchSecondaryProductSkus] = useState(false);
  const router = useRouter();
  const loadMoreClicked = useMemo(() => router.query.loadMore === 'true', [router.query.loadMore]);
  const prevRoute = usePrevious(router.asPath);
  useEffect(() => {
    if (router.asPath !== prevRoute) {
      setShouldFetchSecondaryProductSkus(false);
    }
  }, [router.asPath, prevRoute, setShouldFetchSecondaryProductSkus]);
  const results = useProductListData({ shouldFetchSecondaryProductSkus, loadMoreClicked });
  const value = useMemo(
    () => ({
      ...results,
      setShouldFetchSecondaryProductSkus,
      loadMoreClicked,
    }),
    [results, setShouldFetchSecondaryProductSkus, loadMoreClicked]
  );
  return <ProductListContext.Provider value={value}>{children}</ProductListContext.Provider>;
};
