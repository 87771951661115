/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { PureComponent, Fragment } from 'react';
import cx from 'classnames';
import CustomRadioInput from '../FormGroup/RadioButton';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { countryCodes } from '@/constants/locale';
import { nddIcon } from '@/constants/images/nddIcon';
import { productInfoConstants } from '@/constants/productInfoConstants';
import { cartConstants } from '@/constants/cart';
import azCommonStyles from '@/theme/globals.module.scss';
import { convertToAmPm } from '@/utils/convertToAmPm';
import CountDown from '@/components/AZCustomComponent/CountDown';
import InfoToolTip from '@/components/AZCustomComponent/InfoToolTip';
import { Button } from '@/components/Button';
import { globalConstant } from '@/constants/global';
import { orderTypes } from '@/constants/orderTypes';
import NextImage from '@/components/NextImage';
import Availability from './Availability';
import styles from './styles.module.scss';
import { fulfillmentLabels } from '@/constants/fulfillmentConstants';
import { useGetAllHeaderData } from '@/features/header/api/useGetAllHeaderData';
import { infoIconWithGreenColor } from '@/constants/images';
import { type TSkuAvailability } from '@/features/product';
import { MyStoreModal } from '@/components/Store/StoreModal/MyStoreModal';
import { usePriceHiding } from '@/hooks/usePriceHiding';

type Props = {
  isVDPItem?: boolean;
  options: Array<any>;
  cartPage?: boolean;
  selectedValue: string;
  inline?: boolean;
  storeSelected?: boolean;
  onChange: (event: any, activeValue: string) => void;
  applyNegativeMargin?: boolean;
  locale?: string | null;
  dollars?: string;
  cents?: string;
  availabilityInfoVO?: any;
  isBopusView?: boolean;
  numberOfSisterStores?: number;
  skuAvailabilityInfo?: TSkuAvailability;
  phoneNumber?: string;
  hidePricing?: boolean;
};

type PropsComp = {
  labels: any;
} & Props;

type State = {
  modalOpen: boolean;
};

const labelMap = {
  Label_freeNextDayDeliveryEligible: 'Label_freeNextDayDeliveryEligible',
  label_cart_lineItem_WhatIsIt: 'label_cart_lineItem_WhatIsIt',
  label_Visit_Store_To_Purchase: 'label_Visit_Store_To_Purchase',
  label_MyAccountSTH_shipaddress_EstDelivery: 'label_MyAccountSTH_shipaddress_EstDelivery',
  labels_order_within: 'labels_order_within',
  labels_order_hrs: 'labels_order_hrs',
  labels_order_and: 'labels_order_and',
  labels_order_min: 'labels_order_min',
  labels_order_to: 'labels_order_to',
  labels_order_receive: 'labels_order_receive',
  labels_OrderCutOffTimeHead: 'labels_order_cut_off_time_head_ndd',
  labels_OrderCutOffTimeTail: 'labels_order_cut_off_time_tail_ndd',
  label_shelf_resultsList_price_not_available: 'label_shelf_resultsList_price_not_available',
  Label_InStock_SisterStores: 'Label_InStock_SisterStores',
  lblAtNearByStores: fulfillmentLabels.label_shipping_AtNearByStores,
  lblAtNearByStore: fulfillmentLabels.label_shipping_AtNearByStore,
  label_howDoes_it_works_mx: 'label_howDoes_it_works_mx',
  label_info_toolTip_body_mx: 'label_info_toolTip_body_mx',
  label_price_not_available_mx: 'label_price_not_available_mx',
  label_order_by_phone_mx: 'label_order_by_phone_mx',
  order_online_pickup_inStore_noCost_mx: 'order_online_pickup_inStore_noCost_mx',
  label_Footer_main_SendListToStore: 'label_Footer_main_SendListToStore',
};

const timezone = new Date()
  .toLocaleTimeString('en-us', {
    timeZoneName: 'short',
  })
  .split(' ')[2];

export class ToggleComponent extends PureComponent<PropsComp, State> {
  constructor(props: PropsComp) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  freeNextDayDeliveryUi = (item: any) => {
    const cutOffTimeISO = item.shippingAvailability?.fulfillmentCutOffTime;
    const cutOffTime = new Date(cutOffTimeISO).toTimeString();
    return (
      <div>
        <span
          className={cx(azCommonStyles['az-caption'], styles.freeNextDayDelivery)}
          id="freeNextDayDelivery"
        >
          <NextImage
            tabIndex={-1}
            role="presentation"
            src={nddIcon.src}
            alt={nddIcon.alt}
            width={33}
            height={16}
          />{' '}
          {this.props.labels.Label_freeNextDayDeliveryEligible}
        </span>
        <InfoToolTip
          classes={{
            icon: styles.nddInfo,
          }}
          header={this.props.labels.label_cart_lineItem_WhatIsIt}
          body={`${this.props.labels.labels_OrderCutOffTimeHead}
          ${convertToAmPm(cutOffTime, false)}
          ${timezone}${this.props.labels.labels_OrderCutOffTimeTail}
          `}
        />
        <CountDown
          shippingAvailability={item.shippingAvailability}
          storeStockLabel={item.storeStockLabel}
          labels={this.props.labels}
        />
      </div>
    );
  };
  handleOnChangeCart = (event: any, selectedValue: string) => {
    event.preventDefault();

    if (
      (selectedValue === globalConstant.store && !this.props.options[0].disabled) ||
      (selectedValue === globalConstant.online && !this.props.options[1].disabled)
    ) {
      this.props.onChange(event, selectedValue);
    }
  };
  handleOnChange = (event: any, selectedValue: string, itemDisabled: boolean) => {
    event.preventDefault();

    if (selectedValue && !itemDisabled) {
      this.props.onChange(event, selectedValue);
    }
  };

  DisplayCustomPopper = (item: any) => {
    const sisterStores = this.props.availabilityInfoVO?.numberOfSisterStoresAvailable
      ? this.props.availabilityInfoVO?.numberOfSisterStoresAvailable
      : this.props.numberOfSisterStores;
    let itemLabel = item.storeAndAddToCartObj?.label ?? '';
    if (
      sisterStores >= 1 &&
      this.props.isBopusView &&
      (this.props.availabilityInfoVO?.sisterStoreAvailable || this.props.numberOfSisterStores)
    ) {
      let sisterStoreLabel =
        sisterStores > 1 ? this.props.labels.lblAtNearByStores : this.props.labels.lblAtNearByStore;

      itemLabel = sisterStoreLabel.replace(/(en|em)/, `$1 ${sisterStores}`);
    }
    const nextDayAvailable = item.sthoptions;
    const displDeliveryType = nextDayAvailable && this.freeNextDayDeliveryUi(item);
    const nextDayAvailableUi = (!nextDayAvailable || item.buttonIndex === globalConstant.store) &&
      !item.disabled &&
      !this.props.isBopusView && (
        <NextImage src="/images/green-tickmark.svg" alt="" width={14} height={11} />
      );
    const storeStockAvailable =
      !nextDayAvailable &&
      !(
        (this.props.availabilityInfoVO?.sisterStoreAvailable || item.showFindNearStoreBtn) &&
        this.props.isBopusView
      ) &&
      item.storeStockLabel;

    const renderInstockImageandLabel = () => (
      <>
        {!this.props.isBopusView && (
          <NextImage
            src={'/images/green-tickmark.svg'}
            alt={''}
            width={14}
            height={11}
            layout="fixed"
          />
        )}
        <span
          className={
            this.props.isBopusView
              ? styles.inStockSisterStoreLabelBopusMX
              : styles.inStockSisterStoreLabel
          }
          data-testid="sister-store-label"
        >
          <Label label={this.props.labels.Label_InStock_SisterStores} />
        </span>
      </>
    );

    // MX SisterStoreLogicFlow 1
    const addToCartLink = (
      <>
        {renderInstockImageandLabel()}
        {this.props.isBopusView ? ' ' : ''}
        <Button
          variant="link"
          onClick={(e) => {
            const {
              storeAndAddToCartObj: { onClick: toggleStoreModal },
            } = item;
            toggleStoreModal();

            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
          }}
          customClass={this.props.isBopusView ? styles.normal : styles.bold}
          id="deliveryLink"
        >
          {this.props.isBopusView && sisterStores > 0 ? itemLabel : <Label label={itemLabel} />}
        </Button>
      </>
    );

    const renderButton = (item: any) => (
      <>
        {' | '}
        <Button
          variant="link"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            this.openStoreModal();
          }}
          customClass={
            this.props.isBopusView ? styles.storeAddressBopusMX : styles.storeAddressLink
          }
        >
          {this.props.isBopusView
            ? item.storeDetails.address1.toLowerCase()
            : item.storeDetails.address1}
        </Button>
      </>
    );
    const displayAddToCartUi =
      item.showFindNearStoreBtn && item.storeAndAddToCartObj && addToCartLink;
    const renderStoreNameLink = [
      globalConstant.store,
      orderTypes.storeOrder,
      productInfoConstants.bopus,
    ].includes(item.buttonIndex) &&
      !(
        (this.props.availabilityInfoVO?.sisterStoreAvailable || item.showFindNearStoreBtn) &&
        this.props.isBopusView
      ) && (
        <span className={styles.storeLink}>
          {item.disabled ? (
            <span
              className={cx(
                styles.notAvailableAtStore,
                this.props.isBopusView ? styles.notAvailableMX : styles.notAvailable
              )}
            >
              {item.storeDetails.address1}
            </span>
          ) : (
            renderButton(item)
          )}
        </span>
      );
    const notAvailableInStockClass = item.disabled
      ? this.props.isBopusView
        ? styles.notAvailableMX
        : styles.notAvailable
      : styles.inStock;

    return (
      <Fragment>
        <>
          {displDeliveryType}
          <div className={notAvailableInStockClass}>
            {nextDayAvailableUi}{' '}
            <span data-testid="availability">
              {storeStockAvailable}
              {renderStoreNameLink}
            </span>
          </div>
          {displayAddToCartUi}{' '}
        </>
      </Fragment>
    );
  };
  DisplayNoStoreSelected = (item: any) => {
    const itemOnclick = item.storeAndAddToCartObj?.onClick ?? null;
    const itemLabel = item.storeAndAddToCartObj?.label ?? '';
    return (
      <Button
        variant="link"
        onClick={(e) => {
          itemOnclick();
          e.stopPropagation();
          e.preventDefault();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        <Label label={itemLabel} />
      </Button>
    );
  };

  DisplayGiftCard = (
    <div className={styles.notAvailable} id="notAvailable">
      {this.props.labels.label_Visit_Store_To_Purchase}
    </div>
  );

  subLabelOption = (item: any) => {
    let nextDayAvailable = item.sthoptions;
    const { shippingAvailability } = item;
    const selectStore =
      !this.props.storeSelected &&
      (item.buttonIndex === orderTypes.storeOrder || item.buttonIndex === globalConstant.store) &&
      item.disabled;
    const dispAddtoCart = selectStore
      ? this.DisplayNoStoreSelected(item)
      : this.DisplayCustomPopper(item);
    const displaySubLabel =
      item.storeStockLabel === cartConstants.giftCard ? this.DisplayGiftCard : dispAddtoCart;

    if (nextDayAvailable && shippingAvailability) {
      const currentDay = new Date(
        `${shippingAvailability.displayDate ?? ''} ${new Date().getFullYear()}`
      );
      const estimatedDate = new Date(
        `${shippingAvailability.calculatedDate ?? ''} ${new Date().getFullYear()}`
      );
      const daysDiffernce = (estimatedDate.getTime() - currentDay.getTime()) / (1000 * 3600 * 24);
      nextDayAvailable =
        !(shippingAvailability.carrierHoliday ?? false) &&
        !(shippingAvailability.holiday ?? false) &&
        shippingAvailability.orderByMessage != null &&
        daysDiffernce === 1;

      if (!nextDayAvailable && shippingAvailability.calculatedDate) {
        item.storeStockLabel = (
          <Fragment>
            {this.props.labels.label_MyAccountSTH_shipaddress_EstDelivery}
            {shippingAvailability.calculatedDate}
          </Fragment>
        );
      }
    }

    return (
      <div
        className={cx(
          azCommonStyles['az-caption'],
          azCommonStyles['az-align-left'],
          selectStore ? styles.selectStore : ''
        )}
      >
        {displaySubLabel}
      </div>
    );
  };
  openStoreModal = () => {
    this.setState({
      modalOpen: true,
    });
  };
  closeStoreModal = () => {
    this.setState({
      modalOpen: false,
    });
  };
  storeModal = (modalOpen: boolean) => {
    if (!modalOpen) {
      return null;
    }

    return <MyStoreModal closeStoreModal={this.closeStoreModal} />;
  };
  labelOption = (item: any) => {
    if (this.props.locale === countryCodes.ptBr) {
      return null;
    }

    return (
      <div
        className={cx(
          azCommonStyles['az-caption'],
          azCommonStyles['az-align-left'],
          this.props.isBopusView ? styles.deliveryOptionLabelBopusMX : styles.deliveryOptionLabel
        )}
        data-testid="delivery-option-label"
      >
        {item.deliveryOptionLabel === 'Ship To Home' ? 'Ship-To-Home' : item.deliveryOptionLabel}{' '}
      </div>
    );
  };
  priceLabel = (item: any) => {
    if (this.props.hidePricing) {
      return null;
    }

    return !item.isPriceDefined ? (
      <div
        className={cx(
          azCommonStyles['az-caption'],
          azCommonStyles['az-align-left'],
          styles.unavailablePriceLabel
        )}
        id="price-availability-label"
        data-testid="price-availability-label"
      >
        {this.props.labels.label_shelf_resultsList_price_not_available}
      </div>
    ) : null;
  };
  renderOption = (item: any, radio: boolean) => {
    const { inline, cartPage = false, isBopusView } = this.props;
    const vdpComp = this.props.isBopusView ? (
      <div className={styles.vdpItemDisplay}>
        <NextImage src="/images/shape-store.svg" alt="shape store" width={22} height={19} />
        <div className={styles.vdpItemContainer}>
          <div
            className={cx(
              azCommonStyles['az-caption'],
              azCommonStyles['az-align-left'],
              styles.aditionalCost
            )}
            id="price-availability-label"
            data-testid="price-availability-label"
          >
            {item.deliveryOptionLabel}
          </div>
          {this.props?.skuAvailabilityInfo &&
            Number(this.props?.skuAvailabilityInfo?.storeTotalPrice) <= 0 && (
              <div className={cx(azCommonStyles['az-align-left'], styles.vdpPriceNotAvailable)}>
                {this.props.labels.label_shelf_resultsList_price_not_available}
              </div>
            )}
          <div>
            <a
              className={cx(azCommonStyles['az-align-left'], styles.vdpPhoneLink)}
              href={`tel:+${this.props.phoneNumber}`}
            >
              {this.props.labels.label_order_by_phone_mx}
            </a>

            <span className={styles.infoToolTipWrapper}>
              <InfoToolTip
                classes={{ icon: styles.tooltipInfo }}
                placement="top"
                data-testid="mycart_rewards_tooltipicon"
                header={this.props.labels.label_howDoes_it_works_mx}
                body={this.props.labels.label_info_toolTip_body_mx}
                iconSrc={infoIconWithGreenColor}
              />
            </span>
          </div>
        </div>
      </div>
    ) : (
      <div className={styles.paddingForSTLS}>
        <div
          className={cx(
            azCommonStyles['az-caption'],
            azCommonStyles['az-align-left'],
            styles.aditionalCost
          )}
        >
          {this.props.labels.label_Footer_main_SendListToStore}
        </div>
        <div>
          <a
            className={cx(azCommonStyles['az-align-left'], styles.vdpPhoneLink)}
            href={`tel:+${this.props.phoneNumber}`}
          >
            {this.props.labels.label_order_by_phone_mx}
          </a>

          <span className={styles.infoToolTipWrapper}>
            <InfoToolTip
              classes={{ icon: styles.tooltipInfo }}
              placement="top"
              data-testid="mycart_rewards_tooltipicon"
              header={this.props.labels.label_howDoes_it_works_mx}
              body={this.props.labels.label_info_toolTip_body_mx}
              iconSrc={infoIconWithGreenColor}
            />
          </span>
        </div>
      </div>
    );
    const option = radio ? (
      <CustomRadioInput
        data-testid={`optionButton${item.buttonIndex}`}
        name={`fullfilment-radio${item.buttonIndex}`}
        value={item.buttonIndex}
        disabled={item.disabled}
        checked={
          cartPage
            ? item.isSelected
            : !this.props.storeSelected || (!item.disabled && item.isSelected)
        }
        label={this.labelOption(item)}
        subLabel={this.subLabelOption(item)}
      />
    ) : isBopusView ? (
      <>
        <div>
          <NextImage src="/images/shape-store.svg" alt="shape store" width={22} height={19} />
        </div>
        <div>
          {this.labelOption(item)}
          {this.priceLabel(item)}
          {this.subLabelOption(item)}
        </div>
      </>
    ) : (
      <>
        <div>
          {this.labelOption(item)}
          {this.priceLabel(item)}
        </div>
        {this.subLabelOption(item)}
      </>
    );
    return (
      <div
        className={`${
          this.props.isVDPItem
            ? styles.vpdItemCard
            : inline && !isBopusView
            ? styles.inlineToggle
            : styles.segmentedToggle
        }`}
        key={item.buttonIndex}
        onClick={(event) => {
          if (radio && !item.isSelected) {
            cartPage
              ? this.handleOnChangeCart(event, item.buttonIndex)
              : this.handleOnChange(event, item.buttonIndex, item.disabled);
          }
        }}
      >
        {this.props.isVDPItem ? vdpComp : option}
      </div>
    );
  };

  render() {
    const { options = [], inline, isBopusView } = this.props;
    const optionsMapObj =
      this.props.locale === countryCodes.mx || this.props.locale === countryCodes.ptBr
        ? this.renderOption(options[0], false)
        : options?.map((item: any) => this.renderOption(item, true));
    const toggleClass =
      inline && !isBopusView
        ? styles.inlineToggleContainer
        : isBopusView &&
          (this.props.locale === countryCodes.mx || this.props.locale === countryCodes.ptBr)
        ? styles.segmentedBopusMXContainer
        : styles.segmentedToggleContainer;
    return (
      <Availability
        optionsMapObj={optionsMapObj}
        toggleClass={toggleClass}
        storeModal={this.storeModal}
        closeStoreModal={this.closeStoreModal}
        modalOpen={this.state.modalOpen}
        applyNegativeMargin={this.props.applyNegativeMargin}
      />
    );
  }
}

const ToggleComponentComp = ({
  cartPage = false,
  inline = false,
  storeSelected = false,
  applyNegativeMargin = true,
  isBopusView = false,
  availabilityInfoVO,
  numberOfSisterStores,
  skuAvailabilityInfo,
  ...props
}: Props) => {
  const { storeData } = useGetAllHeaderData();
  const hidePricing = usePriceHiding();

  const phoneNumber = storeData?.phoneNumber;
  const fullfillmentOptions = skuAvailabilityInfo?.fulfillmentOptions?.[0];
  let isVDPItem = false;
  if (fullfillmentOptions && storeData) {
    isVDPItem =
      !fullfillmentOptions?.available &&
      !fullfillmentOptions?.sisterStoreAvailable &&
      fullfillmentOptions?.availableQty === -2;
  }
  return (
    <ToggleComponent
      cartPage={cartPage}
      inline={inline}
      storeSelected={storeSelected}
      isBopusView={isBopusView}
      applyNegativeMargin={applyNegativeMargin}
      availabilityInfoVO={availabilityInfoVO}
      numberOfSisterStores={numberOfSisterStores}
      labels={useLabels(labelMap)}
      phoneNumber={phoneNumber}
      skuAvailabilityInfo={skuAvailabilityInfo}
      hidePricing={hidePricing}
      {...props}
      isVDPItem={isVDPItem}
    />
  );
};

export default ToggleComponentComp;
