/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import type { CitrusBannerResponse } from '../interface';
import { usePageType } from '@/features/category/api/getPageType';
import { type PageContextType } from '@/next-types';
import { getCitrusShelfData as internalGetCitrusShelfData } from '../utils/apiUtils.server';
import { useFeatureFlag } from '@/features/globalConfig';
import { getDecodedCookies } from '@/utils/cookie';
import { getCitrusCatalogId } from '../utils/getCitrusCatalogId';
import { getCitrusContentStandardId } from '../utils/getCitrusContentStandardId';
import environmentConfig from '@/config/environment';
import { getCitrusProxyConfiguration } from '../lib/getCitrusProxyConfiguration';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';

const URL = `/api/citrus/shelf`;
const PROXY_URL = '/v1/ads/bannerx';

type Options = {
  enabled?: boolean;
  category: string;
};

const getCitrusShelfDataWithProxy = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusShelfKey>>) => {
  const [, , { category }] = queryKey;
  const cookies = getDecodedCookies(['eCookieId', 'rewardsId', 'forceCitrusProd']);
  const rewardsId = cookies.get('rewardsId');
  const eCookieId = cookies.get('eCookieId');
  const forceCitrusProd = !!cookies.get('forceCitrusProd');
  const { baseURL, axiosConfig } = getCitrusProxyConfiguration();

  const response = await getAxiosForClient().post<CitrusBannerResponse>(
    `${baseURL}${PROXY_URL}`,
    {
      customerId: rewardsId || null,
      sessionId: rewardsId || eCookieId,
      catalogId: getCitrusCatalogId(forceCitrusProd),
      contentStandardId: getCitrusContentStandardId(forceCitrusProd),
      placement: 'category-shelf-banner-x',
      options: {
        filterMode: 'AndOr',
      },
      productFilters: [[`category:${category}`]],
      bannerSlotIds: [
        {
          slotId: 'category-shelf-top-banner',
          maxNumberOfAds: 1,
        },
        {
          slotId: 'category-shelf-midpage-banner',
          maxNumberOfAds: 1,
        },
      ],
    },
    axiosConfig
  );
  return selector(response.data);
};

const getCitrusShelfData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusShelfKey>>) => {
  const [, , { category }] = queryKey;
  const params = {
    category,
  };

  const response = await getAxiosForClient().post<CitrusBannerResponse>(URL, params);
  return selector(response.data);
};

const getCitrusSearchDataWithProxy = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusSearchKey>>) => {
  const [, , { category }] = queryKey;
  const cookies = getDecodedCookies(['eCookieId', 'rewardsId', 'forceCitrusProd']);
  const rewardsId = cookies.get('rewardsId');
  const eCookieId = cookies.get('eCookieId');
  const forceCitrusProd = !!cookies.get('forceCitrusProd');
  const { baseURL, axiosConfig } = getCitrusProxyConfiguration();

  const response = await getAxiosForClient().post<CitrusBannerResponse>(
    `${baseURL}${PROXY_URL}`,
    {
      customerId: rewardsId || null,
      sessionId: rewardsId || eCookieId,
      catalogId: getCitrusCatalogId(forceCitrusProd),
      contentStandardId: getCitrusContentStandardId(forceCitrusProd),
      placement: 'search-category-banner-x',
      options: {
        filterMode: 'AndOr',
      },
      productFilters: [[`category:${category}`]],
      bannerSlotIds: [
        {
          slotId: 'in-grid-top-banner',
          maxNumberOfAds: 1,
        },
        {
          slotId: 'in-grid-midpage-banner',
          maxNumberOfAds: 1,
        },
      ],
    },
    axiosConfig
  );
  return selector(response.data);
};

const getCitrusSearchData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCitrusSearchKey>>) => {
  const [, , { category }] = queryKey;
  const params = {
    category,
  };

  const response = await getAxiosForClient().post<CitrusBannerResponse>(URL, params);
  return selector(response.data);
};

const selector = (data: CitrusBannerResponse): CitrusBannerResponse => data;

const getCitrusShelfKey = (options: Options) => ['citrus', 'shelf', options] as const;
const getCitrusSearchKey = (options: Options) => ['citrus', 'search', options] as const;

export const useCitrusShelfData = (options: Omit<Options, 'vehicleId' | 'category'>) => {
  const citrusEnabled = useFeatureFlag('IS_CITRUS_ENABLED') === 'true';
  const { data: pageTypeData } = usePageType();
  const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;

  const category = pageTypeData?.catalogRepositoryName ?? '';

  const queryKey = {
    category: category,
  };
  return useQuery({
    queryKey: getCitrusShelfKey(queryKey),
    staleTime: 30 * 60000,
    queryFn: CITRUS_PROXY_DISABLED ? getCitrusShelfData : getCitrusShelfDataWithProxy,
    enabled: citrusEnabled && !!category && !!(options.enabled ?? true),
  });
};

export const useCitrusSearchPageData = (options: Omit<Options, 'vehicleId' | 'category'>) => {
  const citrusEnabled = useFeatureFlag('IS_CITRUS_ENABLED') === 'true';
  const { data: pageTypeData } = useSearchProductData();
  const CITRUS_PROXY_DISABLED = environmentConfig.CITRUS_PROXY_DISABLED;

  const category = pageTypeData?.records?.[0]?.partTypeName ?? '';

  const queryKey = {
    category: category,
  };

  return useQuery({
    queryKey: getCitrusSearchKey(queryKey),
    staleTime: 30 * 60000,
    queryFn: CITRUS_PROXY_DISABLED ? getCitrusSearchData : getCitrusSearchDataWithProxy,
    enabled: citrusEnabled && !!category && (options.enabled ?? true),
  });
};

export const prefetchCitrusShelfData = (
  queryClient: QueryClient,
  context: PageContextType,
  category: string,
  eCookieId: string
) => {
  return queryClient.prefetchQuery({
    queryKey: getCitrusShelfKey({ category }),
    queryFn: () =>
      internalGetCitrusShelfData({ req: context.req, res: context.res, category, eCookieId }),
  });
};
