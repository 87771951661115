/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { cartConstants } from '@/constants/cart';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';
import { type FulfillmentOption, type FulfillmentGroup } from '@/types/reduxStore/skuDetails';

const getGroupById = (options: FulfillmentGroup[], groupId: number) =>
  options.find((option) => option.groupId === groupId)?.groupFulfillmentOptions ?? [];

const findOptionByTypeId = (options: FulfillmentOption[], typeId: number) =>
  options.find((option) => option.fulfillmentTypeIdOriginal === typeId);

/**
 * Provides relevant information to display fulfillment options in a button-style layout.
 * The array returned is in the same order as the buttons should be displayed.
 * If an option is `undefined`, it means that option was not found and its corresponding button
 * should likely be undefined or hidden.
 *
 * @param {FulfillmentGroup[] | undefined} fulfillmentOptions - Array of fulfillment groups containing options
 * @returns {(FulfillmentOption | undefined)[]} Array containing:
 * - Store pickup option (if it exists): Can guide how to display the store option (e.g., available or not).
 * - One prioritized online delivery option, helping determine the second button:
 *   1. Next Day Delivery
 *   2. VDP DS
 *   3. Standard Online Order
 *   4. Other delivery types
 * - Same day delivery option (if it exists): Useful for calculations or dedicated button display.
 *
 * @note This function provides the relevant information to decide the display of buttons
 *       and assists in calculations. Includes options even if they are not currently available.
 */
export const determineOptionsToDisplayButtons = (
  fulfillmentOptions: FulfillmentGroup[] | undefined
): (FulfillmentOption | undefined)[] => {
  const options = fulfillmentOptions ?? [];

  const storeOptions = getGroupById(options, FULFILLMENT_METHODS.STOREORDER);
  const onlineOptions = getGroupById(options, FULFILLMENT_METHODS.ONLINEORDER);

  const storeOption = storeOptions[0];

  const onlineOptionToDisplay =
    findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.NEXTDAY) || // Prioritized first
    findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.VDPDS) || // Second priority
    findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.ONLINEORDER) || // Third priority
    // Any other online delivery option, excluding Same Day
    onlineOptions.find((opt) => opt.fulfillmentTypeIdOriginal !== FULFILLMENT_METHODS.SAMEDAY);

  const sameDayOption = findOptionByTypeId(onlineOptions, FULFILLMENT_METHODS.SAMEDAY);

  return [storeOption, onlineOptionToDisplay, sameDayOption];
};

/**
 * Determines the index of the selected fulfillment button based on shipping type and fulfillment ID.
 * Returns the index (0-based) of the button that should be selected in the fulfillment options list.
 *
 * @param {string} shippingType - The type of shipping selected (store order or online order)
 * @param {number} fulfillmentId - The ID of the fulfillment method
 * @returns {number} Index of the button that should be selected
 */
export const determineIndexButtonToBeSelected = (shippingType: string, fulfillmentId: number) => {
  const storePickUpShouldBeSelected = shippingType === cartConstants.STOREORDER;
  const homeDeliveryShouldBeSelected =
    shippingType === cartConstants.ONLINEORDER && fulfillmentId !== FULFILLMENT_METHODS.SAMEDAY;
  const sameDayDeliveryShouldBeSelected =
    shippingType === cartConstants.ONLINEORDER && fulfillmentId === FULFILLMENT_METHODS.SAMEDAY;
  const optionsSelectedStates = [
    storePickUpShouldBeSelected,
    homeDeliveryShouldBeSelected,
    sameDayDeliveryShouldBeSelected,
  ];
  return optionsSelectedStates.findIndex((item) => item === true);
};

/**
 * Provides relevant information for displaying fulfillment options in a list-style layout.
 * This function focuses on available options and limits the display to two online delivery options.
 *
 * @param {FulfillmentGroup[] | undefined} fulfillmentOptions - Array of fulfillment groups containing options
 * @returns {(FulfillmentOption | undefined)[]} Array containing:
 * - Store pickup option: Always included and can guide how to display it in the list.
 * - Up to two available online delivery options: Helps determine the order and number of displayed options.
 *
 */
export const determineOptionsToDisplayList = (
  fulfillmentOptions: FulfillmentGroup[] | undefined
): (FulfillmentOption | undefined)[] => {
  const options = fulfillmentOptions ?? [];

  const storeOption = getGroupById(options, FULFILLMENT_METHODS.STOREORDER)[0];

  const availableOnlineOptions = getGroupById(options, FULFILLMENT_METHODS.ONLINEORDER)
    .filter((option) => option.available)
    .slice(0, 2);

  return [storeOption, ...availableOnlineOptions];
};
