/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxios } from '@/lib/axios';
import { type AxiosError, type AxiosInstance } from 'axios';
import {
  type DealConstraintSkuDetailsData,
  type DealConstraintSkuDetailsResponse,
} from '../interface';
import { requestBaseURL } from '@/config/serviceAPI';
import { createQuery } from '@/utils/createReactQuery';
import { isAxiosError } from '@/utils/errorsHandling';

const URL = `${requestBaseURL}/ecomm/b2c/browse/v3/skus`;

type Options = {
  dealId: string;
  parentSku: string;
  constraintSkusList: string[];
  fulfillmentOption: string;
};

const getDealConstraintSkuDetails = async (options: Options, axiosInstance?: AxiosInstance) => {
  const { dealId, parentSku, constraintSkusList, fulfillmentOption } = options;
  const constraintSkuCsv = constraintSkusList.join(',');

  const response = await getAxios(axiosInstance).get<DealConstraintSkuDetailsResponse>(
    `${URL}/${parentSku}/deals/${dealId}/constraint-skus/${constraintSkuCsv}/fulfillment-option/${fulfillmentOption}`
  );

  return selector(response.data);
};

const selector = (data: DealConstraintSkuDetailsResponse): DealConstraintSkuDetailsData => data;

const { useData } = createQuery<DealConstraintSkuDetailsData, Options>(
  'dealConstraintSkuDetails',
  getDealConstraintSkuDetails
);

export const useDealConstraintSkuDetails = ({
  onError,
  ...rest
}: Options & { enabled?: boolean; onError?: (err: AxiosError) => void }) => {
  return useData({
    enabled: rest.constraintSkusList.length > 0,
    onError: (err) => {
      if (isAxiosError(err)) {
        onError?.(err);
      }
    },
    ...rest,
  });
};
