/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import root from 'window-or-global';
import type { Dispatch, ThunkAction, Action } from '../../types';
import fetchMiniCartData from '../../actions/miniCart';
import actionTypes from '../../types/action';
import { eventTypes } from '../../constants/event';
import { cartConstants } from '../../constants/cart';
import { getFulfillmentOptionsByGroup } from '@/utils/itemHelpers/fulfillmentHelpers/getFulfillmentOptionsByGroup';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { type useAddToCart } from '@/features/orders/api/postAddToCart';
import type { QueryClient } from '@tanstack/react-query';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';

const trackAddToCartShelf = (
  addOptions: any,
  analyticsData: any,
  isBopus: boolean,
  productAvailableQuantity?: number,
  fulfillmentTypeid?: number
) => {
  const fulfillmentTypeId = [fulfillmentTypeid];
  const { pageName = '', pageType = '' } = root.utag_data || {};
  const data: any = {
    eventType: 'addToCart',
    productPriceList: [analyticsData.price],
    productRequestedQuantityList: [addOptions.quantity],
    productBrandList: [analyticsData.brandName],
    productIdList: [addOptions.sku_id],
    productFulfillmentTypeList: fulfillmentTypeId,
    originalPartTypeIdList: [analyticsData.originalPartTypeId],
    productRank: !isNaN(analyticsData.prodIndex)
      ? Number(analyticsData.prodIndex) + 1
      : analyticsData.prodIndex,
    productRepositoryIdList: analyticsData.productRepositoryIdList,
    productPartNumberList: [analyticsData.partNumber],
    productPartList: [analyticsData.name],
    customLinkName: analyticsData.customLinkName,
    productRecommendationTypeList: analyticsData.productRecommendationTypeList,
    pageName: pageName,
    pageType: pageType,
    productStrikeThroughPricing: analyticsData.productStrikeThroughPricing,
    productFulfillmentOptionSeenByCustomer: analyticsData.productFulfillmentOptionSeenByCustomer,
  };

  if (productAvailableQuantity !== undefined) {
    data.productAvailableQuantityList = [productAvailableQuantity];
  }

  if (analyticsData.fulfillmentChange !== '' && analyticsData.customLinkName !== '') {
    data.fulfillmentChange = analyticsData.fulfillmentChange;
  }

  if (analyticsData.corePrice !== 0) {
    data.productCorePriceList = [analyticsData.corePrice];
  }

  clickTrack(data);
};

const {
  ADD_TO_CART_SHELF_FAILURE,
  ADD_TO_CART_SHELF_REQUESTING,
  ADD_TO_CART_SUCCESS,
  EMPTY_CART_ITEMS,
  EMPTY_DATA,
  SET_SELECTED_NULL_CARTRIDGE,
  SET_LOADING_FOR_PRODUCT,
  SET_PRODUCT_FILTER_EVENTS,
  CLEAR_PRODUCT_FILTER_EVENTS,
  CLEAR_PRODUCT_FILTER_SELECTION,
  SET_FILTER_SCROLL,
  SET_FILTER_SCROLL_BRAND,
  SET_ITEMS_PER_PAGE_OR_SORT_BY_FOCUS,
} = actionTypes;

const setLoadingForProduct = (skuNumber?: number): { skuNumber: void | number; type: string } => ({
  type: SET_LOADING_FOR_PRODUCT,
  skuNumber,
});

export const addToCart =
  (
    addToCartMutation: ReturnType<typeof useAddToCart>,
    addOptionsParms: any,
    analyticsData: any,
    onSuccess: () => void,
    queryClient: QueryClient,
    searchedTerm?: string,
    xFusionQueryId?: string
  ): ThunkAction =>
  async (dispatch: Dispatch) => {
    try {
      const { storePickupAvailable, ...addOptions } = addOptionsParms;
      const isBopus = addOptions.order_type === cartConstants.STOREORDER && storePickupAvailable;
      const orderType =
        typeof addOptions.order_type === 'number'
          ? addOptions.order_type
          : addOptions.order_type === cartConstants.ONLINEORDER
          ? FULFILLMENT_METHODS.ONLINEORDER
          : FULFILLMENT_METHODS.STOREORDER;
      const fulfillmentTypeid = addOptions.fulfillmentId;
      dispatch(setLoadingForProduct(addOptions.sku_id));
      dispatch({ type: ADD_TO_CART_SHELF_REQUESTING });
      // format request type for v2 fulfillment
      const orderV2Request = {
        skuId: addOptions.sku_id,
        productId: addOptions.product_id,
        quantity: addOptions.quantity,
        fulfillmentTypeId: fulfillmentTypeid ?? orderType,
        vehicleId: addOptions.catalog_vehicle_id,
        storeNumber: addOptions.store_number,
        searchedTerm,
        xFusionQueryId,
      };
      const data = await addToCartMutation.mutateAsync(orderV2Request);
      onSuccess?.();
      const availabilityInfo = getFulfillmentOptionsByGroup(
        data.lineItemFulfillmentOptions.find((items: any) => items.skuId === addOptions.sku_id)
          ?.fulfillmentOptions
      );
      dispatch({
        type: ADD_TO_CART_SUCCESS,
        data: {
          ...data,
          availabilityInfo,
          orderType: addOptions.order_type,
          quantity: addOptions.quantity,
          skuId: addOptions.sku_id,
          productId: addOptions.product_id,
        },
      });

      let productAvailableQuantity;
      productAvailableQuantity =
        availabilityInfo.find((option) => option.selected)?.maxAvailableQty ?? 0;
      if (analyticsData) {
        trackAddToCartShelf(
          addOptions,
          analyticsData,
          isBopus,
          productAvailableQuantity,
          fulfillmentTypeid
        );
      }

      dispatch(fetchMiniCartData(queryClient));
    } catch (err: any) {
      dispatch({
        type: ADD_TO_CART_SHELF_FAILURE,
        err: err.response.data.title,
        errorKey: err.response.data.errorKey,
        errorMessageDefault: err.response.data.errorMessageDefault,
        errorSku: addOptionsParms.sku_id,
        errorLog: err,
        quantity: addOptionsParms.quantity,
        orderType: addOptionsParms.order_type,
      });
    } finally {
      dispatch(setLoadingForProduct());
    }
  };

export const removeDataPart = (): Action => ({ type: EMPTY_DATA });

export const removeDataFromPage = (): Action => ({ type: EMPTY_CART_ITEMS });

export const setSelectedNullCartridge = (value: string): Action => ({
  type: SET_SELECTED_NULL_CARTRIDGE,
  value,
});

export const setProductFilterEvents = (
  filterName: string,
  filterId: string,
  checked: boolean,
  quickFilter?: boolean
): Action => {
  const eventType = checked ? eventTypes.applyFilter : eventTypes.removeFilter;
  const selectedFilterValue =
    quickFilter && !filterName.includes('Quick Filter')
      ? `${eventTypes.quickFilterEvent}: ${filterName}`
      : filterName;

  return {
    type: SET_PRODUCT_FILTER_EVENTS,
    filterScroll: quickFilter,
    selectedFilterValue,
    filterId,
    eventType,
    checked,
  };
};

export const clearProductFilterEvents = (): Action => {
  return {
    type: CLEAR_PRODUCT_FILTER_EVENTS,
  };
};

export const clearProductFilterSelection = (): Action => {
  return {
    type: CLEAR_PRODUCT_FILTER_SELECTION,
  };
};

export const setFilterScroll = (value: boolean) => {
  return { type: SET_FILTER_SCROLL, value };
};

export const setFilterScrollBrand = (value: boolean) => {
  return { type: SET_FILTER_SCROLL_BRAND, value };
};

export const setSortByOrItemsPerPageFocus = (focusSortBy: boolean, focusItemsPerPage: boolean) => {
  return { type: SET_ITEMS_PER_PAGE_OR_SORT_BY_FOCUS, focusSortBy, focusItemsPerPage };
};
