/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type MutableRefObject, useEffect, useState } from 'react';
import { useFilterAndRedirect } from '@/hooks/useFilterAndRedirect';
import FilterDataRefinement from '../FilterDataRefinement';
import { type FilterDataType, type HandleFilterType } from '@/types/filterTypes';
import styles from './styles.module.scss';
import filterStyles from '../QuickFilter/sharedStyles.module.scss';
import { Dropdown } from '@az/starc-ui';

type QuickFiltersDesktopProps = {
  quickFilter: FilterDataType;
  onFilterSelect: ((a: HandleFilterType) => void) | undefined;
  pageType: string;
  index: number;
  shelfSelectedFilters?: HandleFilterType[];
  oneClickFilterRefs?: MutableRefObject<MutableRefObject<HTMLLIElement>[]>;
  oneClickFiltersLength: number;
};

const getSelectedFilterCount = (
  quickFilter: FilterDataType,
  shelfSelectedFilters: HandleFilterType[] | undefined
): number => {
  if (!shelfSelectedFilters) {
    return 0;
  }

  return shelfSelectedFilters.filter(
    (filter) => filter.displayName === quickFilter.displayName && filter.checked === true
  ).length;
};

export const QuickFiltersDesktop = ({
  quickFilter,
  onFilterSelect,
  pageType,
  index,
  shelfSelectedFilters,
  oneClickFilterRefs,
  oneClickFiltersLength,
}: QuickFiltersDesktopProps) => {
  const [open, setOpen] = useState(false);
  const quickFilterLength = getSelectedFilterCount(quickFilter, shelfSelectedFilters);
  const quickFilterName =
    quickFilterLength > 0
      ? `${quickFilter.displayName} (${quickFilterLength})`
      : `${quickFilter.displayName}`;
  const handleFilterAndRedirect = useFilterAndRedirect();

  const handleFilter = (filter: HandleFilterType) => {
    setOpen(false);
    handleFilterAndRedirect(filter, onFilterSelect);
  };
  const quickFilterRef = oneClickFilterRefs?.current[oneClickFiltersLength + index + 1];

  useEffect(() => {
    if (!quickFilterRef) {
      return;
    }
    const objectObserver = new IntersectionObserver(
      ([entry]) => {
        entry.target.setAttribute(
          'data-testid',
          entry.isIntersecting
            ? `${quickFilter.displayName}-quick-chip-visible`
            : `${quickFilter.displayName}-quick-chip-hidden`
        );
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (quickFilterRef.current) {
      objectObserver.observe(quickFilterRef.current);
    }

    return () => {
      objectObserver.disconnect();
    };
  }, [quickFilter.displayName, quickFilterRef]);

  return (
    <li ref={quickFilterRef}>
      <Dropdown
        width={343}
        placement="bottom-end"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <Dropdown.Button className={filterStyles.dropdownButton}>
          <span className={filterStyles.dropdownButtonText}>{quickFilterName}</span>
        </Dropdown.Button>
        <Dropdown.Content className={filterStyles.dropdownContent}>
          <FilterDataRefinement
            classes={{
              root: styles.quickFilterRefinementRoot,
            }}
            filterItem={{ ...quickFilter, quickFilter: true }}
            handleFilter={handleFilter}
            pageType={pageType}
          />
        </Dropdown.Content>
      </Dropdown>
    </li>
  );
};
