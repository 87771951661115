/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type AxiosInstance } from 'axios';
import { useRouter } from 'next/router';
import { requestBaseURL } from '@/config/serviceAPI';
import type {
  PartTypesSearchResponseModel,
  SearchRequestModel,
} from '@/api/types/browse-search-types';
import { getAxios } from '@/lib/axios';
import { getCountryFromLocale } from '@/utils/getCountryFromLocale';
import { createQuery } from '@/utils/createReactQuery';
import { useLocale } from '@/hooks/useLocale';
import type { Locale } from '@/types/i18n';
import { useMapFacet } from '@/hooks/useMapFacet';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { getApiOptions } from '../utils/getSearchApiOptions';
import { type QueryClient } from '@tanstack/react-query';
import { showXMPreviewDate } from '@/utils/showXMPreviewDate';
import { type MappedSearchPartTypes } from '../interface';
import { useGlobalState } from '@/hooks/useGlobalState';
import { countryCodes } from '@/constants/locale';
import { routePaths } from '@/constants/routePaths';

const PRODUCTS_URL = `${requestBaseURL}/external/product-discovery/browse-search/v1/part-types/search`;

type GetSearchPartTypesOptions = {
  mapFacet: ReturnType<typeof useMapFacet>;
  locale: Locale;
  facet: string | undefined;
  pageNumber: number | undefined;
  recordsPerPage: number | undefined;
  searchText: string | undefined;
  sort:
    | {
        sortFieldName: string | undefined;
        sortOrder: string | undefined;
      }
    | undefined;
  storeId: string | undefined;
  userSegment: string | undefined;
  vehicleId: string | undefined;
  skipPdpRedirect: boolean;
  preview: boolean;
  userProfileId: string | undefined;
};

const {
  useData: useSearchParts,
  prefetch: prefetchSearchParts,
  query: partsQuery,
} = createQuery<MappedSearchPartTypes, GetSearchPartTypesOptions>(
  'searchPartTypes',
  async (options, axiosInstance) => getSearchPartTypes(options, axiosInstance)
);

const searchPartTypesSelector = (
  { searchResults, redirectUrl, oemBrandName, oemPartNumber }: PartTypesSearchResponseModel,
  mapFacet: ReturnType<typeof useMapFacet>
): MappedSearchPartTypes => {
  return {
    redirectLocation: redirectUrl,
    oemBrandName,
    oemPartNumber,
    recordsPerPage: searchResults?.recordsPerPage,
    total: searchResults?.totalNumberOfRecords,
    firstRecNum: searchResults?.firstRecordNumber,
    correctedTerm: searchResults?.correctedTerm,
    lastRecNum: searchResults?.lastRecordNumber,
    staticNavigation: searchResults?.facets?.map(mapFacet),
    searchedKeyword: searchResults?.searchedKeyword,
    records: searchResults?.skuRecords?.map((record, i) => ({
      searchResultPosition: (searchResults?.firstRecordNumber ?? 0) + i,
      propertyMap: {
        P_ProductRepositoryId: [record.eCommerceProductId ?? ''],
        P_ItemId: [record.itemId ?? ''],
        P_ProductDisplayName: [record.partTypeName ?? ''],
        P_ProductName: [record.partTypeName ?? ''],
        P_ProductImageUrl: [record.productImageUrl ?? ''],
        P_ProductUrl: [record.productCanonicalUrl ?? ''],
        originalPartTypeId: [record.eCommerceProductId ?? ''],
      },
    })),
  };
};

const getSearchPartTypes = async (
  options: GetSearchPartTypesOptions,
  axiosInstance?: AxiosInstance
) => {
  const { mapFacet, locale, searchText, userProfileId, ...rest } = options;
  const country = getCountryFromLocale(locale);
  const customerType = 'B2C';
  const salesChannel = 'ECOMM';

  if (!searchText) {
    throw new Error('searchText is required');
  }

  const reqData: SearchRequestModel = {
    ...rest,
    country,
    customerType,
    salesChannel,
    searchText,
    ...(!!options.facet &&
      options.facet.includes('vehicle_fitment') && {
        ignoreVehicleSpecificProductsCheck: true,
      }),
  };

  const response = await getAxios(axiosInstance).post<PartTypesSearchResponseModel>(
    PRODUCTS_URL,
    reqData,
    { headers: { 'User-Profile-Id': userProfileId } }
  );

  return searchPartTypesSelector(response.data, mapFacet);
};

export const useSearchPartTypes = (
  { enabled, viewingPartTypes }: { enabled: boolean; viewingPartTypes?: boolean } = {
    enabled: true,
    viewingPartTypes: false,
  }
) => {
  const locale = useLocale();
  const router = useRouter();
  const mapFacet = useMapFacet();
  const preferredVehicle = usePreferredVehicle();
  const vehicleId = preferredVehicle?.catalogVehicleId;
  const [searchState] = useGlobalState('search');
  const { data: storeDetailsData } = useStoreDetailsData();
  const storeNumber = storeDetailsData?.storeNumber;
  const { data: headerData } = useHeaderData();
  const userProfileId = headerData?.myAccountMap?.profileId;
  const options = getApiOptions({
    router,
    locale,
    vehicleId,
    vehicleIdChanged: searchState?.preferredVehicleChanged,
    storeNumber,
    mapFacet,
    viewingPartTypes,
    isCurrentPage: true,
    preview: showXMPreviewDate(),
    userProfileId,
  });

  return useSearchParts({
    ...options,
    pageNumber: typeof router.query.currentPage === 'string' ? Number(router.query.currentPage) : 1,
    recordsPerPage:
      typeof router.query.recsPerPage === 'string' ? Number(router.query.recsPerPage) : undefined,
    skipPdpRedirect: options.skipPdpRedirect ?? false,
    enabled: Boolean(
      enabled &&
        router.route === routePaths.searchResult &&
        router.query.searchText &&
        typeof router.query.searchText === 'string' &&
        router.query.searchText !== '' &&
        (storeNumber || locale === countryCodes.mx || locale === countryCodes.ptBr)
    ),
  });
};

export const getSearchPartTypesFromCache = (
  queryClient: QueryClient,
  options: GetSearchPartTypesOptions
) => {
  return queryClient.getQueryData<MappedSearchPartTypes>(partsQuery.getFullKey(options));
};

export const prefetchSearchPartTypes = async (
  queryClient: QueryClient,
  options: GetSearchPartTypesOptions,
  axiosInstance: AxiosInstance
) => {
  await prefetchSearchParts(queryClient, options, axiosInstance);
};
