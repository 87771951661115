/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type RefObject, useState, useRef, useEffect } from 'react';

export default function useAreElementsVisible(refs: RefObject<HTMLElement>[] | undefined) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
    refs?.forEach((ref) => {
      if (ref?.current) {
        observerRef.current?.observe(ref.current);
      }
    });

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [refs]);

  return isOnScreen;
}
