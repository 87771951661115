/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { useGlobalConfig } from '@/features/globalConfig';
import { useHeaderData } from '@/features/header/api/getHeader';
import { getPreferredVehicle } from '@/features/header/utils/getPreferredVehicle';
import { getAxios } from '@/lib/axios';
import { useRouter } from 'next/router';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import { parseUrl, sanitizeUrlWithParamList, stringifyUrl } from '@/utils/urlHelpers';
import { type AxiosInstance } from 'axios';
import { type QueryClient } from '@tanstack/react-query';
import { acceptableBrandQueryParams } from '../constants';
import type { BrandData, BrandOptions, BrandResponse } from '../interface';
import { createQuery } from '@/utils/createReactQuery';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const URL = `${requestBaseURL}/ecomm/b2c/v2/browse/brands`;

const selector = (data: BrandResponse): BrandData => data;

const getBrand = async (options: BrandOptions, axiosInstance?: AxiosInstance) => {
  const { vehicleId = '0', path, workspaceId, previewDate } = options;

  const parsedUrl = parseUrl(path);

  if (workspaceId) {
    parsedUrl.query.workspaceId = workspaceId;
  }
  if (previewDate) {
    parsedUrl.query.previewDate = previewDate;
  }

  if (vehicleId) {
    parsedUrl.query.vehicleId = vehicleId;
  }

  const brandName = parsedUrl.url.split('/').pop();

  if (!brandName) {
    throw new Error('Brand name is required.');
  }

  const completeURL = sanitizeUrlWithParamList(
    parsedUrl.query,
    acceptableBrandQueryParams,
    `${URL}/${brandName}`
  );

  const response = await getAxios(axiosInstance).get<BrandResponse>(completeURL);

  return selector(response.data);
};

// Set vehicledId='0' to persist cache between server and client
const sanitizeBrandOptions = ({ path, vehicleId = '0', ...rest }: BrandOptions): BrandOptions => {
  const parsedUrl = parseUrl(path);

  for (const param in parsedUrl.query) {
    if (!(acceptableBrandQueryParams as Record<string, boolean>)[param]) {
      delete parsedUrl.query[param];
    }
  }

  return {
    path: stringifyUrl(parsedUrl),
    vehicleId,
    ...rest,
  };
};

const {
  useData: useBrandData,
  prefetch: prefetchBrandData,
  query: brandQuery,
} = createQuery<BrandData, BrandOptions>('brand', getBrand);

export const useBrand = ({ enabled }: { enabled: boolean } = { enabled: false }) => {
  const router = useRouter();
  const { workspaceId, previewDate } = useXMPreviewState();
  const headerResult = useHeaderData();
  const preferredVehicle = getPreferredVehicle(headerResult.data);
  const cmsBrandShelfEnabled = useMonetateDecisionFlag('cmsBrandShelfPageEnabled');

  const configResult = useGlobalConfig();
  return useBrandData({
    ...sanitizeBrandOptions({
      workspaceId,
      previewDate,
      path: router.asPath,
      vehicleId: preferredVehicle?.catalogVehicleId,
      storeNumber: headerResult.data?.storeNumber,
    }),
    enabled: enabled && configResult.isSuccess && headerResult.isSuccess && !cmsBrandShelfEnabled,
    staleTime: Infinity,
  });
};

export const getBrandFromCache = (queryClient: QueryClient, options: BrandOptions) => {
  return queryClient.getQueryData<BrandData>(brandQuery.getFullKey(sanitizeBrandOptions(options)));
};

export const prefetchBrand = (
  queryClient: QueryClient,
  options: BrandOptions,
  axiosInstance: AxiosInstance
) => {
  return prefetchBrandData(queryClient, sanitizeBrandOptions(options), axiosInstance);
};
