/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import {
  type CategoryData,
  type CategoryDataContentItem,
  type ProductShelfResponse,
} from '../interface';
import { getMapFacet } from '@/hooks/useMapFacet';
import type { ShelfLeftNav } from '@/types/legacy';

const EMPTY_CATEGORY_PRODUCTS_DATA = {
  categoryImageUrl: '',
  makeorModelName: null,
  categoryData: undefined,
  description: '',
  displayName: '',
  seoCopyBlock: '',
  seoBottomCopy: '',
  seoTopCopy: null,
  pageType: undefined,
  yearBasedCategories: null,
  seoMetaData: null,
  modelNames: null,
  summary: null,
  catLabel: null,
  productId: null,
  shopByUrlMake: null,
  shopByUrlModel: null,
  shopByUrlYear: null,
  yearRange: null,
  categoryMake: null,
  makeModelYearPageType: null,
  shortDescription: null,
  shopByUrlSuffix: null,
  makeNames: null,
  categoryId: null,
  hierarchyLocation: null,
  redirect: false,
  shopByPageType: null,
  makeModelYearPath: undefined,
  topContentSeoActive: false,
  categoryModel: null,
  location: null,
};

export const mapMerchShelfData = (productShelfData: ProductShelfResponse): CategoryData => {
  const rppOptions = undefined;

  const blankCategoryDataContentItem = [] as CategoryDataContentItem[];
  return {
    ...productShelfData,
    ...EMPTY_CATEGORY_PRODUCTS_DATA,
    breadCrumbsList:
      productShelfData.productShelfResults?.breadCrumbs?.map((item) => ({
        pageType: item.pageType,
        displayName: item.displayName,
        url: item.canonicalPath,
      })) ?? [],
    contents: {
      'endeca:contentPath': '',
      '@type': '',
      templateTypes: [],
      name: '',
      contentPaths: [],
      redirectGroup: '',
      contents: [
        {
          '@type': 'Shelf',
          includeInSiteMap: 'true',
          name: 'Shelf Page',
          categoryTippyTop: blankCategoryDataContentItem,
          categoryPageBody: blankCategoryDataContentItem,
          categoryResultHeader: blankCategoryDataContentItem,
          categoryResultBottom: blankCategoryDataContentItem,
          categoryHead: blankCategoryDataContentItem,
          isBrandPremiumPage: false,
          shelfTippyTop: [{ '@type': '' }],
          shelfLeftNav: [
            {
              '@type': 'ShelfLeftNav_FilterNav',
              staticNavigation:
                productShelfData.productShelfResults?.facets?.map((facet) =>
                  getMapFacet(facet, 'Clearance')
                ) ?? [],
              name: '',
            },
            {},
            {},
          ] as ShelfLeftNav,
          shelfHead: [],
          shelfResultBottom: [
            { '@type': '', contentPaths: [], contents: [], name: '', templateTypes: [] },
            {
              '@type': '',
              desktop: false,
              mmEnabled: false,
              mobile: false,
              name: '',
              seoCopyBlock: '',
              seoTextMM: '',
              seoTextYMM: '',
              tablet: false,
              ymmEnabled: false,
            },
          ],
          shelfResultHeader: [],
          shelfPageBody: [
            {
              '@type': 'ShelfPageBody_ResultsList',
              name: 'Results List',
              tablet: true,
              desktop: true,
              mobile: true,
              disableRecordsPerPage: false,
              headerText: productShelfData.productShelfResults?.pageDisplayName ?? '',
              recsPerPage: productShelfData.productShelfResults?.recordsPerPage ?? 0,
              totalNumRecs: productShelfData.productShelfResults?.totalNumberOfRecords ?? 0,
              firstRecNum: productShelfData.productShelfResults?.firstRecordNumber ?? 0,
              lastRecNum: productShelfData.productShelfResults?.lastRecordNumber ?? 0,
              rppOptions,
              records: productShelfData.productShelfResults?.skuRecords?.map((item) => {
                return {
                  active: item.activeFlag,
                  productReviewsEnabled: item.reviewEnabledFlag,
                  techNote: item.technicalNote,
                  brand: item.brandName ?? '',
                  description: item.itemDescription,
                  locationFilter: item.itemLocation,
                  seoUrl: item.productDetailsPageUrl,
                  configurableSku: item.configurableSkuFlag,
                  productImageUrl: item.itemImageUrl ?? '',
                  imageUrl: item.itemImageUrl ?? '',
                  normalizedProductCanonicalUrl: item.productCanonicalUrl,
                  productSpecs: item.productSpecification,
                  name: item.itemDescription,
                  techNotes: item.applicationQuestions,
                  vehicleSpecific: item.vehicleSpecificFlag,
                  vehicleFit: !!item.vehicleFitmentFlag,
                  skuNumber: Number(item.itemId),
                  ...item,
                  partTypeId: item.originalPartTypeId ?? '',
                  originalPartTypeId: item.originalPartTypeId ?? '',
                  systemCode:
                    typeof item.originalSystemCode === 'string'
                      ? parseInt(item.originalSystemCode)
                      : item.originalSystemCode,
                  originalSystemCode:
                    typeof item.originalSystemCode === 'string'
                      ? parseInt(item.originalSystemCode)
                      : item.originalSystemCode,
                };
              }),
            },
          ],
        },
      ],
    },
  };
};
