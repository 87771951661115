/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Grid } from '@/components/Grid';
import azCommonStyles from '@/theme/globals.module.scss';
import React from 'react';
import { type ProductPotentialDeal } from '../../interface';
import styles from './styles.module.scss';

type Props = {
  itemVal: ProductPotentialDeal;
  staticText: string;
  systemVal: string;
};

export function PDPDeals(props: Props) {
  const dealName = props.itemVal.dealLongName ? props.itemVal.dealLongName : props.itemVal.dealName;
  const desc = props.itemVal.dealLongDesc ? props.itemVal.dealLongDesc : props.itemVal.description;
  return (
    <Grid item lg={4} xl={4} md={6} sm={12} xs={12}>
      <div className={styles.productDeal}>
        <Grid item xs={12} className={styles.productHeader}>
          <span className={azCommonStyles['az-title-5-regular']}>{dealName}</span>
        </Grid>
        <div className={styles.productInfo}>
          <Grid item xl={4} lg={4} className={styles.productImg}>
            <img src={props.itemVal.dealImageUrl} alt="deal img" />
          </Grid>
          <Grid item xl={8} lg={8} className={azCommonStyles['az-body-2-regular']}>
            <span className={styles.productDescription}>{desc}</span>
            <div className={styles.expiresData}>
              {props.staticText} {props.itemVal.end}
            </div>
          </Grid>
        </div>
      </div>
    </Grid>
  );
}
