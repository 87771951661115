/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import { type FormattedContentStackSeoFacetedBuilder } from '@/features/contentstack/interface/models';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { usePageType } from '@/features/category/api/getPageType';
import { type PageContextType } from '@/next-types';
import { getSeoFacetedContent } from '../../contentstack/lib/seoData/getContentStackFacetedSeoContent';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const URL = `/api/contentstack/seoContentFaceted`;
const getContentStackFacetedSeoData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCSSeoFacetedContentKey>>) => {
  const [, , { canonicalPath, botEnabledFacetPath }] = queryKey;
  const facetedPath = canonicalPath + botEnabledFacetPath;
  const { data } = await getAxiosForClient().get<FormattedContentStackSeoFacetedBuilder>(URL, {
    params: { facetedPath },
  });

  return data;
};

const getCSSeoFacetedContentKey = ({
  canonicalPath,
  botEnabledFacetPath,
}: {
  canonicalPath: string;
  botEnabledFacetPath: string;
}) => ['cms', 'facetedSEOBuilder', { canonicalPath, botEnabledFacetPath }] as const;

export const useContentStackFacetedSeoData = ({ enabled = true }: { enabled?: boolean }) => {
  const { data: pageTypeData } = usePageType();
  const isCMSSeoFacetedContentEnabled = useMonetateDecisionFlag('cmsSeoFacetedContentEnabled');
  return useQuery({
    queryKey: getCSSeoFacetedContentKey({
      canonicalPath: pageTypeData?.canonicalPath ?? '',
      botEnabledFacetPath: pageTypeData?.botEnabledFacetPath ?? '',
    }),
    queryFn: getContentStackFacetedSeoData,
    enabled: enabled && isCMSSeoFacetedContentEnabled,
  });
};

export const prefetchContentStackFacetedSeoData = async ({
  queryClient,
  context,
  canonicalPath,
  botEnabledFacetPath,
}: {
  queryClient: QueryClient;
  context: PageContextType;
  canonicalPath: string;
  botEnabledFacetPath: string;
}) => {
  return queryClient.prefetchQuery({
    queryKey: getCSSeoFacetedContentKey({ canonicalPath, botEnabledFacetPath }),
    queryFn: () =>
      getSeoFacetedContent(context.req, {
        ...context.query,
        facetedPath: canonicalPath + botEnabledFacetPath,
      }),
  });
};
