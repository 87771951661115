/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect, useState, type RefObject } from 'react';

type RefHTMLElementDimensions = {
  width: number;
  height: number;
};

const useRefDimensions = (ref?: RefObject<HTMLElement>): RefHTMLElementDimensions => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref?.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width, height } = boundingRect;
      setWidth(width);
      setHeight(height);
    }
  }, [ref]);

  return { width: width, height: height };
};

export default useRefDimensions;
