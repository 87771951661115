/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import type {
  DealConstraint,
  DealConstraintSkuDetails,
  DealResponse,
  SkuDetails,
  ConstraintSelectionHash,
} from '../../../interface';
import styles from './styles.module.scss';
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import { getConstraintData } from './helpers/getConstraintData';
import ParentConstraint from './ParentConstraint';
import SecondaryConstraints from './SecondaryConstraints';
import { type detailsLabelMap } from './helpers/detailsLabelMap';

type Props = {
  loadError: string | null | undefined;
  addToCartError: string | null | undefined;
  dealDetails: DealResponse | undefined;
  constraintSelections: ConstraintSelectionHash | null;
  parentSkuDetails: SkuDetails;
  onConstraintSwapClick: (
    constraint: DealConstraint,
    constraintSkuDetails: DealConstraintSkuDetails
  ) => void;
  dealId: string;
  labels: typeof detailsLabelMap;
};
const ConstraintDetails = ({
  loadError,
  addToCartError,
  dealDetails,
  constraintSelections,
  parentSkuDetails,
  onConstraintSwapClick,
  dealId,
  labels,
}: Props) => {
  if (loadError) {
    return (
      <div className={styles.bundleDetailsBody}>
        <div className={styles.errorContainer}>
          <NotificationBlock type="error" message={loadError} />
        </div>
      </div>
    );
  }

  if (!dealDetails || !constraintSelections) {
    return (
      <div className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  const { constraints } = dealDetails;
  const { parentConstraint, parentConstraintSkuDetails, secondaryConstraints } = getConstraintData(
    constraints,
    constraintSelections,
    parentSkuDetails
  );

  if (!parentConstraint || !parentConstraintSkuDetails) {
    return null;
  }

  return (
    <div className={styles.bundleDetailsBody}>
      {!!addToCartError && (
        <div className={styles.errorContainer}>
          <NotificationBlock type="error" message={addToCartError} />
        </div>
      )}
      <ParentConstraint
        onConstraintSwapClick={onConstraintSwapClick}
        parentConstraint={parentConstraint}
        parentConstraintSkuDetails={parentConstraintSkuDetails}
        label={labels.label_deal_bundle_qualifying_item}
      />
      {Boolean(secondaryConstraints.length) && (
        <SecondaryConstraints
          secondaryConstraints={secondaryConstraints}
          constraintSelections={constraintSelections}
          onConstraintSwapClick={onConstraintSwapClick}
          dealId={dealId}
          lblCompleteDeal={labels.label_deal_bundle_complete_your_deal}
          lblCompleteDesc={labels.label_deal_bundle_complete_your_deal_description}
        />
      )}
    </div>
  );
};

export default ConstraintDetails;
