/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useRef, useState, useReducer, createRef } from 'react';
import Script from 'next/script';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Grid } from '@/components/Grid';
import { defaultStoreUS, countryCodes } from '@/constants/locale';
import { productInfoConstants } from '@/constants/productInfoConstants';
import usePrevious from '@/utils/usePrevious';
import type { ReduxState } from '@/types';
import PaginationComp from '@/components/AZCustomComponent/Pagination/Pagination';
import { useRouter } from 'next/router';
import { getPathnameFromAsPath } from '@/utils/urlHelpers';
import { isBrandShelfPage } from '@/utils/isBrandShelfPage';
import { useProductList } from '../../../../context/useProductList';
import { YMMEModal } from '@/features/ymme/components/YMMEModal';
import style from '@/components/PageTemplates/Generic/styles.module.scss';
import { NoPartFound } from './NoPartFound';
import styles from './viewStyles.module.scss';
import { ShelfProduct } from './Product';
import type { Locale } from '@/types/i18n';
import { useLabel } from '@/hooks/useLabels';
import { FeatureFlag, useFeatureFlag } from '@/features/globalConfig';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import type { ShelfPart } from '@/types/legacy';
import { useRedirectAfterVehicleChanged } from '@/features/category/hooks/useRedirectAfterVehicleChanged';
import useRefDimensions from '@/hooks/useRefDimensions';
import { useProductListView } from '../../../../context/useProductListView';
import { useIsMobileQRExperience } from '@/hooks/useIsMobileQRExperience';
import { WarrantyModal } from '@/components/ProductDetail/ProductWarranty';
import useAreElementsVisible from '@/hooks/useAreElementsVisible';
import { useIs24ProductViewEnabled } from '@/hooks/useIs24ProductViewEnabled';
import { CMSCitrusShelf } from '@/features/contentstack/components/CMSCitrus/CMSCitrusShelf';
import { useSeoMetadata } from '@/features/common/api/getSeoMetadata';
import {
  useCitrusShelfData,
  useCitrusSearchPageData,
} from '@/features/citrus/api/getCitrusShelfData';
import { usePageType as usePageTypeLegacy } from '@/hooks/usePageType';
import { usePageType } from '@/features/category/api/getPageType';
import { LoadMoreResults } from '@/components/AZCustomComponent/LoadMoreResults';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { LoadingIndicator } from '@/components/AZCustomComponent/LoadingIndicator';
import { useCombinedInfiniteQuery } from '@/hooks/useCombinedInfiniteQuery';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';
import { sessionStorage } from '@/utils/sessionStorage';

export type Props = {
  recsPerPage: number;
  firstRecNum: number;
  totalNumRecs: number;
  locale: Locale | undefined;
  isPaginationEnabled: boolean;
  handleDealClick?: (
    part: ShelfPart,
    price: string,
    quantity: number,
    ref: React.RefObject<HTMLButtonElement>,
    fulfillmentTypeId: number | null
  ) => void;
  xFusionQueryId?: string;
  resultsListRef?: React.RefObject<HTMLDivElement>;
};

type WarrantyModal = {
  isShown: boolean;
  showModalFrom?: string;
  warrantyType?: string;
};

export const ResultsList = ({
  recsPerPage,
  firstRecNum,
  totalNumRecs,
  locale,
  handleDealClick,
  isPaginationEnabled,
  xFusionQueryId,
  resultsListRef,
}: Props) => {
  const { pageType } = usePageTypeLegacy();
  const matchesTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const router = useRouter();
  const { query } = router;
  const isLoadMoreEnabled = useMonetateDecisionFlag('loadMoreEnabled');
  const gcpUrlEnabled = useFeatureFlag('SEO_GCP_URL_ENABLED') === 'true';

  const { listViewState } = useProductListView();
  const isListView = isBrandShelfPage(router.asPath)
    ? listViewState.isBrandListView
    : listViewState.isListView;
  const [warrantyModal, setWarrantyModal] = useState<WarrantyModal>({
    isShown: false,
  });
  const shelfReadyStatus = useSelector(({ shelf }: ReduxState) => shelf.readyStatus);
  const redirectAfterVehicleChanged = useRedirectAfterVehicleChanged();
  const {
    data: shelfData,
    setShouldFetchSecondaryProductSkus,
    loadMoreClicked,
    status,
    isFetching,
    additionalProductSkuDetailsIsFetching,
  } = useProductList();
  const {
    infiniteQueryStatus,
    hasNextPage,
    fetchNextPage,
    infiniteQueryIsloading,
    totalRecords,
    currentlyShowing,
  } = useCombinedInfiniteQuery();
  const loadingStatus =
    status === 'loading' ||
    additionalProductSkuDetailsIsFetching ||
    isFetching ||
    infiniteQueryStatus === 'loading' ||
    infiniteQueryIsloading;
  const [hasFetchedSecondarySkus, setHasFetchedSecondarySkus] = useState(false);
  const parts = shelfData?.shelfParts;
  const { data: headerData } = useHeaderData();
  const miniCartMap = headerData?.miniCartMap;
  const miniCartItemCount = miniCartMap?.itemCount || 0;
  const previousMiniCartItemCount = usePrevious(miniCartItemCount);
  const sponsoredProductsLength = parts?.filter((part) => part.isSponsoredCard).length ?? 0;
  const totalNumRecords = (totalRecords ?? 0) + sponsoredProductsLength;
  const isSearchPage = router.pathname.includes('/searchresult');
  const isSYMMPage = shelfData?.makeModelYearPageType;
  const { data: storeDetails } = useStoreDetailsData();

  const { data: pageTypeData } = usePageType();
  const { data: seoMetadataInfo } = useSeoMetadata({
    enabled: true,
    vehicleMake: pageTypeData?.make ?? undefined,
    vehicleModel: pageTypeData?.model ?? undefined,
    vehicleYear: pageTypeData?.year ?? undefined,
    gcpUrlEnabled,
  });
  const preferredVehicle = usePreferredVehicle();
  const prevVehicleId = usePrevious(preferredVehicle?.catalogVehicleId);
  const prevStoreNumber = sessionStorage.getItem('previousStore');
  const storeNumber =
    locale === countryCodes.us
      ? storeDetails?.storeNumber || defaultStoreUS
      : storeDetails?.storeNumber;

  const vehicleId = headerData?.vehicleMap.vehicleId;
  const hasVehicle = Boolean(vehicleId);
  const vehicleName = headerData?.vehicleMap.vehicleName ?? '';
  const { setPageSpecificData } = usePageSpecificData();
  const shelfCatLabel =
    typeof router.query.searchText === 'string'
      ? router.query.searchText
      : seoMetadataInfo?.pageHeadingName ?? shelfData?.shelfCatLabel;

  const brandNameShelf = shelfData?.brandName;

  const handleCloseWarrantyModal = () => setWarrantyModal({ isShown: false });

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const previousWindowWidth = usePrevious(windowWidth);
  const shelfGridDimensions = useRefDimensions(resultsListRef);
  const [resultListHeight, setResultListHeight] = useState(shelfGridDimensions.height);
  const previousResultListHeight = usePrevious(resultListHeight);
  const [showYmme, toggleShowYmme] = useReducer((show: boolean) => !show, false);
  const [resizedGridContents, setResizedGridContents] = useState(false);
  const shelfItemRefs = useRef([]);
  const isElementVisible = useAreElementsVisible(shelfItemRefs.current.slice(5));
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const setElementHeight = (el: HTMLElement | null | undefined, newHeight?: number) => {
    if (newHeight && el) {
      el.style.minHeight = `${newHeight}px`;
    } else if (el) {
      el.style.minHeight = 'auto';
    }
  };
  const [significantChange, setSignificantChange] = useState(false);

  useEffect(() => {
    if (significantChange && router.query.loadMore && !isLoadingMore) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { loadMore, ...restQuery } = router.query;
      void router.push(
        {
          pathname: router.pathname,
          query: restQuery,
        },
        undefined,
        { shallow: true }
      );
      setSignificantChange(false);
    }
  }, [significantChange, router, isLoadingMore]);

  // When vehicle changes
  useEffect(() => {
    if (prevVehicleId !== preferredVehicle?.catalogVehicleId && prevVehicleId !== undefined) {
      setSignificantChange(true);
    }
  }, [preferredVehicle?.catalogVehicleId, prevVehicleId]);

  // When store changes
  useEffect(() => {
    if (
      router.query.loadMore &&
      !!prevStoreNumber &&
      storeDetails?.storeNumber !== prevStoreNumber &&
      storeDetails?.storeNumber
    ) {
      setSignificantChange(true);
      scrollToTop();
      sessionStorage.removeItem('previousStore');
    }
  }, [storeDetails?.storeNumber, prevStoreNumber, router]);

  const getSectionElement = (
    column: HTMLElement | null,
    section: string
  ): HTMLElement | undefined => {
    const sectionElements = column ? Array.from(column.querySelectorAll<HTMLElement>(section)) : [];
    if (sectionElements.length > 1) {
      return sectionElements.find((elm) => elm?.offsetHeight > 0);
    }

    return sectionElements[0];
  };

  function getSectionHeightForColumn(column: HTMLElement | null, section: string) {
    if (column) {
      const sectionElement = getSectionElement(column, section);
      sectionElement && setElementHeight(sectionElement);

      return sectionElement?.offsetHeight;
    }
  }

  const reSizableSectionClasses = [
    '.fulfillment-option-wrapper',
    '.product-title',
    '.product-price-wrapper',
    '.product-detail-wrapper',
    '.fitment-button-container',
    '.product-notes-grid-view',
    '.product-attributes-container',
  ];
  // TODO replace with useMediaQuery
  const isWidthMobileOrTablet = windowWidth <= 1139 || window.outerWidth <= 1139;
  const isTabletOnly = windowWidth <= 1139 && windowWidth > 719;
  const RR_AZRMOB_SHELF_ENABLED = useFeatureFlag('RR_AZRMOB_SHELF_ENABLED');
  const RR_AZRMOB_SHELF_TAG = useFeatureFlag('RR_AZRMOB_SHELF_TAG');
  const RR_AZRWEB_SHELF_ENABLED = useFeatureFlag('RR_AZRWEB_SHELF_ENABLED');
  const RR_AZRWEB_SHELF_TAG = useFeatureFlag('RR_AZRWEB_SHELF_TAG');
  const enable24ProductView = useIs24ProductViewEnabled();
  const customHeaderScript =
    isWidthMobileOrTablet && RR_AZRMOB_SHELF_ENABLED === 'true'
      ? RR_AZRMOB_SHELF_TAG
      : RR_AZRWEB_SHELF_ENABLED === 'true'
      ? RR_AZRWEB_SHELF_TAG
      : '';

  const citrusShelfBannersEnabled = useFeatureFlag('IS_CITRUS_SHELF_BANNERS_ENABLED') === 'true';
  const citrusSearchBannersEnabled =
    useFeatureFlag('IS_CITRUS_SEARCH_RESULTS_BANNERS_ENABLED') === 'true';

  const { data: citrusShelfData } = useCitrusShelfData({
    enabled: citrusShelfBannersEnabled,
  });
  const { data: citrusSearchData } = useCitrusSearchPageData({
    enabled: citrusSearchBannersEnabled,
  });

  const citrusData = isSearchPage ? citrusSearchData : citrusShelfData;
  const citrusBannerSlotId = isSearchPage
    ? 'in-grid-midpage-banner'
    : 'category-shelf-midpage-banner';
  const hasCitrusMidBanner =
    (citrusShelfBannersEnabled || citrusSearchBannersEnabled) &&
    !!citrusData?.ads.find((ads) => ads.slotId === citrusBannerSlotId && !!ads.banners.length);

  function resizeGridContents(shelfItems: Array<React.RefObject<HTMLElement>>) {
    const shelfItemsLength = shelfItems.length;
    const columnsPerRow = matchesTablet ? 2 : 3;
    const numOfRows = Math.ceil(shelfItemsLength / columnsPerRow);
    let i;

    for (i = 0; i < numOfRows; i++) {
      const columnStartIndex = i * columnsPerRow;
      const columns = shelfItems.slice(columnStartIndex, columnStartIndex + columnsPerRow);
      reSizableSectionClasses.forEach((section) => {
        let longestColumnSectionHeight = 0;
        columns.forEach((column) => {
          const currentColumn = column.current;
          const columnSectionHeight = getSectionHeightForColumn(currentColumn, section);

          if (columnSectionHeight && columnSectionHeight > longestColumnSectionHeight) {
            longestColumnSectionHeight = columnSectionHeight;
          }
        });
        columns.forEach((column) => {
          const currentColumn = column.current;
          const columnSection = getSectionElement(currentColumn, section);
          setElementHeight(columnSection, longestColumnSectionHeight);
        });
      });
    }
  }

  function resetGridHeights(shelfItems: Array<React.RefObject<HTMLElement>>) {
    shelfItems.forEach((shelfItem) => {
      const currentItem = shelfItem.current;
      reSizableSectionClasses.forEach((section) => {
        const shelfItemSection = currentItem?.querySelectorAll<HTMLElement>(section)[0];
        setElementHeight(shelfItemSection);
      });
    });
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let timeoutId: ReturnType<typeof setTimeout>;
      resizeGrids();
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setWindowWidth(window.innerWidth), 150);
      };

      window.addEventListener('resize', resizeListener);

      return () => {
        window.removeEventListener('resize', resizeListener);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      enable24ProductView &&
      isElementVisible &&
      !hasFetchedSecondarySkus &&
      setShouldFetchSecondaryProductSkus &&
      !isLoadMoreEnabled
    ) {
      setShouldFetchSecondaryProductSkus(true);
      setHasFetchedSecondarySkus(true);
    }
  }, [
    enable24ProductView,
    isElementVisible,
    hasFetchedSecondarySkus,
    isLoadMoreEnabled,
    setShouldFetchSecondaryProductSkus,
  ]);

  const resizeGrids = () => {
    const shelfItems = shelfItemRefs.current;
    const shelfItemsLength = shelfItems.length;
    const shelfItemsExist = shelfItems && shelfItemsLength && shelfItemsLength > 1;
    if (shelfItemsExist && !isListView && !matchesMobile) {
      resizeGridContents(shelfItems);
      setResizedGridContents(true);
    } else if (resizedGridContents && (isListView || matchesMobile)) {
      resetGridHeights(shelfItems);
      setResizedGridContents(false);
    }
  };

  useEffect(() => {
    if (resultsListRef?.current) {
      if (resizeObserver) {
        resizeObserver.disconnect();
        resizeObserver.observe(resultsListRef?.current);
      }
    }

    let timeoutId: ReturnType<typeof setTimeout>;

    if (
      (previousResultListHeight === 0 && resultListHeight === 0) ||
      previousResultListHeight !== resultListHeight ||
      windowWidth !== previousWindowWidth ||
      miniCartItemCount !== previousMiniCartItemCount
    ) {
      timeoutId = setTimeout(() => {
        resizeGrids();
      }, 150);
    }

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListView, resultListHeight, windowWidth, miniCartItemCount]);

  if (parts && shelfItemRefs.current.length !== parts.length) {
    shelfItemRefs.current = Array(parts.length)
      .fill(null)
      .map((_, i) => shelfItemRefs.current[i] || createRef());
  }

  const fetchPaginatedList = (_: unknown, pageNumber: number) => {
    const {
      // we ignore eslint here because destructuring got used to omit prop from object
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: { cmsPath, slug, seoUrlPath, brandName, ...restQuery },
      asPath,
    } = router;
    setHasFetchedSecondarySkus(false);
    void router.push({
      pathname: getPathnameFromAsPath(asPath.trim()),
      query: { ...restQuery, pageNumber },
    });
  };

  const hasSearchTextQuery = Boolean(query.searchText);
  const isMobileQRExperience = useIsMobileQRExperience();

  const shouldShowSecondBanner = (!isSYMMPage || hasSearchTextQuery) && parts && parts.length > 3;

  const whereToShowBanner = !isListView && isTabletOnly ? 4 : 3;
  const brandPartsNotAvailable = useLabel(
    'label_brandpage_resultsList_isCurrentlyNotAvailableForYour'
  );
  const forLabel = useLabel('label_Search_for');
  const forYourLabel = useLabel('label_Search_for_your');
  const resultsLabel = useLabel('label_Search_results');
  const noPartsFoundLabel = isBrandShelfPage(router.asPath)
    ? `${brandNameShelf ?? ''} ${brandPartsNotAvailable}`
    : `${parts?.length ? String(parts.length) : '0'} ${resultsLabel} ${forLabel} ${
        shelfCatLabel ?? ''
      } ${forYourLabel}`;

  let resizeObserver: ResizeObserver | undefined;
  const showLoadmoreOrBackToTopButton =
    isPaginationEnabled &&
    parts?.length &&
    !loadingStatus &&
    isLoadMoreEnabled &&
    status === 'success' &&
    !isLoadingMore;

  try {
    resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        entries.forEach((entry) => {
          if (entry.target.getBoundingClientRect().height !== previousResultListHeight) {
            setResultListHeight(entry.target.getBoundingClientRect().height);
          }
        });
      });
    });
  } catch (e) {
    resizeObserver = undefined;
  }

  return (
    <Grid justify="center" alignItems="center" container id="shelf-results-container">
      <Grid
        container
        spacing={0}
        ref={resultsListRef}
        className={style.results}
        id="shelf-result-list"
      >
        {parts?.length === 0 ? (
          <NoPartFound noPartsFoundLabel={noPartsFoundLabel} vehicleName={vehicleName} />
        ) : null}
        <ul>
          {parts?.slice(0, whereToShowBanner)?.map((part, index) => (
            <ShelfProduct
              key={`${part.skuNumber}${index}`}
              hasVehicle={hasVehicle}
              pageType={pageType}
              index={index}
              onDealClick={handleDealClick}
              part={part}
              shelfItemRef={shelfItemRefs.current[index]}
              isListItem
              shelfReadyStatus={shelfReadyStatus}
              storeNumber={storeNumber}
              toggleShowYmme={toggleShowYmme}
              setWarrantyModal={setWarrantyModal}
              resizeShelfGrids={
                !resizeObserver
                  ? () =>
                      setTimeout(() => {
                        resizeGrids();
                      }, 150)
                  : undefined
              }
              xFusionQueryId={xFusionQueryId}
            />
          ))}
          {shouldShowSecondBanner && !isMobileQRExperience ? (
            <>
              <FeatureFlag flag="IS_CITRUS_ENABLED">
                {hasCitrusMidBanner ? (
                  <li className={styles.secondBannerBackGroundCard}>
                    <CMSCitrusShelf content={{ slot_id: citrusBannerSlotId }} />
                  </li>
                ) : null}
              </FeatureFlag>
            </>
          ) : null}
          {parts?.slice(whereToShowBanner)?.map((part, index) => {
            return (
              <ShelfProduct
                key={part.skuNumber}
                pageType={pageType}
                hasVehicle={hasVehicle}
                index={index + whereToShowBanner}
                onDealClick={handleDealClick}
                part={part}
                isListItem
                shelfItemRef={shelfItemRefs.current[index + whereToShowBanner]}
                shelfReadyStatus={shelfReadyStatus}
                storeNumber={storeNumber}
                toggleShowYmme={toggleShowYmme}
                setWarrantyModal={setWarrantyModal}
                resizeShelfGrids={
                  !resizeObserver
                    ? () =>
                        setTimeout(() => {
                          resizeGrids();
                        }, 150)
                    : undefined
                }
                xFusionQueryId={xFusionQueryId}
              />
            );
          })}
        </ul>

        {isPaginationEnabled && parts?.length && !isLoadMoreEnabled ? (
          <PaginationComp
            offset={firstRecNum}
            limit={recsPerPage}
            total={totalNumRecs}
            performFetch={fetchPaginatedList}
            pageName={productInfoConstants.productShelfName}
          />
        ) : (
          !isLoadMoreEnabled && <div className={styles.noPaginationSection} />
        )}
        <YMMEModal
          onUpdateVehicleSuccess={redirectAfterVehicleChanged}
          showYmme={showYmme}
          closeYmmeWidget={toggleShowYmme}
        />
        {warrantyModal.isShown ? (
          <WarrantyModal
            showWarranty={warrantyModal.isShown}
            closeWarrantyModal={handleCloseWarrantyModal}
            warrantyType={warrantyModal.warrantyType}
          />
        ) : null}
        {customHeaderScript ? <Script src={customHeaderScript} strategy="lazyOnload" /> : null}
      </Grid>
      {(isLoadingMore || loadingStatus) &&
        !!parts?.length &&
        isLoadMoreEnabled &&
        loadMoreClicked && <LoadingIndicator />}
      {!!parts?.length && isLoadMoreEnabled && showLoadmoreOrBackToTopButton && (
        <LoadMoreResults
          totalRecords={totalNumRecords}
          hasNextPage={hasNextPage}
          currentlyShowing={currentlyShowing}
          fetchMore={async () => {
            setIsLoadingMore(true);
            if (!router.query.loadMore) {
              setIsLoadingMore(true);
              await router.push(
                {
                  pathname: router.pathname,
                  query: { ...router.query, loadMore: 'true' },
                },
                undefined,
                { shallow: true }
              );
            }
            fetchNextPage && (await fetchNextPage());
            setPageSpecificData({ productFindingMethod: 'loadMore' });
            setIsLoadingMore(false);
          }}
        />
      )}
      {isLoadMoreEnabled && !isPaginationEnabled && <div className={styles.noPaginationSection} />}
    </Grid>
  );
};
