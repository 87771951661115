/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '@/components/Button';
import styles from './flyout.module.scss';
import { sessionStorage } from '@/utils/sessionStorage';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';
import { useSetStore } from '../../hooks/useSetStore';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { SmartLink as Link } from '@/utils/smartLink';
import { countryCodes } from '@/constants/locale';
import { capitalizePhrase } from '@/utils/capitalizePhrase';
import { SnackBarContext } from '@/components/SnackBarContext';
import { SisterStoreContext } from '../../context/SisterStoreContext';
import { routePaths } from '@/constants/routePaths';
import { useRouter } from 'next/router';
import { GetDirectionsBlock } from './GetDirectionsBlock';
import NextImage from '@/components/NextImage';

type Props = {
  sisterStore: any;
  productDetails: any;
  itemData: any;
  filterproduct: any;
  skuId: string;
  count: number;
  indexToFocus: boolean;
  removeTabbableItems: boolean;
  labelMap: Record<string, string>;
  quantityInCart: number;
  dealId?: string;
  handleClose?: (e: any, option?: boolean, storeDidChange?: boolean) => void;
  prevStore?: string;
};

const getAnalyticsId = (qtyInCart: number, count: number) => {
  if (qtyInCart === 0) {
    // Select store and add to cart
    return 'select_store_and_add_to_cart';
  } else if (qtyInCart === count) {
    //Select a store
    return 'select_store';
  } else {
    //Select store and update cart
    return 'select_store_and_update_cart';
  }
};

export const SisterStoreTile = ({
  sisterStore,
  skuId,
  productDetails,
  itemData,
  filterproduct,
  count,
  indexToFocus,
  removeTabbableItems,
  labelMap,
  quantityInCart,
  dealId,
  prevStore,
}: Props) => {
  const labels = useLabels(labelMap);
  const currentDay: string = sisterStore.sisterStoreDetail.currentDay;
  const now = new Date();
  const currentHour = now.getHours();
  const [sisterStoreCheck, setSisterStoreCheck] = useState(false);
  const { showSnackBar: showSnackBarContext } = useContext(SnackBarContext);
  const { isUsingAutoClose, updateSettingNewStore, closeSisterStoreFlyout } =
    useContext(SisterStoreContext);

  const setStore = useSetStore(() => null);
  const router = useRouter();
  const { sisterStoreDetail, qoh } = sisterStore;
  const locale = useLocale();
  const isMX = locale === countryCodes.mx;
  const isBR = locale === countryCodes.ptBr;
  const isUS = locale === countryCodes.us;

  useEffect(() => {
    if (sisterStore !== undefined) {
      setSisterStoreCheck(true);
    }
  }, [sisterStore]);

  /**
   *Determines the appropriate label based on cart quantity and count.
   If a deal ID exists, returns the label for selecting a store.
   */
  const getLabel = (qtyInCart: number, count: number): string => {
    if (dealId || isBR) {
      return labels.selectStore;
    }

    if (qtyInCart === 0) {
      return labels.selectStoreAndAddToCart;
    } else if (qtyInCart === count) {
      return labels.selectStore;
    } else {
      return labels.selectStoreAndUpdateCart;
    }
  };

  const onSetStoreClick = useCallback(() => {
    if (router.asPath === routePaths.cart || !isUsingAutoClose) {
      closeSisterStoreFlyout();
    } else {
      updateSettingNewStore(true);
    }
    if (!dealId && !isBR) {
      sessionStorage.setItem('addOrUpdateCart', 'true');
      sessionStorage.setItem('skuId', skuId ?? '');
      sessionStorage.setItem('skuIdSetStore', skuId ?? '');
      sessionStorage.setItem('sister_store_update_type', getAnalyticsId(quantityInCart, count));
      sessionStorage.setItem('productId', itemData?.productInfo?.productId);
      sessionStorage.setItem('storeNumber', sisterStoreDetail.storeNumber);
      sessionStorage.setItem(
        'quantity',
        count > sisterStore.qoh ? sisterStore.qoh.toString() : count.toString()
      );
    }

    const isSameAsPrevStore = prevStore?.toLowerCase() === sisterStoreDetail.address1.toLowerCase();

    if (!isSameAsPrevStore) {
      showSnackBarContext(
        `${labels.lblYourStoreHasBeenUpdatedTo} ${capitalizePhrase(sisterStoreDetail.address1)}.`
      );
      setStore(sisterStoreDetail.storeNumber, sisterStoreDetail.zip, true);
    } else {
    }
  }, [
    router.asPath,
    isUsingAutoClose,
    dealId,
    isBR,
    prevStore,
    sisterStoreDetail.address1,
    sisterStoreDetail.storeNumber,
    sisterStoreDetail.zip,
    closeSisterStoreFlyout,
    updateSettingNewStore,
    skuId,
    quantityInCart,
    count,
    itemData?.productInfo?.productId,
    sisterStore.qoh,
    showSnackBarContext,
    labels.lblYourStoreHasBeenUpdatedTo,
    setStore,
  ]);

  const address1 = sisterStoreDetail.address1.toLocaleLowerCase();
  const address2 =
    sisterStoreDetail.address2 !== 'NULL' && sisterStoreDetail.address2 !== undefined
      ? sisterStoreDetail.address2.toLocaleLowerCase()
      : '';
  const address3 =
    sisterStoreDetail.address3 !== 'NULL' && sisterStoreDetail.address3 !== undefined
      ? sisterStoreDetail.address3?.toLocaleLowerCase()
      : '';
  const cityStateZipcode = `${sisterStoreDetail.city.toLocaleLowerCase()}, ${sisterStoreDetail.state.toLocaleUpperCase()} ${
    sisterStoreDetail.zip || ''
  }`;
  const ariaLabelSetYourStore = `Select store ${sisterStoreDetail?.storeNumber} ${address1} ${address2} ${cityStateZipcode}`;
  const ariaLabelProductDetails = productDetails
    ? productDetails?.skuDescription
    : filterproduct?.name;

  const addItemToCart = `${labels.lblAdd} ${count} ${ariaLabelProductDetails} ${labels.lblToCart}`;
  const quantityToAdd = count - quantityInCart;
  const quantityToAddOrRemove = Math.abs(count - quantityInCart);
  const addQuantityOfItemToCart = `${labels.lblAdd} ${quantityToAddOrRemove} ${ariaLabelProductDetails} ${labels.lblToCart}`;
  const removeQuantityOfItemFromCart = `${labels.lblRemove} ${quantityToAddOrRemove} ${ariaLabelProductDetails} ${labels.lblFromCart}`;
  const setStoreAddItem = `${ariaLabelSetYourStore} ${labels.lblAnd} ${addItemToCart}`;
  const setStoreUpdateAddItem = `${ariaLabelSetYourStore} ${labels.lblAnd} ${addQuantityOfItemToCart}`;
  const setStoreUpdateRemoveItem = `${ariaLabelSetYourStore} ${labels.lblAnd} ${removeQuantityOfItemFromCart}`;
  const ariaLabel =
    quantityInCart == 0
      ? setStoreAddItem
      : quantityInCart == count
      ? ariaLabelSetYourStore
      : quantityToAdd > 0
      ? setStoreUpdateAddItem
      : setStoreUpdateRemoveItem;

  const calculateInStock = () => {
    return qoh <= 0 || qoh < count;
  };

  const calcPickupLabel = () => {
    const is24HourStore =
      sisterStoreDetail?.storeFullHours?.[currentDay].open === '0:00 am' &&
      sisterStoreDetail?.storeFullHours?.[currentDay].close === '0:00 pm';
    if (is24HourStore) {
      return currentHour >= 22; // past 10pm then tomorrow otherwise its today
    }
    return (
      parseInt(sisterStoreDetail?.storeFullHours?.[currentDay].close.split(':')) + 10 <= currentHour
    );
  };

  return sisterStoreCheck ? (
    <>
      <div
        className={`${styles.mainContainer} ${styles.flex} ${styles.flexColumn} ${styles.flexWrap}`}
      >
        <div className={`${styles.topContainer} ${styles.flex}`}>
          <div
            data-testid="sister-store-flyout-address"
            className={`${styles.address} ${styles.flex} ${styles.flexColumn}`}
          >
            {!isBR && (
              <>
                <div>{address1}</div>
                <div>{address2}</div>
                <div>{cityStateZipcode}</div>
              </>
            )}

            {isBR && (
              <div>
                <p>{`${address1}, ${address2}`}</p>
                <p>{address3}</p>
                {`${
                  sisterStoreDetail.zip || ''
                }, ${sisterStoreDetail.city.toLocaleLowerCase()} ${sisterStoreDetail.state.toLocaleUpperCase()}`}
              </div>
            )}
            <GetDirectionsBlock sisterStoreDetail={sisterStoreDetail} />
          </div>
          <div className={`${styles.storeDetails} ${styles.flex} ${styles.flexColumn}`}>
            <div className={styles.storeNumber}>
              {labels.storeNumber}
              {sisterStoreDetail?.storeNumber}
            </div>
            <div className={styles.distance}>
              {sisterStore.distance} {labels.distance}
            </div>
            <div>{labels.fromYourStore}</div>
            {isMX ? <div>{labels.selectedStore}</div> : null}
          </div>
        </div>
        <div className={`${styles.bottomContainer}`}>
          <div className={`${styles.pickup} ${styles.flex}`}>
            {isMX && (
              <>
                <div className={`${styles.flex} ${styles.flexColumn}`}>
                  <div>{labels.orderOnline}</div>
                  <div>{labels.pickupAtStore}</div>
                </div>
              </>
            )}
            {isUS && (
              <div className={`${styles.inStorePickup} `}>
                {labels.inStorePickup}&nbsp;
                <span className={`${styles.pickupDay}`}>
                  {calcPickupLabel() ? labels.tomorrow : labels.today}
                </span>
              </div>
            )}

            {isBR && <span></span>}

            <div className={`${styles.pickup} ${styles.flex}`}>
              <Link
                aria-label={`${labels.lblLinkToCallPhoneNumber} ${formatPhoneNumber(
                  sisterStoreDetail.phoneNumber,
                  locale
                )} `}
                to={'tel:' + sisterStoreDetail?.phoneNumber}
                tabIndex={removeTabbableItems ? -1 : 0}
                setFocusToAnchorTag={indexToFocus}
              >
                <span className={styles.storePhoneNumber}>
                  {formatPhoneNumber(sisterStoreDetail.phoneNumber, locale)}
                </span>
              </Link>
            </div>
          </div>

          <div className={`${styles.storeDetails} ${styles.flex} ${styles.storeDetailsFlexView}`}>
            <div className={calculateInStock() ? styles.outOfStock : styles.inStock}>
              {qoh} {labels.inStock}
            </div>
            <div
              data-testid="sister-store-flyout-open-until"
              className={sisterStoreDetail?.openedNow ? styles.openUntil : styles.closed}
            >
              {sisterStoreDetail?.messageToDisplay} {isBR ? 'hrs' : ''}
            </div>
          </div>
        </div>
        {calculateInStock() && (
          <div className={styles.infoContainer}>
            <NextImage src="/images/blueToolTip.svg" alt="info" width={16} height={16} />
            <div className={styles.infoLabel}>{labels.reduce_quantity}</div>
          </div>
        )}
        <div className={styles.buttonClass}>
          <Button
            data-testid="sister-store-flyout-button"
            size="small"
            fullWidth={true}
            customClass={styles.storeButtonClass}
            variant="contained"
            ariaLabel={ariaLabel}
            onClick={onSetStoreClick}
            disabled={calculateInStock()}
            tabIndex={removeTabbableItems ? -1 : 0}
          >
            {getLabel(quantityInCart, count)}
          </Button>
        </div>
      </div>
    </>
  ) : null;
};

export default SisterStoreTile;
