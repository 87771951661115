/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import cx from 'classnames';
import Breadcrumb from '@/components/AZCustomComponent/BreadcrumbSTARC';
import type {
  ContentStackDefaultBrandPage,
  ContentStackPremiumBrandPages,
  ContentStackProductListingPage,
  ContentStackShelfPage,
} from '@/features/contentstack/interface';
import { CMSBanner } from '@/features/contentstack/components/CMSBanner/CMSBanner';
import { CMSBannerCTA } from '@/features/contentstack/components/CMSBannerCTA/CMSBannerCTA';
import { CMSBannerSplit } from '@/features/contentstack/components/CMSBannerSplit/CMSBannerSplit';
import { CMSButtonGrid } from '@/features/contentstack/components/CMSButtonGrid/CMSButtonGrid';
import { CMSCategoryGrid } from '@/features/contentstack/components/CMSCategoryGrid/CMSCategoryGrid';
import { CMSFaq } from '@/features/contentstack/components/CMSFaq/CMSFaq';
import { CMSFeaturedContent } from '@/features/contentstack/components/CMSFeaturedContent/CMSFeaturedContent';
import { CMSGrid } from '@/features/contentstack/components/CMSGrid/CMSGrid';
import { CMSImageCategory } from '@/features/contentstack/components/CMSImageCategory/CMSImageCategory';
import {
  CMSImageContent,
  isValidCMSImageContent,
} from '@/features/contentstack/components/CMSImageContent/CMSImageContent';
import { CMSImageContentBackground } from '@/features/contentstack/components/CMSImageContentBackground/CMSImageContentBackground';
import { CMSLogoDescription } from '@/features/contentstack/components/CMSLogoDescription/CMSLogoDescription';
import { CMSMenuList } from '@/features/contentstack/components/CMSMenuList/CMSMenuList';
import { CMSProductChart } from '@/features/contentstack/components/CMSProductChart/CMSProductChart';
import { CMSProductTypeGrid } from '@/features/contentstack/components/CMSProductTypeGrid/CMSProductTypeGrid';
import { CMSRichText } from '@/features/contentstack/components/CMSRichText/CMSRichText';
import { CMSSpacer } from '@/features/contentstack/components/CMSSpacer/CMSSpacer';
import { CMSVideoContent } from '@/features/contentstack/components/CMSVideoContent/CMSVideoContent';
import { type IItem } from '@/components/AZCustomComponent/BreadcrumbSTARC/utils/isBreadcrumbModel';
import { Grid } from '@/components/Grid';
import styles from '../styles.module.scss';
import Title from '@/components/AZCustomComponent/Title';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import azCommonStyles from '@/theme/globals.module.scss';
import { ExpandableTopDescription } from '../ExpandableTopDescription';
import { useRemoveUnsupportedSearchChars } from '@/hooks/useRemoveUnsupportedSearchChars';
import { SmartLink } from '@/utils/smartLink';
import { View, Icon, ArrowLeft, Text } from '@az/starc-ui';
import { useRouter } from 'next/router';
import { useLabel } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import React from 'react';
import Hidden from '@/components/Hidden';
import { STARCTypography } from '@/components/STARCTypography';
import { CMSShelfSpacer } from '@/features/contentstack/components/CMSShelfSpacer/CMSShelfSpacer';
import { countryCodes } from '@/constants/locale';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';
import { usePageType } from '@/hooks/usePageType';
import { pageTypes } from '@/constants/page';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { SuperDuperHero } from '@/features/home/components/SuperDuperHero/SuperDuperHero';
import { VisualCategoryFilters } from '../../../../search/components/VisualCategoryFilters';
import { usePreferredVehicle } from '@/features/header/hooks/usePreferredVehicle';
import { CMSImageVideoSplit } from '@/features/contentstack/components/CMSImageVideoSplit/CMSImageVideoSplit';
import { CMSGridBanner } from '@/features/contentstack/components/CMSGridBanner/CMSGridBanner';

type Props = {
  catLabel?: string;
  content:
    | NonNullable<ContentStackShelfPage['shelfresultheader']>
    | NonNullable<ContentStackDefaultBrandPage['brandshelfheader']>
    | NonNullable<ContentStackPremiumBrandPages['brandshelfheader']>
    | NonNullable<ContentStackProductListingPage['shelfresultheader']>;
  seoTopCopy?: string | null;
  shelfBreadcrumb?: IItem[];
  shortDescription?: string | null;
  showSEOData: boolean;
  showBackButton?: boolean;
  handleClick?: () => void;
  viewingPartTypes?: boolean;
  setViewingPartTypes?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CMSShelfResultHeaderModularHandler = ({
  catLabel,
  content,
  seoTopCopy,
  shelfBreadcrumb = [],
  shortDescription,
  showSEOData,
  showBackButton = false,
  handleClick,
  viewingPartTypes = false,
  setViewingPartTypes,
}: Props) => {
  const preferredVehicle = usePreferredVehicle();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const removeUnsupportedSearchChars = useRemoveUnsupportedSearchChars();
  const backButtonLabel = useLabel('label_back_to_results');
  const locale = useLocale();
  const isUS = locale === countryCodes.enUS;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const useNewShelfDesignMobile =
    useMonetateDecisionFlag('useNewShelfDesignMobile') && isMobile && isUS;
  const router = useRouter();
  const { pageType } = usePageType();
  const isBrandPage = pageType === pageTypes.BrandShelf;
  const isProductShelf = pageType === pageTypes.ProductShelf;
  const { data: brandData } = useBrandProductData({
    enabled: isBrandPage,
  });
  const catLabelValue =
    isBrandPage && brandData?.brandName ? `${brandData?.brandName} Parts and Products` : catLabel;
  return (
    <>
      {content.map((modularBlock, index) => {
        if (modularBlock.breadcrumb?.enable) {
          return (
            <React.Fragment key={index}>
              <Hidden smDown implementation="js">
                <Breadcrumb
                  customClass={cx({ [styles.showingBack]: showBackButton && !isMobile })}
                  showSEOData={showSEOData}
                  data={shelfBreadcrumb}
                />
              </Hidden>
              {showBackButton && (
                <SmartLink
                  samePage
                  to={`/searchresult?searchText=${encodeURIComponent(
                    removeUnsupportedSearchChars(String(router.query.searchText))
                  )}`}
                  handleClick={handleClick}
                >
                  <View
                    align="center"
                    gap={1}
                    direction="row"
                    padding={[2, !isLargeScreen ? 3 : 0]}
                  >
                    <Icon svg={ArrowLeft} color="accent" />
                    <Text
                      // TODO: remove className when STARC resolves https://gitlab.autozone.com/it/open-interface/starc/-/issues/27
                      className={styles.fontFamCond}
                      weight="medium"
                      attributes={{
                        style: { lineHeight: useNewShelfDesignMobile ? '24px' : '1.40' },
                      }}
                      size={useNewShelfDesignMobile ? '087' : '125'}
                      textCase="uppercase"
                    >
                      {backButtonLabel}
                    </Text>
                  </View>
                </SmartLink>
              )}
              <Hidden mdUp implementation="js">
                <Breadcrumb showSEOData={showSEOData} data={shelfBreadcrumb} />
              </Hidden>
            </React.Fragment>
          );
        }
        if (modularBlock.banner?.banner?.[0]?.asset_size) {
          return (
            <STARCTypography key={index}>
              <CMSShelfSpacer removeHorizontalSpacing={{ s: true, l: true }}>
                <CMSBanner
                  content={modularBlock.banner.banner[0]}
                  legalPadding={{ s: true, m: true, l: false }}
                />
              </CMSShelfSpacer>
            </STARCTypography>
          );
        }
        if (modularBlock.banner_split?.banner_split?.[0]) {
          return (
            <STARCTypography key={index}>
              <CMSShelfSpacer removeHorizontalSpacing={{ s: true, l: true }}>
                <CMSBannerSplit
                  content={modularBlock.banner_split.banner_split[0]}
                  cmsBannerOptions={{ legalPadding: { s: true, m: true, l: false } }}
                />
              </CMSShelfSpacer>
            </STARCTypography>
          );
        }

        if (
          'rich_text_editor' in modularBlock &&
          modularBlock.rich_text_editor?.rich_text_editor?.[0]
        ) {
          return (
            <CMSSpacer key={index}>
              <CMSRichText content={modularBlock.rich_text_editor.rich_text_editor[0]} />
            </CMSSpacer>
          );
        }

        if ('video_content' in modularBlock && modularBlock.video_content?.video_content?.[0]) {
          return (
            <CMSSpacer key={index} removeHorizontalSpacing={{ l: true }}>
              <CMSVideoContent content={modularBlock.video_content.video_content[0]} />
            </CMSSpacer>
          );
        }

        if (
          'image_video_split' in modularBlock &&
          modularBlock.image_video_split?.image_video_split?.[0]
        ) {
          return (
            <CMSSpacer
              removeHorizontalSpacing={{ l: true }}
              removeImagePadding={{ s: true }}
              key={index}
            >
              <CMSImageVideoSplit content={modularBlock.image_video_split.image_video_split[0]} />
            </CMSSpacer>
          );
        }

        if ('grid_banner' in modularBlock && modularBlock.grid_banner?.grid_banner?.[0]) {
          return (
            <CMSSpacer removeHorizontalSpacing={{ l: true, s: true }} key={index}>
              <CMSGridBanner content={modularBlock.grid_banner.grid_banner[0]} />
            </CMSSpacer>
          );
        }

        if ('faq' in modularBlock && modularBlock.faq?.faq?.[0]?.faq?.length) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSFaq content={modularBlock.faq.faq[0]} />
            </CMSShelfSpacer>
          );
        }

        if ('parttype_grid' in modularBlock && modularBlock.parttype_grid?.parttype_grid?.[0]) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSGrid content={modularBlock.parttype_grid.parttype_grid[0]} />
            </CMSShelfSpacer>
          );
        }

        if ('category_grid' in modularBlock && modularBlock.category_grid?.category_grid?.[0]) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSCategoryGrid content={modularBlock.category_grid.category_grid[0]} />
            </CMSShelfSpacer>
          );
        }

        if ('product_chart' in modularBlock && modularBlock.product_chart?.product_chart?.[0]) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSProductChart content={modularBlock.product_chart.product_chart[0]} />
            </CMSShelfSpacer>
          );
        }

        if ('video_content' in modularBlock && modularBlock.video_content?.video_content?.[0]) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSVideoContent content={modularBlock.video_content.video_content[0]} />
            </CMSShelfSpacer>
          );
        }
        if (
          'image_content' in modularBlock &&
          modularBlock.image_content?.image_content?.[0] &&
          isValidCMSImageContent(modularBlock.image_content?.image_content?.[0])
        ) {
          return (
            <CMSShelfSpacer
              removeHorizontalSpacing={{ l: true }}
              removeImagePadding={{ s: true }}
              key={index}
            >
              <CMSImageContent content={modularBlock.image_content.image_content[0]} />
            </CMSShelfSpacer>
          );
        }

        if (
          'image_content_background' in modularBlock &&
          modularBlock.image_content_background?.image_content_background?.[0]
        ) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSImageContentBackground
                content={modularBlock.image_content_background.image_content_background[0]}
              />
            </CMSShelfSpacer>
          );
        }

        if (
          'image_category_grid' in modularBlock &&
          modularBlock.image_category_grid?.image_category_grid?.[0]
        ) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSImageCategory content={modularBlock.image_category_grid.image_category_grid[0]} />
            </CMSShelfSpacer>
          );
        }

        if ('menu_list' in modularBlock && modularBlock.menu_list?.menu_list?.[0]) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSMenuList content={modularBlock.menu_list.menu_list[0]} />
            </CMSShelfSpacer>
          );
        }

        if ('product_grid' in modularBlock && modularBlock.product_grid?.product_grid?.[0]) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSProductTypeGrid
                content={modularBlock.product_grid.product_grid[0]}
                gridIndex={index}
              />
            </CMSShelfSpacer>
          );
        }

        if ('banner_cta' in modularBlock && modularBlock.banner_cta?.banner_cta?.[0]) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSBannerCTA
                content={modularBlock.banner_cta.banner_cta[0]}
                priority={index === 0}
              />
            </CMSShelfSpacer>
          );
        }

        if (
          'featured_content' in modularBlock &&
          modularBlock.featured_content?.featured_content?.[0]
        ) {
          return (
            <CMSShelfSpacer key={index}>
              <CMSFeaturedContent content={modularBlock.featured_content.featured_content[0]} />
            </CMSShelfSpacer>
          );
        }

        if (
          'logo_description' in modularBlock &&
          modularBlock.logo_description?.logo_description?.[0]
        ) {
          return (
            <CMSShelfSpacer
              key={index}
              id={modularBlock.logo_description.logo_description[0].component_id ?? ''}
            >
              <CMSLogoDescription content={modularBlock.logo_description.logo_description[0]} />
            </CMSShelfSpacer>
          );
        }

        if ('button_grid' in modularBlock && modularBlock.button_grid?.button_grid?.[0]) {
          return (
            <CMSShelfSpacer key={index} removeHorizontalSpacing>
              <CMSButtonGrid content={modularBlock.button_grid.button_grid[0]} />
            </CMSShelfSpacer>
          );
        }

        if (
          'super_duper_hero' in modularBlock &&
          modularBlock.super_duper_hero?.super_duper_hero?.[0]
        ) {
          return (
            <CMSShelfSpacer key={index} removeHorizontalSpacing>
              <SuperDuperHero options={modularBlock.super_duper_hero.super_duper_hero[0]} />
            </CMSShelfSpacer>
          );
        }

        if (
          'shop_by_category' in modularBlock &&
          modularBlock.shop_by_category?.enable &&
          setViewingPartTypes
        ) {
          return (
            <CMSShelfSpacer key={index} removeHorizontalSpacing removeVerticalSpacing>
              <View
                padding={
                  useNewShelfDesignMobile
                    ? !preferredVehicle
                      ? [1, 0, 2, 0]
                      : [1, 0, 3, 0]
                    : { s: [0, 4, 3.5, 4] }
                }
              >
                <VisualCategoryFilters
                  setViewingPartTypes={setViewingPartTypes}
                  viewingPartTypes={viewingPartTypes}
                />
              </View>
            </CMSShelfSpacer>
          );
        }

        return null;
      })}
      {catLabelValue && (
        <Grid
          container
          spacing={0}
          className={cx(styles.titleSection, {
            [styles.slimTitleSection]: useNewShelfDesignMobile && !isProductShelf,
          })}
          wrap={isLargeScreen ? 'wrap' : 'nowrap'}
        >
          <Grid item sm={12} md={10} lg={9}>
            <Title
              overRuleH1="h1"
              variant="lg"
              firstText={catLabelValue}
              firstTextClass={styles.shelfTitle}
              dataTestIdContainer="custom-header-container"
              id="shelfHeader"
            />
            {shortDescription && seoTopCopy === null && (
              <p className={cx(azCommonStyles['az-body-2-regular'], styles.shortDescription)}>
                {shortDescription}
              </p>
            )}
            {seoTopCopy && <ExpandableTopDescription content={seoTopCopy} />}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export { CMSShelfResultHeaderModularHandler };
