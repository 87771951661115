/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useMemo } from 'react';
import type { FilterDataType, HandleFilterType } from '../types/filterTypes';
import { getQuickAndOneClickFilters } from '@/components/Filter/utils/getQuickAndOneClickFilters';
import { type QuickFilterModel } from '@/api/types/browse-search-types';

type ShelfChipSet = {
  oneClickFilters: HandleFilterType[];
  quickFilters: FilterDataType[];
};

const getQuickAndOneClickFiltersArray = (
  quickFilters: QuickFilterModel[] | undefined,
  staticFilterData: Array<FilterDataType> | null | undefined,
  dynamicFilterData: Array<FilterDataType> | null | undefined
) => {
  let oneClickFiltersArray: Array<HandleFilterType> = [];
  let quickFiltersArray: Array<FilterDataType> = [];
  staticFilterData?.forEach((filter: FilterDataType) =>
    getQuickAndOneClickFilters(oneClickFiltersArray, quickFiltersArray, filter, quickFilters)
  );
  dynamicFilterData?.forEach((filter: FilterDataType) =>
    getQuickAndOneClickFilters(oneClickFiltersArray, quickFiltersArray, filter, quickFilters)
  );
  return { oneClickFilters: oneClickFiltersArray, quickFilters: quickFiltersArray };
};

export const useGetQuickAndOneClickFilters = (
  quickFilters: QuickFilterModel[] | undefined,
  staticFilterData: Array<FilterDataType> | null = null,
  dynamicFilterData: Array<FilterDataType> | null = null
): ShelfChipSet => {
  const shelfChipSet = useMemo(
    () => getQuickAndOneClickFiltersArray(quickFilters, staticFilterData, dynamicFilterData),
    [dynamicFilterData, quickFilters, staticFilterData]
  );

  return shelfChipSet;
};
