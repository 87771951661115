/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useEffect } from 'react';
import cx from 'classnames';
import type { SortingOption } from '@/types/legacy';
import SelectField from '../StandaloneFields/SelectField';
import { useRouter } from 'next/router';
import { useLabel } from '@/hooks/useLabels';
import { setFilterScroll } from '../../../actions/shelf/shelf';
import type { SortingOptionValue } from '@/types/legacy';
import type { CommonQuickFilterProps } from '../../Filter/QuickFilter/types';
import { stringifyUrl, parseUrl } from '../../../utils/urlHelpers';
import filterStyles from '../../Filter/QuickFilter/sharedStyles.module.scss';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { Dropdown, type DropdownProps } from '@az/starc-ui';

export type SortByProps = {
  classes?: any;
  onChange?: Function;
  redirectOnChange?: boolean;
  isQuickFilter?: boolean;
  QuickFilterProps?: CommonQuickFilterProps;
  sortingOptions: SortingOption[];
  focusItem?: boolean;
  placement?: DropdownProps['placement'];
  setFocus?: (focusSortBy: boolean, focusItemsPerPage: boolean) => void;
};

const SortBy = (props: SortByProps) => {
  const dispatch = useDispatch();
  const {
    classes = {},
    onChange,
    redirectOnChange = true,
    isQuickFilter,
    QuickFilterProps = {},
    sortingOptions = [],
    focusItem = false,
    setFocus,
    placement,
  } = props;
  const featuredLabel = useLabel('label.sort.featured');
  const sortByLabel = useLabel('label.sort.sortBy');
  const options = [
    {
      parameter: 'featured' as SortingOptionValue,
      name: featuredLabel,
      enabled: true,
    },
    ...sortingOptions,
  ];
  const router = useRouter();
  const { asPath } = router;
  const { query } = parseUrl(asPath);
  const [sortSelected, setSortOption] = useState<SortingOptionValue>(
    (query.sort as SortingOptionValue) ?? 'featured'
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (query?.sort) {
      setSortOption(query.sort as SortingOptionValue);
    } else {
      setSortOption('featured');
    }
  }, [query.sort]);

  const changeHandler = (sortBy: SortingOptionValue) => {
    if (setFocus) {
      setFocus(true, false);
    }

    const { url, query } = parseUrl(asPath);
    setSortOption(sortBy);
    onChange?.(sortBy);

    if (redirectOnChange) {
      if (sortBy === 'featured' && query.sort) {
        delete query.sort;
      } else {
        query.sort = sortBy;
      }

      router.push(
        stringifyUrl(
          {
            url,
            query: { ...query, pageNumber: null },
          },
          {
            skipNull: true,
          }
        )
      );
    }
  };

  const handleQuickFilterChange = (isOpen: boolean) => {
    const { handleChange } = QuickFilterProps || {};
    dispatch(setFilterScroll(false));
    handleChange?.(isOpen);
  };

  if (isQuickFilter) {
    return (
      <Dropdown
        width={343}
        open={open}
        onOpen={() => {
          handleQuickFilterChange(true);
          setOpen(true);
        }}
        onClose={() => {
          handleQuickFilterChange(false);
          setOpen(false);
        }}
        placement={placement}
      >
        <Dropdown.Button className={filterStyles.dropdownButton}>
          <span className={filterStyles.dropdownButtonText}>{sortByLabel}</span>
        </Dropdown.Button>
        <Dropdown.Content className={filterStyles.dropdownContent}>
          <div
            className={filterStyles.dropdownList}
            role="listbox"
            aria-activedescendant={`sort-option-${sortSelected}`}
            aria-label={sortByLabel}
          >
            {options.map((option, index) => {
              const isSelected = sortSelected === option.parameter;

              if (option.enabled) {
                return (
                  <button
                    key={`${option.name}-${index}`}
                    id={`sort-option-${option.parameter}`}
                    className={cx(filterStyles.dropdownOption, {
                      [filterStyles.optionSelected]: isSelected,
                    })}
                    role="option"
                    aria-selected={isSelected}
                    aria-disabled={isSelected}
                    disabled={isSelected}
                    onClick={() => {
                      changeHandler(option.parameter);
                      setOpen(false);
                    }}
                  >
                    <div>{option.name}</div>
                  </button>
                );
              }

              return null;
            })}
          </div>
        </Dropdown.Content>
      </Dropdown>
    );
  }

  return (
    <SelectField
      classes={classes}
      data-testid="sort-by-select-field"
      id="sort-by-select-field"
      name=""
      onChange={(event: any) => changeHandler(event.target.value)}
      value={sortSelected}
      hideEmptyOption
      autoFocus={focusItem}
    >
      {options.map((option, index) => {
        if (option.enabled) {
          return (
            <option
              data-testid="sort-by-option"
              key={`${option.parameter}-${index}`}
              value={option.parameter}
            >
              {option.name}
            </option>
          );
        }

        return null;
      })}
    </SelectField>
  );
};

export default SortBy;
