/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { CustomLinkName } from '@/types/analytics';
import { clickTrack } from '@/utils/analytics/clickTrack';

export const trackFulfillmentChange = (
  fulfillmentChange: string,
  customLinkName: CustomLinkName,
  productFulfillmentOptionSeenByCustomer?: string[]
) => {
  const utag = window.utag_data;
  const pagetype = utag?.pageType ?? '';
  const pagename = utag?.pageName ?? '';
  const sitesection = utag?.siteSection ?? '';
  clickTrack({
    eventType: 'fulfillmentChange',
    fulfillmentChange,
    customLinkName,
    pageName: pagename,
    pageType: pagetype,
    siteSection: sitesection,
    productFulfillmentOptionSeenByCustomer,
  });
};
