/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type SearchPageBody, type SearchPageBodyRecordsElement } from '@/features/search';
import type { ShelfPageBodyComplete, ShelfPageBodyItemResultsList } from '@/types/legacy';

export const getResults = (
  bodyContent: SearchPageBody | ShelfPageBodyComplete
): SearchPageBodyRecordsElement | ShelfPageBodyItemResultsList | undefined => {
  let results;
  bodyContent.forEach((content) => {
    if (content['@type']?.split('_')[1].toLowerCase().includes('results') && 'records' in content) {
      results = content;
    }
  });
  return results;
};
