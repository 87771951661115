/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import { type FormattedContentStackSEOContent } from '@/features/contentstack/interface/models';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { usePageType } from '@/features/category/api/getPageType';
import { type PageContextType } from '@/next-types';
import { getSeoContent } from '../../contentstack/lib/seoData/getContentStackSeoContent';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

const URL = `/api/contentstack/seoContent`;
const getContentStackSeoData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getCSSEOContentKey>>) => {
  const [, , { productId }] = queryKey;
  const { data } = await getAxiosForClient().get<FormattedContentStackSEOContent>(URL, {
    params: { productId },
  });
  return data;
};

const getCSSEOContentKey = ({ productId }: { productId: string }) =>
  ['cms', 'seoBuilder', { productId }] as const;

export const useContentStackSeoBuilderData = ({ enabled = true }: { enabled?: boolean }) => {
  const { data: pageTypeData } = usePageType();
  const isCMSSeoContentEnabled = useMonetateDecisionFlag('cmsSeoContentEnabled');
  return useQuery({
    queryKey: getCSSEOContentKey({ productId: pageTypeData?.catalogRepositoryId ?? '' }),
    queryFn: getContentStackSeoData,
    enabled: enabled && isCMSSeoContentEnabled,
  });
};

export const prefetchContentStackSeoData = async ({
  queryClient,
  context,
  productId,
}: {
  queryClient: QueryClient;
  context: PageContextType;
  productId: string;
}) => {
  await queryClient.prefetchQuery({
    queryKey: getCSSEOContentKey({ productId }),
    queryFn: () => getSeoContent(context.req, { ...context.query, productId }),
  });
};
