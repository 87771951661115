/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type ProductShelfResultsModel } from '@/api/types/browse-search-types';

export type MakeModelYearPageType = 'ShopByMake' | 'ShopByModel' | 'ShopByYear' | null;

export const getShopByType = (results: ProductShelfResultsModel): MakeModelYearPageType =>
  results.make
    ? results.model
      ? results.year
        ? 'ShopByYear'
        : 'ShopByModel'
      : 'ShopByMake'
    : null;
