/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { Locale } from '@/types/i18n';
import type { useMapFacet } from '@/hooks/useMapFacet';
import type { NextRouter } from 'next/router';
import { getSortValue } from './getSortValue';

type SearchQuery = {
  searchText: string | string[] | undefined;
  sort: string | string[] | undefined;
  facet: string | undefined;
  minPrice: string | undefined;
  maxPrice: string | undefined;
};

export const getApiOptions = ({
  router,
  locale,
  vehicleId,
  storeNumber,
  mapFacet,
  preview,
  viewingPartTypes,
  vehicleIdChanged,
  userProfileId,
}: {
  router: NextRouter;
  locale: Locale;
  vehicleId: string | undefined;
  storeNumber: string | undefined;
  mapFacet: ReturnType<typeof useMapFacet>;
  preview: boolean;
  isCurrentPage?: boolean;
  viewingPartTypes?: boolean;
  vehicleIdChanged?: boolean;
  userProfileId?: string;
}) => {
  const {
    searchText,
    sort: sortParam = [],
    facet,
    minPrice,
    maxPrice,
  } = router.query as SearchQuery;
  const sort = typeof sortParam === 'string' ? getSortValue(sortParam) : undefined;
  const newFacet =
    minPrice || maxPrice
      ? `${facet ? `${facet}++` : ''}price:${minPrice ?? '*'} to ${maxPrice ?? '*'}`
      : facet;
  return {
    mapFacet,
    locale,
    searchText: typeof searchText === 'string' ? searchText : undefined,
    vehicleId: vehicleId ?? '0',
    userProfileId: userProfileId,
    skipPdpRedirect: vehicleIdChanged,
    facet: typeof newFacet === 'string' ? newFacet : undefined,
    sort,
    storeId: storeNumber,
    userSegment: undefined,
    keywordSearchVisual: viewingPartTypes || undefined,
    preview,
  };
};
