/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useEffect, useMemo } from 'react';
import { useProductShelfResults } from '@/features/category/api/getProductShelfResults';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { usePageType } from '@/hooks/usePageType';
import { pageTypes } from '@/constants/page';
import { useProductList } from '@/features/shelf/context/useProductList';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

export const useCombinedInfiniteQuery = () => {
  const { pageType } = usePageType();
  const isShelfPage = pageType === pageTypes.ProductShelf || pageType === pageTypes.MerchShelf;
  const isSearchPage = pageType === pageTypes.search;
  const isBrandPage = pageType === pageTypes.BrandShelf;
  const { data: shelfData } = useProductList();
  const shelfQuery = useProductShelfResults({ enabled: isShelfPage });
  const searchQuery = useSearchProductData({ enabled: isSearchPage });
  const brandQuery = useBrandProductData({ enabled: isBrandPage });
  const queryClient = useQueryClient();
  const router = useRouter();
  const currentlyShowing = useMemo(() => {
    if (isShelfPage) {
      return shelfData?.shelfParts?.length;
    }
    if (isSearchPage) {
      return searchQuery?.data?.records?.length;
    }
    if (isBrandPage) {
      return brandQuery?.data?.records?.length;
    }
    return null;
  }, [
    isShelfPage,
    isSearchPage,
    isBrandPage,
    searchQuery,
    shelfData?.shelfParts?.length,
    brandQuery?.data?.records?.length,
  ]);

  useEffect(() => {
    const hasLoadMore = router.asPath.includes('loadMore=');

    if (!hasLoadMore) {
      if (isShelfPage) {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === 'productshelf-results' && query.queryKey[2] === 'infinite',
          },
          (oldData: any) => {
            if (!oldData?.pages?.length) {
              return oldData;
            }
            return {
              ...oldData,
              pages: oldData?.pages?.slice(0, 1) || [],
              pageParams: [oldData?.pageParams?.[0]],
              hasNextPage: false,
            };
          }
        );
      }
      if (isSearchPage) {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === 'searchProductData' && query.queryKey[2] === 'infinite',
          },
          (oldData: any) => {
            if (!oldData?.pages?.length) {
              return oldData;
            }
            return {
              ...oldData,
              pages: oldData?.pages?.slice(0, 1) || [],
              pageParams: [oldData?.pageParams?.[0]],
              hasNextPage: false,
            };
          }
        );
      }
      if (isBrandPage) {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === 'brandProducts' && query.queryKey[2] === 'infinite',
          },
          (oldData: any) => {
            if (!oldData?.pages?.length) {
              return oldData;
            }
            return {
              ...oldData,
              pages: oldData?.pages?.slice(0, 1) || [],
              pageParams: [oldData?.pageParams?.[0]],
              hasNextPage: false,
            };
          }
        );
      }
    }
  }, [router.asPath, isShelfPage, isSearchPage, isBrandPage, queryClient]);

  const totalRecords = useMemo(() => {
    if (isShelfPage) {
      return shelfQuery?.data?.productShelfResults?.totalNumberOfRecords;
    }
    if (isSearchPage) {
      return searchQuery?.data?.total;
    }
    if (isBrandPage) {
      return brandQuery?.data?.total;
    }
    return null;
  }, [isShelfPage, isSearchPage, isBrandPage, shelfQuery, searchQuery, brandQuery?.data?.total]);

  const activeQuery = useMemo(() => {
    if (isShelfPage) {
      return shelfQuery;
    }
    if (isSearchPage) {
      return searchQuery;
    }
    if (isBrandPage) {
      return brandQuery;
    }
    return null;
  }, [isShelfPage, isSearchPage, shelfQuery, searchQuery, brandQuery, isBrandPage]);

  const hasMoreProducts =
    typeof currentlyShowing === 'number' &&
    typeof totalRecords === 'number' &&
    currentlyShowing < totalRecords;

  return {
    infiniteQueryStatus: activeQuery?.status,
    pageNumber: activeQuery && 'pageNumber' in activeQuery ? activeQuery.pageNumber : undefined,
    hasNextPage:
      hasMoreProducts ||
      (activeQuery && 'hasNextPage' in activeQuery ? activeQuery.hasNextPage : undefined),
    fetchNextPage:
      activeQuery && 'fetchNextPage' in activeQuery ? activeQuery.fetchNextPage : undefined,
    infiniteData: activeQuery?.data,
    infiniteQueryIsloading: activeQuery?.isLoading || activeQuery?.isFetching,
    totalRecords,
    currentlyShowing: currentlyShowing,
  };
};
