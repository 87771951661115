/**
 * Copyright 2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Title from '@/components/AZCustomComponent/Title';
import { useLabel } from '@/hooks/useLabels';
import type { OldReview } from '../../interface';
import azCommonStyles from '@/theme/globals.module.scss';
import { BVShelfCard } from './BVShelfCard';
import styles from './styles.module.scss';

type Props = {
  reviews: OldReview[];
};

export const BazaarVoiceShelf = ({ reviews }: Props) => {
  const titleFirst = useLabel('label_customer');
  const titleSecond = useLabel('label_reviews');

  return (
    <div
      className={`
        ${azCommonStyles['az-padding-top-m']}
        ${azCommonStyles['az-padding-left-xs']}
        ${azCommonStyles['az-padding-right-xs']}
        ${styles.bvContainer}`}
      data-testid="BazaarVoiceShelf"
    >
      <Title
        firstText={titleFirst}
        secondText={titleSecond}
        swapStyle
        variant="md"
        stylesClass={`${azCommonStyles['az-padding-bottom-4xs']} ${styles.bvTitle}`}
        isSameTitleTag
      />
      <div className={`${azCommonStyles['az-margin-bottom-xxxs']} ${styles.separator}`} />
      {reviews.map((review, index) => (
        <BVShelfCard key={index} review={review} />
      ))}
    </div>
  );
};
