/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { CMSBanner } from '@/features/contentstack/components/CMSBanner/CMSBanner';
import { CMSBannerSplit } from '@/features/contentstack/components/CMSBannerSplit/CMSBannerSplit';
import { CMSCampaignModal } from '@/features/contentstack/components/CMSCampaignModal/CMSCampaignModal';
import { CMSCitrusCarouselShelf } from '@/features/contentstack/components/CMSCitrusCarousel/CMSCitrusCarouselShelf';
import { CMSCitrusShelf } from '@/features/contentstack/components/CMSCitrus/CMSCitrusShelf';
import { CMSExperienceInjector } from '@/features/contentstack/components/CMSExperienceInjector/CMSExperienceInjector';
import { CMSTarget } from '@/features/contentstack/components/CMSTarget/CMSTarget';
import type { ContentStackProductListingPage } from '@/features/contentstack/interface';
import { CMSPLPSpacer } from './CMSPLPSpacer/CMSPLPSpacer';
import { STARCTypography } from '@/components/STARCTypography';
import { CMSShelfYmme } from './CMSShelfYmme/CMSShelfYmme';
import { CMSResultList } from './CMSResultList/CMSResultList';
import { CMSSortingOptions } from './CMSSortingOptions/CMSSortingOptions';
import type { ShelfPart } from '@/types/legacy';
import type { HandleFilterType } from '@/types/filterTypes';
import { SuperDuperHero } from '@/features/home/components/SuperDuperHero/SuperDuperHero';

type Props = {
  content: Array<NonNullable<ContentStackProductListingPage['shelfpagebody']>[0]>;
  pageType: 'shelf' | 'brand' | 'merch' | 'search';
  refQuickFilter?: React.Ref<HTMLElement>;
  resultsListRef?: React.RefObject<HTMLDivElement>;
  onFilterSelect?: (a: HandleFilterType) => void;
  handleDealClick: (
    part: ShelfPart,
    price: string,
    quantity: number,
    ref: React.RefObject<HTMLButtonElement>,
    fulfillmentTypeId: number | null
  ) => void;
};

export const CMSPLPBodyModularHandler = ({
  content,
  pageType,
  refQuickFilter,
  resultsListRef,
  onFilterSelect,
  handleDealClick,
}: Props) => {
  let foundSplitBanner = false;

  return (
    <STARCTypography>
      {content.map((modularBlock, index) => {
        if ('banner' in modularBlock && modularBlock.banner?.banner?.[0]) {
          return (
            <CMSPLPSpacer key={`${pageType}-${index}`}>
              <CMSBanner
                // Only preload if there's no split banner above this banner
                priority={!foundSplitBanner}
                loading="eager"
                content={modularBlock.banner.banner[0]}
                legalPadding={{ s: true, m: true, l: false }}
              />
            </CMSPLPSpacer>
          );
        }

        if ('banner_split' in modularBlock && modularBlock.banner_split?.banner_split?.[0]) {
          foundSplitBanner = true;
          return (
            <CMSPLPSpacer key={`${pageType}-${index}`}>
              <CMSBannerSplit priority content={modularBlock.banner_split.banner_split[0]} />
            </CMSPLPSpacer>
          );
        }

        if (
          'experience_injector' in modularBlock &&
          modularBlock.experience_injector?.experience_injector?.[0]
        ) {
          return (
            <CMSPLPSpacer
              key={`${pageType}-${index}`}
              removeHorizontalSpacing={{ s: true, l: true }}
              removeVerticalSpacing
            >
              <CMSExperienceInjector
                content={modularBlock.experience_injector.experience_injector[0]}
              />
            </CMSPLPSpacer>
          );
        }

        if ('ymme_content_block' in modularBlock && modularBlock.ymme_content_block) {
          const layout = modularBlock.ymme_content_block.layout ?? 'Thin';
          const maybeProductFinder = content.find(
            (block) => 'product_finder' in block && !!block.product_finder?.product_finder?.[0]
          );
          const maybeNullResult = content.find(
            (block) => 'null_result' in block && !!block.null_result?.null_result?.[0]
          );

          return (
            <CMSPLPSpacer
              key={`${pageType}-${index}`}
              removeHorizontalSpacing
              removeVerticalSpacing
            >
              <CMSShelfYmme
                content={{ layout }}
                productFinderData={
                  maybeProductFinder && 'product_finder' in maybeProductFinder
                    ? maybeProductFinder?.product_finder?.product_finder?.[0]
                    : undefined
                }
                nullResultsData={
                  maybeNullResult && 'null_result' in maybeNullResult
                    ? maybeNullResult?.null_result?.null_result?.[0]
                    : undefined
                }
              />
            </CMSPLPSpacer>
          );
        }

        if ('citrus_ad' in modularBlock && modularBlock.citrus_ad?.citrus_ad?.[0]) {
          return modularBlock.citrus_ad.citrus_ad[0].type === 'carousel' ? (
            <CMSPLPSpacer
              key={`${pageType}-${index}`}
              removeHorizontalSpacing={{ s: true, l: true }}
            >
              <CMSCitrusCarouselShelf />
            </CMSPLPSpacer>
          ) : (
            <CMSCitrusShelf
              key={`${pageType}-${index}`}
              content={modularBlock.citrus_ad.citrus_ad[0]}
            />
          );
        }

        if ('target_div' in modularBlock && modularBlock.target_div?.target_div?.[0]) {
          return (
            <CMSPLPSpacer
              key={`${pageType}-${index}`}
              removeHorizontalSpacing={{ s: true, l: true }}
              removeVerticalSpacing
            >
              <CMSTarget content={modularBlock.target_div.target_div[0]} />
            </CMSPLPSpacer>
          );
        }

        if ('results_list' in modularBlock && modularBlock.results_list) {
          return (
            <CMSPLPSpacer key={`${pageType}-${index}`} removeHorizontalSpacing>
              <CMSSortingOptions
                refQuickFilter={refQuickFilter}
                onFilterSelect={onFilterSelect}
                resultsListRef={resultsListRef}
              />
              <CMSResultList resultsListRef={resultsListRef} handleDealClick={handleDealClick} />
            </CMSPLPSpacer>
          );
        }

        if (
          'super_duper_hero' in modularBlock &&
          modularBlock.super_duper_hero?.super_duper_hero?.[0]
        ) {
          return (
            <CMSPLPSpacer key={`${pageType}-${index}`}>
              <SuperDuperHero options={modularBlock.super_duper_hero.super_duper_hero[0]} />
            </CMSPLPSpacer>
          );
        }

        if ('campaign' in modularBlock && modularBlock.campaign?.campaign) {
          return (
            <CMSCampaignModal
              key={`${pageType}-${index}`}
              content={modularBlock.campaign.campaign}
            />
          );
        }
      })}
    </STARCTypography>
  );
};
