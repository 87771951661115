/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import { nddIcon } from '../../constants/images/nddIcon';
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import { getFormattedAmPmShort } from '@/utils/itemHelpers/fulfillmentHelpers/getFormattedAmPmShort';
import NextImage from '@/components/NextImage';
import { OrderWithinHrsMinArrivesMessage } from './FulfillmentCutoff';
import InfoToolTip from '../AZCustomComponent/InfoToolTip';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useSegmentMessage } from '@/features/segmentMessages/api/getSegmentMessages';

export default function NextDayOption({
  option,
  isDealDrawer,
}: {
  option: FulfillmentOption;
  isDealDrawer?: boolean;
}) {
  const labelInfoPopper1 = useLabel(fulfillmentLabels.label_shipping_ndd_infoPopper_1);
  const labelInfoPopper2 = useLabel('label_shipping_infoPopper_cutoff_2');
  const labelInfoPopperCostNdd = useLabel('label_shipping_infoPopper_cost_ndd');
  const nddLabel = useSegmentMessage(fulfillmentLabels.label_shipping_ndd_freeNddEligible);

  return (
    <>
      <div className={styles.listItemContainer} data-testid="next-day-delivery">
        <div className={styles.listItemIcon}>
          <NextImage
            role="presentation"
            src={nddIcon.src}
            alt={nddIcon.alt}
            height="16"
            width="32"
          />
        </div>
        <div className={styles.listItemDetails}>
          <div className={styles.listItemHeader}>
            <span data-testid="next-day" className={styles.nextDayHeader}>
              <Label label={nddLabel} />
            </span>
            <InfoToolTip
              classes={{
                icon: styles.infoPopperIcon,
                popper: cx({ [styles.nddPopper]: isDealDrawer }),
              }}
              header={useLabel(fulfillmentLabels.label_shipping_WhatIsIt)}
              body={`${labelInfoPopper1} ${getFormattedAmPmShort(
                option.fullfillmentCutOffDate
              )}${labelInfoPopper2} ${labelInfoPopperCostNdd}`}
            />
          </div>
          {option.available && option.estimatedDeliveryDate && option.fullfillmentCutOffDate ? (
            <OrderWithinHrsMinArrivesMessage
              estDeliveryDateString={option.estimatedDeliveryDate}
              cutOffDateString={option.fullfillmentCutOffDate}
              orderByLabel={fulfillmentLabels.label_shipping_cutoff_hrsmin_head}
              arrivesByLabel={fulfillmentLabels.label_shipping_cutoff_hrsmin_tail}
              displayAmPmFormat={false}
              shippingArriveBy={fulfillmentLabels.label_shipping_arriveBy}
            />
          ) : (
            <span className={styles.deliveryMessage}>{option.availableLabel}</span>
          )}
        </div>
      </div>
    </>
  );
}
