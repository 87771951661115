/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getResults } from '@/components/Filter/FilterMobile/helpers';
import { useBrandProductData } from '@/features/brands/api/getProductBrandData';
import { useSearchPartTypes } from '@/features/search/api/getSearchPartTypes';
import { useSearchProductData } from '@/features/search/api/getSearchProductData';
import { useProductList } from '@/features/shelf/context/useProductList';
import { type ShelfPageBodyComplete } from '@/types/legacy';
import { useMemo } from 'react';

export const useCountData = (
  isSearchPageType?: boolean,
  isBrandPage?: boolean,
  viewingPartTypes?: boolean
) => {
  const { data: shelfData } = useProductList();
  const { data: searchProductData } = useSearchProductData({
    enabled: !!isSearchPageType,
  });
  const { data: searchPartTypesData } = useSearchPartTypes({
    enabled: Boolean(isSearchPageType && viewingPartTypes),
    viewingPartTypes,
  });

  const { data: brandProductData } = useBrandProductData({ enabled: isBrandPage });
  const shelfBodyContent: ShelfPageBodyComplete | null | undefined = shelfData?.shelfPageBody;

  const countData = useMemo(() => {
    let countData = {
      recsPerPage: 0,
      total: 0,
      firstRecNum: 0,
      lastRecNum: 0,
    };

    if (shelfBodyContent) {
      const results = getResults(shelfBodyContent);
      if (results) {
        if ('pagination' in results) {
          const { pagination } = results;
          countData = {
            recsPerPage: pagination?.pageSize,
            total: pagination?.total,
            firstRecNum: pagination?.firstRecordNumberOfCurrentPage,
            lastRecNum: pagination?.lastRecordNumberOfCurrentPage,
          };
        } else if ('firstRecNum' in results) {
          const { recsPerPage, totalNumRecs, firstRecNum, lastRecNum } = results;
          countData = {
            recsPerPage: recsPerPage ?? 0,
            total: totalNumRecs ?? 0,
            firstRecNum,
            lastRecNum,
          };
        }
      } else if (brandProductData && isBrandPage) {
        const { recordsPerPage, total, firstRecNum, lastRecNum } = brandProductData;
        countData = {
          recsPerPage: recordsPerPage,
          total: total ?? 0,
          firstRecNum: firstRecNum ?? 0,
          lastRecNum: lastRecNum ?? 0,
        };
      } else if (isSearchPageType) {
        if (searchPartTypesData) {
          countData = {
            recsPerPage: searchPartTypesData.recordsPerPage ?? 0,
            total: searchPartTypesData.total ?? 0,
            firstRecNum: searchPartTypesData.firstRecNum ?? 0,
            lastRecNum: searchPartTypesData.lastRecNum ?? 0,
          };
        } else if (searchProductData) {
          countData = {
            recsPerPage: searchProductData.recordsPerPage ?? 0,
            total: searchProductData.total ?? 0,
            firstRecNum: searchProductData.firstRecNum ?? 0,
            lastRecNum: searchProductData.lastRecNum ?? 0,
          };
        }
      }
    }

    return countData;
  }, [
    isSearchPageType,
    searchPartTypesData,
    searchProductData,
    brandProductData,
    isBrandPage,
    shelfBodyContent,
  ]);

  return countData;
};
