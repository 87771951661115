/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { type MappedSearchProductData } from '@/features/search';
import type { CitrusSearchCarouselParams } from '../interface';
import type { VehicleSummary } from '@/types';

export const getCitrusSearchCarouselParams = ({
  searchProductData,
  vehicle,
}: {
  searchProductData: MappedSearchProductData;
  vehicle: VehicleSummary | undefined;
}): CitrusSearchCarouselParams => {
  return {
    category: searchProductData.records?.[0]?.partTypeName ?? '',
    vehicleId: vehicle?.catalogVehicleId ?? '0',
  };
};
