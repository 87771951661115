/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import {
  type AffinityRelatedProductsOptions,
  type AffinityRelatedProductsResponse,
  type AffinityRelatedProductsData,
  type AffinityRelatedProduct,
  type AffinityRelatedProductsQueryParams,
} from '../interface';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useDeviceType } from '@/utils/useDeviceType';
import { type CertonaPricing } from '@/types';
import { API_URLS } from '@/constants/apiUrls';
import { createQuery } from '@/utils/createReactQuery';

const URL = `${requestBaseURL}/ecomm/b2c/v2/browse/affinity/related-parts`;

export const getAffinityRelatedProductsResults = async (
  options: AffinityRelatedProductsOptions,
  axiosInstance?: AxiosInstance
) => {
  const { vehicleId, storeId, productId, itemsToDisplay } = options;

  if (!vehicleId) {
    throw new Error('vehicleId is required');
  }

  if (!productId) {
    throw new Error('productId is required');
  }

  if (!storeId) {
    throw new Error('storeId is required');
  }

  const params: AffinityRelatedProductsQueryParams = { vehicleId, storeId, productId };

  let affinityData;

  const {
    data: { affinityRelatedProducts },
  } = await getAxios(axiosInstance).get<AffinityRelatedProductsResponse>(URL, {
    params,
  });
  affinityData = affinityRelatedProducts;

  if (itemsToDisplay) {
    affinityData = affinityData.slice(0, itemsToDisplay);
  }

  let certonaPricing = null;
  if (affinityData.length) {
    const skuNumbers = affinityData.map((part) => part.skuId).join(',');

    const { data: certonaPricingForAffinityItems } = await getAxios(axiosInstance).get<
      CertonaPricing[]
    >(`${API_URLS.CERTONA_PRICING}${skuNumbers}?storeNumber=${options.storeId ?? ''}`);

    certonaPricing = certonaPricingForAffinityItems;
  }

  return selector(affinityData, certonaPricing);
};

const selector = (
  affinityData: AffinityRelatedProduct[],
  certonaPricing: CertonaPricing[] | null
): AffinityRelatedProductsData => {
  if (certonaPricing) {
    const relatedProductsWithPricing = affinityData.map((product) => {
      const pricingForProduct = certonaPricing.find((part) => part.skuId === product.skuId);

      if (!pricingForProduct) {
        throw new Error('certona pricing is required for affinity products');
      }

      return {
        ...product,
        ...pricingForProduct,
      };
    });

    return relatedProductsWithPricing;
  }

  return null;
};

const { useData: useAffinityData } = createQuery<
  AffinityRelatedProductsData,
  AffinityRelatedProductsOptions
>('affinityRelatedParts', async (options, axiosInstance) =>
  getAffinityRelatedProductsResults({ ...options }, axiosInstance)
);

export const useAffinityRelatedProducts = ({
  enabled = true,
  itemsToDisplay,
}: { enabled?: boolean; itemsToDisplay?: number } = {}) => {
  const { data: productDetails, isSuccess: isProductDetailsSuccess } = useProductDetails();
  const { data: headerData } = useHeaderData();
  const { data: skuData } = useProductSkuDetails({
    skuIds:
      isProductDetailsSuccess && productDetails.product?.itemId
        ? [productDetails.product.itemId]
        : null,
  });
  const deviceType = useDeviceType();

  const productId = productDetails?.product?.ecommProductId;
  const originalPartTypeId = productDetails?.product?.originalPartTypeId;
  const isPDPItemAvailable = skuData?.[0]?.fulfillmentOptions?.some(({ available }) => available);

  const vehicleId = headerData?.vehicleMap.catalogVehicleId;
  const fitsVehicle = productDetails?.product?.vehicleFitFlag ?? false;

  const storeNumber = headerData?.storeNumber;

  const queryEnabled = Boolean(
    enabled && isPDPItemAvailable && fitsVehicle && vehicleId && storeNumber && deviceType !== 'bot'
  );

  const options = {
    vehicleId: vehicleId,
    /* If originalPartTypeId is available, we should use it instead of productId.
     * Affinity uses parts pro catalog, which are used in store.
     * Parts Pro catalog and ecomm catalog (productIds) may not match, we keep track of the
     * original relations using the property originalPartTypeId.
     * originalPartTypeId refers to the parts-pro productId which is partType + system code.
     */
    productId: originalPartTypeId ? originalPartTypeId : productId,
    storeId: storeNumber,
    isPDPItemAvailable,
    fitsVehicle,
    deviceType,
    itemsToDisplay,
  };

  return useAffinityData({
    ...options,
    enabled: queryEnabled,
  });
};
